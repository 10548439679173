import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaterialThemeService {
  private themeSubject = new BehaviorSubject<string>('light-mode');
  public themeClass = this.themeSubject.asObservable();

  private themeClasses = ['light-theme', 'dark-theme']

  updateThemeClass(theme) {
    this.themeSubject.next(theme);

    // Store latest value in local storage
    // so it isn't lost on a page refresh
    localStorage.setItem("themeClass", theme);

    // Update class on document body so it's viewable in dialogs
    // and overlays that aren't children of the app component.

    // Remove all theme classes
    for (let themeClass of this.themeClasses) {
      if (document.body.classList.contains(themeClass)) {
        document.body.classList.remove(themeClass);
      }
    }

    // Add new theme class
    document.body.classList.add(theme);
  }
}
