import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ReferralService } from '../services/referral.service';
import { LocalStorageService } from '../services/local-storage.service';

import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-referral',
    templateUrl: './referral.component.html',
    styleUrls: ['./referral.component.scss'],
    standalone: true,
    imports: [],
})
export class ReferralLandingPage implements OnInit {
  isLoading = true;
  constructor(
    private authService: AuthService,
    private referralService: ReferralService,
    private route: ActivatedRoute,
    private storage: LocalStorageService
  ) {}

  ngOnInit() {
    const shortLink = this.route.snapshot.paramMap.get('id');
    if (shortLink) {
      // Get referral ID from API
      this.referralService.retrieveReferral(shortLink).subscribe((data) => {
        // Record referral ID
        console.log(data);
        const id = data._id;
        this.storage.set('referralId', id);

        // Record referral log for "Click"
        const body = {
          object_type: 'referral',
          event: 'clicks',
          action: 'analytics',
        };
        this.referralService.createReferralLog(id, body).subscribe((data) => {
          console.log({ 'LOG CREATE': data });
          this.signIn();
        });
      });
    }
  }

  signIn() {
    // Go to homepage
    window.open(`${environment.clientUrl}/home/`, '_self');
  }
}
