// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-circle {
  background-color: var(--mat-theme-accent);
  border-radius: 999px;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-text {
  color: var(--mat-theme-background);
  font-size: 24px;
  line-height: 24px;
  display: block;
}

.close-icon-button {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 99px !important;
}

.close-icon {
  font-size: 16px;
  line-height: 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
