import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProfilesService } from 'src/app/services/profiles.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { MatButton } from '@angular/material/button';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass, TitleCasePipe, KeyValuePipe } from '@angular/common';

@Component({
    selector: 'app-influencer-requirements',
    templateUrl: './influencer-requirements.component.html',
    styleUrls: ['./influencer-requirements.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    FaIconComponent,
    MatButtonToggle,
    MatButton,
    TitleCasePipe,
    KeyValuePipe
],
})
export class InfluencerRequirementsComponent implements OnInit {
  @Output() changePage = new EventEmitter();
  @Output() close = new EventEmitter();

  requirements = [
    'Have and active social media badge',
    'Have the chose social media account linked on your bio profile',
    'Meet the required follower count on the chosen social media',
  ];

  socials = {
    instagram: '100K+',
    x: '100K+',
    twitter: '100K+',
    youtube: '100K+',
    tiktok: '1M+',
  };
  socials2 = {
    instagram: '100K+',
    x: '100K+',
    youtube: '100K+',
    tiktok: '1M+',
  };
  
  activatedSocials = [];
  selectedSocials = [];
  loading = true;

  influencerBadge;
  badgeActive;

  constructor(
    private profiles: ProfilesService,
    private utilities: UtilitiesService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    // If profileId not in local storage, get the default bio from account
    let profileId = localStorage.getItem('profileId');
    if (!profileId) {
      profileId = this.utilities.profileId;
    }

    for (const profileBadge of this.utilities.profileDetails.badges) {
      console.log(profileBadge)
      if (profileBadge.badge.badge_type === 'influencer') {
        this.influencerBadge = profileBadge.badge;
        this.badgeActive = profileBadge.is_active;
      }
    }

    // this.profiles.retrieveProfile(profileId).then((data) => {
    //   console.log(data)
    //   for (const profileBadge of data.badges) {
    //     if (profileBadge.badge.badge_type === 'social-media') {
    //       console.log(profileBadge);
    //       this.activatedSocials = profileBadge.verified_data?.verified_socials.filter(
    //         (item) => item.type in this.socials
    //       );
    //     }
    //   }
    //   this.loading = false;
    // });

    this.profiles
    .getVerifiedSocialBadgesForProfile(profileId)
    .subscribe((data) => {
      console.log(data)
      this.activatedSocials = data.filter(social => social.type in this.socials)
      this.loading = false;
    });
  }

  toggleSocial(id) {
    const socialIndex = this.selectedSocials.indexOf(id);
    if (socialIndex === -1) {
      this.selectedSocials.push(id);
    } else {
      this.selectedSocials.splice(socialIndex, 1);
    }
  }

  continue() {
    this.changePage.emit({
      page: 'influencer-application',
      data: this.selectedSocials
    })
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getImageHost(url) {
    return this.sanitize(`${environment.imageHost}/static/images/${url}`);
  }
}
