// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-title {
  color: var(--primary);
  font-size: 20pt;
}

.product-price {
  color: var(--mat-theme-text);
  font-size: 32pt;
  line-height: 28pt;
  font-weight: 300;
}

.currency-sign {
  color: var(--mat-theme-text);
  font-size: 12pt;
}

.recurring-text {
  color: var(--mat-theme-disabled);
  font-size: 11pt;
}

.trial-price-text {
  color: var(--mat-theme-text);
  font-size: 11pt;
}

.trial-text {
  color: var(--mat-theme-text);
  font-size: 9pt;
}

.default-price-text {
  color: var(--mat-theme-disabled);
  font-size: 9pt;
}

.discount-text {
  color: var(--mat-theme-disabled);
  font-size: 11pt;
}
.discount-text.striked {
  text-decoration: line-through;
}

hr {
  border-color: rgba(0, 0, 0, 0.48) !important;
}

.bottom-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: var(--mat-theme-card);
}
@media (max-height: 800px) {
  .bottom-banner {
    height: 50px;
  }
}
@media (max-height: 600px) {
  .bottom-banner {
    height: 40px;
  }
}

.banner-image {
  height: 40px;
}
@media (max-height: 800px) {
  .banner-image {
    height: 30px;
  }
}
@media (max-height: 600px) {
  .banner-image {
    height: 25px;
  }
}

.logo-image {
  filter: brightness(0) saturate(100%) invert(18%) sepia(97%) saturate(3188%) hue-rotate(272deg) brightness(78%) contrast(134%);
  height: 80px;
}

.redeem-title {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--primary);
  text-align: center;
}

.info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.info-container .info-label {
  opacity: 0.6;
}
.info-container .info-content {
  text-align: right;
}
@media (max-width: 400px) {
  .info-container {
    flex-direction: column;
    gap: 5px;
    align-items: start;
  }
  .info-container .info-content {
    text-align: left;
  }
}

.order-tag {
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  font-weight: 600;
}
.order-tag.trial {
  border: 1px solid var(--mat-theme-accent);
  background-color: var(--mat-theme-accent-light);
  color: var(--mat-theme-accent);
}
.order-tag.active {
  border: 1px solid var(--mat-theme-success);
  background-color: var(--mat-theme-success-light);
  color: var(--mat-theme-success);
}
.order-tag.expiring {
  border: 1px solid var(--mat-theme-danger);
  background-color: var(--mat-theme-danger-light);
  color: var(--mat-theme-danger);
}

.order-title {
  font-size: 35px;
  line-height: 40px;
}

.info-panel {
  box-shadow: none !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
