/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  HostBinding,
  Input,
} from '@angular/core';

import { MoreInfoComponent } from 'src/app/helper/more-info/more-info.component';

import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { StringifyOptions } from 'querystring';
import { environment } from 'src/environments/environment';
import { ExternalService } from 'src/app/services/external.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'app-account-verification',
    templateUrl: './account-verification.component.html',
    styleUrls: ['./account-verification.component.scss'],
    standalone: true,
    imports: [
        MatCheckbox,
        FormsModule,
        MatButton,
    ],
})
export class AccountVerificationComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  //for setting component level max width
  termsUrl;
  policyUrl;

  heading: string;

  message: string;
  // isCatfish = false;
  // catfishBtnColor: string = "basic";
  tosAgree = false;
  // agreeBtnColor: string = "basic";
  // box_class = 'unchecked';
  // ripple_color = 'var(--primary)';
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private location: Location,
    private router: Router,
    private jwt: JWTTokenService,
    private account: AccountService,
    private external: ExternalService,
  ) {}

  ngOnInit() {
    this.termsUrl = `${environment.host}/home/terms-and-conditions`;
    this.policyUrl = `${environment.host}/home/privacy-policy`;
  }

  openMoreInfo() {
    const dialogRef = this.dialog.open(MoreInfoComponent, {
      panelClass: 'custom-dialog',
      width: '350px',
      height: '320px',
      data: {
        icon: 'admin_panel_settings',
        message: `A Social Valid verification lets people know that you are who you say you are.`,
        secondaryMessage: ` The first step in that process is confirming that you making this account for yourself.`,
        submitButton: 'OK',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  changeMessage(heading: string, message = '', isCatfish) {
    // this.isCatfish = isCatfish;
    this.heading = undefined;
    this.heading = heading;
    this.message = message;
    // this.snackBar.open(message, action);
  }

  nextPage() {
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  // toggleAgree() {
  //   this.tosAgree = !this.tosAgree
  //   this.agreeBtnColor = this.tosAgree ? "primary" : "basic"
  // }

  // toggleCatfish() {
  //   this.isCatfish = !this.isCatfish
  //   this.catfishBtnColor = this.isCatfish ? "primary" : "basic"
  // }

  // toggleColor() {
  //   this.box_class = this.box_class == 'checked' ? 'unchecked' : 'checked';
  //   this.ripple_color = this.ripple_color == 'var(--primary)' ? 'var(--mat-theme-app-bar)' : 'var(--primary)';
  // }

  openUrl(url, target) {
    this.external.openNewTab(url, target);
  }
}
