// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas {
  height: 100vh; /* we need these for full-screen effect */
  width: 100vw;
  position: absolute;
  pointer-events: none;
  z-index: 4;
}

.confetti {
  height: 100%; /* we need these for full-screen effect */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.promo-image {
  border-radius: 20px;
}

:host ::ng-deep .carousel-indicators button {
  border-radius: 0 !important;
}

.carousel-caption {
  padding: 20px;
  padding-bottom: 30px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #010103 100%);
  right: 0;
  left: 0;
  bottom: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.custom-carousel {
  width: 300px;
}

.image-slide {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
