// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-container {
  position: absolute;
  border-radius: 999px;
  z-index: 999;
}

.icon-circle {
  background-color: var(--mat-theme-accent);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 2px solid white; /* Adjust the border width (2px) as needed */
  aspect-ratio: 1;
}

:host ::ng-deep .lock-icon-text {
  color: var(--mat-theme-background);
  line-height: 1;
  display: block;
}
:host ::ng-deep .lock-icon-text svg {
  height: auto !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
