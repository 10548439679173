import { Component, OnInit } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';

declare var gtag: any;

@Component({
    selector: 'app-complete',
    templateUrl: './complete.component.html',
    styleUrls: ['./complete.component.scss'],
    standalone: true,
    imports: [MatCard, MatButton],
})
export class CompleteComponent implements OnInit {
  redirect;

  constructor(
    private router: Router,
    private storage: LocalStorageService,
  ) { }

  ngOnInit() {
    this.redirect = this.storage.get('signinRedirect');
    this.storage.remove('signinRedirect');

    // Record GTAG Conversion
    gtag('event', 'conversion', {'send_to': 'AW-608463020/3KuwCKP0y_MYEKzRkaIC'});
  }

  getStarted() {
    // Go to specified redirect or dashboard
    if (this.redirect) {
      this.router.navigateByUrl(this.redirect);
    } else {
      this.router.navigateByUrl('/profiles');
    }
  }
}
