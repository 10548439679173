/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  Input,
} from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import * as moment from 'moment';
import { catchError, of } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton, MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix, MatHint } from '@angular/material/form-field';


@Component({
    selector: 'app-private-account-information',
    templateUrl: './private-account-information.component.html',
    styleUrls: ['./private-account-information.component.scss'],
    // providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-US' }],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatIconButton,
    MatSuffix,
    MatTooltip,
    MatIcon,
    MatHint,
    MatButton,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatCheckbox,
    MatMiniFabButton,
    FaIconComponent
],
})
export class PrivateAccountInformationComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() isOnboard = false;
  @Input() boardMode = 'full';
  @Input() color = 'primary';
  @Input() showEmail = true;
  @Input() showDob = true;

  @ViewChild('ref') dob: ElementRef;

  accountInfoForm = this.fb.group({
    email: [''],
    email_verification_code: [''],
    dateOfBirth: [null, Validators.required],
  });

  // Email Variables
  sendCodeInProgress = false;
  verifyCodeInProgress = false;
  resendCodeSecondsLeft = 0;
  verifyEmailButtonDisabled = true;
  profile = null;

  // DOB Variables
  maxDob: Date;
  minDob: Date;
  is18Above = true;
  is13Below = false;
  tosAgree = false;

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private jwt: JWTTokenService,
    private account: AccountService,
    private profiles: ProfilesService,
    private utilities: UtilitiesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initializeDob();
    this.retrieveInformation();

    // this.updateDate();

    //to check and ask for consent if age is below 18 years
    this.accountInfoForm.get('dateOfBirth').valueChanges.subscribe((data) => {
      if (data) {
        const age = moment().diff(data, 'years');
        if (age < 13) {
          const control = new FormControl(false, Validators.requiredTrue);

          this.accountInfoForm.addControl('childCheck', control);
          this.is18Above = false;
          this.is13Below = true;
        } else if (age < 18) {
          const control = new FormControl(false, Validators.requiredTrue);

          this.accountInfoForm.addControl('childCheck', control);

          this.is18Above = false;
        } else {
          this.accountInfoForm.removeControl('childCheck');
          this.is18Above = true;
          this.is13Below = false;
        }
      }
    });
  }

  // filteredTimezones: Observable<string[]>;

  // guessTimezone() {
  //   return mt.tz.guess();
  // }

  // tzValidator(timezones: string[]): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     const validTz = timezones.includes(control.value);
  //     return validTz ? null : { forbiddenTz: { value: control.value } };
  //   };
  // }

  // updateDate() {
  //   this.accountInfoForm.get('dateOfBirth').valueChanges.subscribe((data) => {
  //     console.log(data);
  //   });
  // }

  initializeDob() {
    const today = new Date();
    const defaultDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    // this.accountInfoForm.patchValue({ dateOfBirth: defaultDate });
    this.maxDob = new Date(
      // today.getFullYear() - 14, changing to show message for all ages
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    this.minDob = new Date(
      today.getFullYear() - 105,
      today.getMonth(),
      today.getDate()
    );
  }

  async retrieveInformation() {
    const {
      dob: dateOfBirth,
    } = this.utilities.accountDetails;

    this.profile = await this.profiles.retrieveProfile()

    const email = this.profile.unverified_email || this.profile.email;
    console.log(email)

    this.accountInfoForm.patchValue({
      email,
      dateOfBirth,
    });
  }

  // dobChanges(value) {
  //   console.log(value);
  //   if (value.length === 2 || value.length === 5) {
  //     // console.log(value);

  //     // this.accountInfoForm.patchValue({dateOfBirth:`${value}/`});
  //     this.dob.nativeElement.value = `${value}/`;
  //   }
  // }

  // async getLocation() {
  //   const { zipCode } = await this.locationService.getLocation();

  //   this.accountInfoForm.patchValue({ zipCode });
  //   // this.locationService.getPosition().then((pos) => {
  //   //   console.log(`Position: ${pos.lng} ${pos.lat}`);
  //   // });
  // }

  // nextPage() {
  //   console.log(this.accountInfoForm.value);
  //   const {
  //     email,
  //     dateOfBirth,
  //   } = this.accountInfoForm.value;

  //   const userId = this.jwt.getSub();

  //   const mainProfileId = this.utilities.profileId;

  //   const accountBody = {
  //     email,
  //     dob: new Date(dateOfBirth).toISOString().slice(0, 10),
  //   };

  //   const profileBody = {
  //     email,
  //     dob: new Date(dateOfBirth).toISOString().slice(0, 10),
  //   };

  //   console.log(userId, { accountBody }, { profileBody });

  //   //update account
  //   this.account.updateAccount(userId, accountBody).subscribe((data: any) => {
  //     console.log(data);
  //     this.utilities.accountDetails = data;
  //   });

  //   //update profile
  //   this.profiles
  //     .updateProfile(mainProfileId, profileBody)
  //     .subscribe((data: any) => {
  //       console.log(data);
  //       this.utilities.profileDetails = data;
  //     });
  // }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  updateEmail(email) {
    this.sendCodeInProgress = true;
    const mainProfileId = this.utilities.profileId;

    this.profiles
      .updateProfile(mainProfileId, {
        unverified_email: email
      })
      .pipe(catchError((err) => of(err)))
      .subscribe((data) => {
        if (data._id) {
          // Update the countdown every second
          this.resendCodeSecondsLeft = 30;
          const self = this;

          const countdownTimer = setInterval(() => {
            self.resendCodeSecondsLeft -= 1;
            if (self.resendCodeSecondsLeft <= 0) {
              self.resendCodeSecondsLeft = 0;
              clearInterval(countdownTimer);
            }
          }, 1000);

          this.sendCodeInProgress = false;

          this.snackBar.open('Sent!', 'OK', {
            duration: 3000,
          });

          this.utilities.profileDetails = data;
          this.retrieveInformation();
        } else {
          this.snackBar.open(data.error.message, 'OK');
          this.verifyCodeInProgress = false;
        }
      });
  }

  verifyCode(code) {
    this.sendCodeInProgress = true;
    const mainProfileId = this.utilities.profileId;

    this.profiles
      .updateProfile(mainProfileId, {
        email_verification_code: code
      })
      .pipe(catchError((err) => of(err)))
      .subscribe((data) => {
        if (data._id) {
          this.accountInfoForm.get('email_verification_code').patchValue('');
          this.resendCodeSecondsLeft = 0;

          this.sendCodeInProgress = false;

          this.snackBar.open('Verified!', 'OK', {
            duration: 3000,
          });

          this.accountInfoForm.get('email').markAsPristine();
          this.utilities.profileDetails = data;
          this.retrieveInformation();
        } else {
          this.snackBar.open(data.error.message, 'OK');
          this.verifyCodeInProgress = false;
        }
      });
  }

  updateDob(dob) {
    const userId = this.jwt.getSub();

    // Update Account
    this.account.updateAccount(userId, {
      dob: new Date(dob).toISOString().slice(0, 10),
    }).subscribe((data: any) => {
      console.log(data);
      if (data._id) {
        this.utilities.accountDetails = data;
        this.snackBar.open("Account Information Updated", "OK", {duration: 2000})
        this.retrieveInformation();
      } else {
        this.snackBar.open(data.error.message, 'OK');
      }
    });

    this.next.emit();
  }

  // saveChanges(result) {
  //   if (result.email) {
  //     const {
  //       email
  //     } = result;
  //     this.sendCodeInProgress = true;
  //   } else if (result.email_verification_code) {
  //     const mainProfileId = this.utilities.profileId;
  //     const {
  //       email_verification_code,
  //     } = result;
  //     this.verifyCodeInProgress = true;
  //   }

  //   /* Create body, leaving out any fields that are null */
  //   const body = Object.assign(
  //     {},
  //     this.isDefined(email) ? { unverified_email: email }: null,
  //     this.isDefined(email_verification_code) ? { email_verification_code }: null,
  //   );

  //   /* Create or Update profile from body */
  //   this.profiles
  //     .updateProfile(mainProfileId, body)
  //     .pipe(catchError((err) => of(err)))
  //     .subscribe((data) => {
  //       console.log(data);
  //       if (data._id) {
  //         let message = "Updated!"
  //         if (this.sendCodeInProgress) {
  //           message = "Sent!"

  //           this.resendCodeSecondsLeft = 30;
  //           var self = this;

  //           // Update the countdown every second
  //           const countdownTimer = setInterval(function() {
  //             self.resendCodeSecondsLeft -= 1;
  //             if (self.resendCodeSecondsLeft <= 0) {
  //               self.resendCodeSecondsLeft = 0;
  //               clearInterval(countdownTimer);
  //             }
  //           }, 1000);
  //         } else if (this.verifyCodeInProgress) {
  //           message = "Verified!"
  //           this.profileForm.get('email_verification_code').patchValue('')
  //           this.resendCodeSecondsLeft = 0
  //         }
  //         this.snackBar.open(message, 'OK', {
  //           duration: 3000,
  //         });
  //         this.inProgress = false;
  //         this.sendCodeInProgress = false;
  //         this.verifyCodeInProgress = false;
  //         this.profileForm.markAsPristine()
  //         this.done.emit();
  //       } else {
  //         this.snackBar.open(data.error.message, 'OK');
  //         this.inProgress = false;
  //         this.sendCodeInProgress = false;
  //         this.verifyCodeInProgress = false;
  //       }
  //     });
  // }

  // private _filterTimezones(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return mt.tz.names().filter((tz) => tz.toLowerCase().includes(filterValue));
  // }

  handleCodeInput(value: string) {
    if (value.length === 6) {
      this.verifyEmailButtonDisabled = false;
    } else {
      this.verifyEmailButtonDisabled = true;
    }
  }
}
