import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitiesService } from '../services/utilities.service';
import { FeaturesService } from '../services/features.service';
import { OrdersService } from '../services/orders.service';
import { JWTTokenService } from '../services/jwttoken.service';
import { AuthService } from '../services/auth.service';
import { KickStartService } from '../services/kick-start.service';
import { PledgeService } from '../services/pledge.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { PaymentInformationComponent } from '../shared/payment-information/payment-information.component';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';


const ADVERTISER_ID = '77245da0-4709-4f01-81c1-2cb69c45c0df';

@Component({
    selector: 'app-payment-confirmation',
    templateUrl: './payment-confirmation.component.html',
    styleUrls: ['./payment-confirmation.component.scss'],
    standalone: true,
    imports: [
    MatIconButton,
    MatIcon,
    MatCard,
    PaymentInformationComponent,
    MatButton
],
})
export class PaymentConfirmationComponent implements OnInit {
  paymentStatus;
  paymentPlan;
  checkoutSessionId;
  orderId;
  order;
  promo;

  loading = true;
  isAuthenticated = false;
  bannerOpen = true;

  constructor(
    private router: Router,
    private orders: OrdersService,
    private route: ActivatedRoute,
    private jwt: JWTTokenService,
    private auth: AuthService,
    private snackbar: MatSnackBar,
    private kickstart: KickStartService,
    private pledge: PledgeService
  ) {}

  async ngOnInit() {
    this.paymentStatus = this.route.snapshot.queryParamMap.get('status');
    this.checkoutSessionId =
      this.route.snapshot.queryParamMap.get('session_id');
    this.orderId =
      this.route.snapshot.queryParamMap.get('order_id');
    this.promo = this.route.snapshot.queryParamMap.get('promo');
    this.isAuthenticated = this.jwt.isAuthenticatedUser();

    if (this.promo === 'kickstart') {
      this.handleKickstart();
    } else if (this.promo === 'pledge') {
      this.handlePledge();
    }

    const filter = this.checkoutSessionId ? { 
      transaction_info__session_id: this.checkoutSessionId
    } : {
      id: this.orderId
    }

    if (this.paymentStatus === 'success') {
      const response = await firstValueFrom(this.orders.listOrders(filter));
      console.log(response);
      this.order = response.count > 0 ? response.results[0] : null;

      if (this.order) {
        this.orders.trackOrder(this.order);
      }
    }

    this.loading = false;
  }

  handleKickstart() {
    this.kickstart.verifyPurchase(this.checkoutSessionId).subscribe({
      next: (data) => {
        // this.paymentValid = true;
        this.kickstart.setKickStartValue(
          'checkoutSessionId',
          this.checkoutSessionId
        );
      },
      error: (err) => {
        this.paymentStatus = 'failed';

        const snackbarRef = this.snackbar.open(
          'There was an issue processing your payment. Please try again or contact support.',
          'Ok'
        );

        snackbarRef.afterDismissed().subscribe(() => {
          this.router.navigateByUrl('kickstart-promo/confirm-payment');
        });
      },
      complete: () => {
        // this.isLoading = false;
      },
    });
  }

  handlePledge() {
    this.pledge.verifyPurchase(this.checkoutSessionId).subscribe({
      next: (data) => {
        // this.paymentValid = true;
        this.pledge.setPledgeValue('checkoutSessionId', this.checkoutSessionId);
      },
      error: (err) => {
        this.paymentStatus = 'failed';

        const snackbarRef = this.snackbar.open(
          'There was an issue processing your payment. Please try again or contact support.',
          'Ok'
        );

        snackbarRef.afterDismissed().subscribe(() => {
          this.router.navigateByUrl('pledge-promo/pledge-share');
        });
      },
      complete: () => {
        // this.isLoading = false;
      },
    });
  }

  navigate(route) {
    if (route === 'signup') {
      localStorage.setItem('providerMode', 'signup');
      this.auth.navigateToAuthPage();
    } else {
      this.router.navigateByUrl(route);
    }
  }
}
