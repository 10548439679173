import {
  Directive,
  Input,
  ElementRef,
  RendererFactory2,
  ViewContainerRef,
  HostBinding,
  OnInit,
  EventEmitter,
  Output,
  runInInjectionContext,
  inject,
  EnvironmentInjector,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UtilitiesService } from '../services/utilities.service';
import { FeatureLockIconComponent } from '../shared/feature-lock-icon/feature-lock-icon.component';
import { FeatureLockClickerComponent } from '../helper/feature-lock-clicker/feature-lock-clicker.component';
import { PageService } from '../services/page.service';

@Directive({
    selector: '[appFeatureLocked]',
    standalone: true,
})
export class FeatureLockDirective implements OnInit, OnChanges {
  @Input() featureIdentifier: string;
  @Input() iconXOffset: number;
  @Input() iconYOffset: number;
  @Input() iconXOffsetAnchor: string = 'right';
  @Input() iconYOffsetAnchor: string = 'bottom';
  @Input() featureName: string;
  @Input() featureMessage: string;
  @Input() iconColor: string = 'primary';
  @Input() iconSize: number = 10;
  @Input() openFeatureLockDialog: boolean = true;
  @Input() showLock: boolean = true;

  @Output() featureLockDialogClosed = new EventEmitter();
  @Output() lockTriggered = new EventEmitter();

  @HostBinding('attr.disabled')
  disabled;

  @HostBinding('class.mat-mdc-button-disabled')
  buttonDisabled;

  loading = false;

  constructor(
    private el: ElementRef,
    // private renderer: Renderer2, //changing renderer to render factory
    private rendererFactory: RendererFactory2, // Use RendererFactory2
    private utilities: UtilitiesService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit() {
    // console.log(this.featureIdentifier);

    const isEnabled = this.utilities.isFeatureEnabled(this.featureIdentifier)

    if (!isEnabled && this.showLock) {
      this.createLock()
    } else if (!isEnabled) {
      // TODO: add some kind of loading overlay so that it doesn't show as locked yet but still prevents clicking the element.
      this.loading = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showLock && this.loading) {
      const isEnabled = this.utilities.isFeatureEnabled(this.featureIdentifier)
      if (!isEnabled && changes.showLock.currentValue) {
        this.createLock()
      } else {
        this.loading = false;
      }
    }
  }

  createLock() {
    console.log(this.el.nativeElement);
    const renderer = this.rendererFactory.createRenderer(null, null);

    // Disable element
    // this.renderer.addClass(this.el.nativeElement, 'feature-locked');//updating renderer logic
    // renderer.setAttribute(this.el.nativeElement, 'class', 'feature-locked');

    // Need to re-enable click events for the element in case they were disabled
    renderer.setStyle(this.el.nativeElement, 'pointer-events', 'all');

    // In case the feature lock clicker doesn't cover the entire clickable area.
    this.el.nativeElement.removeAllListeners('click');

    // this.disabled = true;
    this.buttonDisabled = true;
    // this.checkboxDisabled = true;
    // this.mdcCheckboxDisabled = true;

    // Create and append child components
    const featureLockComponentRef = this.viewContainerRef.createComponent(
      FeatureLockIconComponent
    );

    featureLockComponentRef.instance.iconColor = this.iconColor;
    featureLockComponentRef.instance.iconSize = this.iconSize;
    featureLockComponentRef.instance.xOffsetAnchor = this.iconXOffsetAnchor;
    featureLockComponentRef.instance.yOffsetAnchor = this.iconYOffsetAnchor;

    const clickerComponentRef = this.viewContainerRef.createComponent(
      FeatureLockClickerComponent
    );
    clickerComponentRef.instance.featureName = this.featureName;
    clickerComponentRef.instance.featureMessage = this.featureMessage;
    clickerComponentRef.instance.openFeatureLockDialog =
      this.openFeatureLockDialog;
    clickerComponentRef.instance.dialogClosed.subscribe((result) => {
      this.featureLockDialogClosed.emit(result);
    });
    clickerComponentRef.instance.clicked.subscribe((result) => {
      this.lockTriggered.emit(result);
    });

    let iconContainer = null;

    console.log(this.el);
    switch (this.el.nativeElement.tagName || this.el.nativeElement.nodeName) {
      case 'MAT-CHECKBOX':
        // adding it to each individual based on scenario
        renderer.addClass(this.el.nativeElement, 'feature-locked');
        iconContainer = this.el.nativeElement.querySelector(
          // '.mat-checkbox-inner-container'//changing to updated class name
          '.mdc-checkbox__background'
        );
        featureLockComponentRef.instance.xOffset =
          this.iconXOffset === undefined ? -12 : this.iconXOffset;
        featureLockComponentRef.instance.yOffset =
          this.iconYOffset === undefined ? -12 : this.iconYOffset;
        break;
      case 'MAT-SLIDE-TOGGLE':
        // // Disable the underlying button
        // const buttonElement = this.el.nativeElement.querySelector('button');
        // if (buttonElement) {
        //   renderer.setAttribute(buttonElement, 'disabled', 'true');
        // }
        // Disable the underlying mat-slide-toggle
        // const matSlideToggle = this.el.nativeElement;
        // if (matSlideToggle) {
        //   renderer.setProperty(matSlideToggle, 'ng-reflect-disabled', true);
        // }
        // adding it to each individual based on scenario
        renderer.addClass(this.el.nativeElement, 'feature-locked');
        iconContainer = this.el.nativeElement.querySelector(
          // '.mat-checkbox-inner-container'//changing to updated class name
          '.mdc-form-field'
        );
        featureLockComponentRef.instance.xOffset =
          this.iconXOffset === undefined ? -12 : this.iconXOffset;
        featureLockComponentRef.instance.yOffset =
          this.iconYOffset === undefined ? -12 : this.iconYOffset;
        break;
      case 'INPUT':
        renderer.addClass(this.el.nativeElement, 'feature-locked');
        iconContainer = this.el.nativeElement.parentElement.parentElement;
        featureLockComponentRef.instance.xOffset =
          this.iconXOffset === undefined ? -8 : this.iconXOffset;
        featureLockComponentRef.instance.yOffset =
          this.iconYOffset === undefined ? -8 : this.iconYOffset;
        break;
      case 'DIV':
        renderer.addClass(this.el.nativeElement, 'feature-locked');
        iconContainer = this.el.nativeElement;
        featureLockComponentRef.instance.xOffset =
          this.iconXOffset === undefined ? -8 : this.iconXOffset;
        featureLockComponentRef.instance.yOffset =
          this.iconYOffset === undefined ? -8 : this.iconYOffset;
        break;
      default:
        iconContainer = this.el.nativeElement;
        featureLockComponentRef.instance.xOffset =
          this.iconXOffset === undefined ? -8 : this.iconXOffset;
        featureLockComponentRef.instance.yOffset =
          this.iconYOffset === undefined ? -8 : this.iconYOffset;
        break;
    }

    renderer.appendChild(
      iconContainer,
      featureLockComponentRef.location.nativeElement
    );
    renderer.appendChild(
      iconContainer,
      clickerComponentRef.location.nativeElement
    );

    console.log(this.el);

    this.loading = false;
  }
}
