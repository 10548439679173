import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-guest-more',
    templateUrl: './guest-more.component.html',
    styleUrls: ['./guest-more.component.scss'],
    standalone: true,
})
export class GuestMoreComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() later = new EventEmitter();
  laterStatus = false;

  constructor(public dialogRef: MatDialogRef<GuestMoreComponent>) {}

  ngOnInit() {
    this.laterStatus = Boolean(localStorage.getItem('maybeLater'));
    console.log(this.laterStatus);
  }

  nextPage() {
    this.next.emit();
  }
  laterPage() {
    if (this.laterStatus) {
      localStorage.removeItem('maybeLater');

      this.dialogRef.close();
    } else {
      this.later.emit();
    }
  }
}
