/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { api } from '../../config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class AlternativeVerificationService {
  constructor(private http: HttpClient) {}

  sendMessage(body) {
    return this.http.post<any>(api.alternativeVerification, body);
  }

  getSocialVerificationCode(pageId, profileId) {
    return this.http.post<any>(api.verificationCode, {
      bio: pageId,
      profile: profileId
    });
  }
}
