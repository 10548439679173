import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-pricing-warm-up',
    templateUrl: './pricing-warm-up.component.html',
    styleUrls: ['./pricing-warm-up.component.scss'],
    standalone: true,
    imports: [MatButton],
})
export class PricingWarmUpComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  constructor() {}

  ngOnInit() {}

  nextPage() {
    this.next.emit()
  }

  prevPage() {
    this.prev.emit()
  }

  closeDialog() {
    this.close.emit()
  }
}
