// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.font {
  font-family: "Poppins", sans-serif;
}

.custom-list {
  list-style-type: none; /* Remove the default bullet style */
}

.custom-list li {
  list-style-image: none; /* Remove the default bullet style for each list item */
  list-style-type: none; /* Remove the default bullet style */
}

.custom-list li::before {
  content: "•"; /* Use the Unicode bullet (•) as the custom bullet */
  color: #41B6E6; /* Change the color of the bullet to red */
  display: inline-block;
  width: 10px; /* Adjust the size of the bullet as needed */
  text-align: start;
  margin-right: 5px; /* Adjust the spacing between the bullet and the text */
}

.custom-list li::marker {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
