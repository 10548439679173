import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, catchError, firstValueFrom } from 'rxjs';
import { MoreInfoComponent } from 'src/app/helper/more-info/more-info.component';

import { Location, TitleCasePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-verify-social-media',
    templateUrl: './verify-social-media.component.html',
    styleUrls: ['./verify-social-media.component.scss'],
    standalone: true,
    imports: [
    MatButton,
    TitleCasePipe
],
})
export class VerifySocialMediaComponent implements OnInit {
  @Output() changePage = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() oauthData = {};

  provider = '';
  isLoading = true;
  success = false;
  fast_access = false;
  validatedSocialCount;
  badgeActive = false;
  validatedSocials = [];
  origin = '';
  linkAdded = false;

  profile = null;

  isEditOrigin = false;
  isChooseSocialOrigin = false;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private utilities: UtilitiesService,
    private authorize: AuthorizationService,
    private profiles: ProfilesService
  ) {}

  ngOnInit() {
    // If profileId not in local storage, get the default bio from account
    let profileId = localStorage.getItem('profileId');
    if (!profileId) {
      profileId = this.utilities.profileId;
    }

    console.log('ID');
    console.log(profileId);

    this.provider = this.oauthData['provider'];
    this.success = this.oauthData['success'] === 'True';
    this.linkAdded = this.oauthData['linkAdded'] === 'True';

    this.getVerifiedSocials(profileId);

    console.log(this.provider);
  }

  getVerifiedSocials(profileId) {
    // Check if the profile has access to the simplified social media badge
    this.profiles.retrieveProfile(profileId).then((data) => {
      console.log(data);
      for (const profileBadge of data.badges) {
        if (profileBadge.badge.badge_type === 'social-media') {
          this.validatedSocialCount = profileBadge.verified_data?.verified_socials.length;
          this.badgeActive = profileBadge.is_active;
        }
      }
      this.fast_access = data.features.enabled_features.some(
        (obj) => obj.title === 'Fast Social Badge'
      );
      this.isLoading = false;
    });
  }

  navigate(route) {
    switch (route) {
      case 'change-plan': {
        this.router.navigateByUrl('/account/change-plan');
        this.close.emit();
        break;
      }
    }
  }
}
