import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-guest-preview',
    templateUrl: './guest-preview.component.html',
    styleUrls: ['./guest-preview.component.scss'],
    standalone: true,
    imports: [FaIconComponent, MatButton],
})
export class GuestPreviewComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();

  constructor(private router: Router, private dialogRef: MatDialog) {}

  ngOnInit() {}

  navigate(route) {
    this.dialogRef.closeAll();
    this.next.emit({ router_step: route, wait_for_update: true });
  }

  nextPage() {
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }
}
