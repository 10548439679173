// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font {
  font-family: "Poppins", sans-serif;
}

/* Hide the default arrow icon in the date input */
.date-input::-webkit-calendar-picker-indicator {
  pointer-events: none;
}

/* Disable interaction with the date picker icon */
.date-input {
  /* Optional: Customize the appearance of the input field */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  /* Add any other styles you want */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
