// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 15px;
}

.skip-btn {
  position: absolute;
  right: 16px;
  bottom: 68px;
  width: -moz-fit-content;
  width: fit-content;
}

.skip-btn:disabled {
  opacity: 0.8 !important;
}

.spotlight-video-banner {
  background-color: var(--primary);
  height: -moz-fit-content;
  height: fit-content;
  padding: 16px;
  color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
