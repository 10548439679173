// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-price {
  color: var(--mat-theme-text);
  font-size: 35pt;
  line-height: 30pt;
  font-weight: 300;
}

.currency-sign {
  color: var(--mat-theme-text);
  font-size: 12pt;
}

.recurring-text {
  color: var(--mat-theme-text);
  font-size: 11pt;
}

.trial-price-text {
  color: var(--mat-theme-text);
  font-size: 11pt;
}

.trial-text {
  color: var(--mat-theme-text);
  font-size: 9pt;
}

.default-price-text {
  color: var(--mat-theme-text);
  font-size: 9pt;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
