import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from 'src/config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class ExternalService {
  constructor(private http: HttpClient) {}

  checkIfUrlExists(url: string, icon: string) {
    // return this.http.get<any>(url);

    const body = { url, icon };
    return this.http.post<any>(api.validateLink, body);
  }

  openNewTab(url, target: string) {
    // Abort if url is null or empty
    if (!url) {
      return
    }

    // Ensure that the url starts with http
    if (
      !url.startsWith('https://') && 
      !url.startsWith('http://')
    ) {
      // Default to https:// in the case where it isn't specified.
      url = `https://${url}`
    }

    // Open a new tab
    let windowRef = window.open(url, target);
    if (!windowRef) {
      windowRef = window.open(url, '_self');
    }
    if (!windowRef) {
      alert('Unable to open URL.');
      return null;
    }

    // Return the target of the new window created
    if (windowRef.opener !== windowRef) {
      return '_blank';
    } else {
      return '_self';
    }
  }
}
