/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
} from '@angular/core';

import * as confetti from 'canvas-confetti';
import { MoreInfoComponent } from 'src/app/helper/more-info/more-info.component';

import { Location, NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, NgModel, UntypedFormBuilder, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { ReferralService } from 'src/app/services/referral.service';
import { api } from '../../../config/apiUrls';
import { firstValueFrom, fromEvent } from 'rxjs';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DomainService } from 'src/app/services/domain.service';
import { ProductsService } from 'src/app/services/products.service';
import { StripeService } from 'src/app/services/stripe.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageService } from 'src/app/services/page.service';
import { UsernameValidatorDirective } from 'src/app/directives/username-validator.directive';
import { MatButton } from '@angular/material/button';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ProductInfoComponent } from '../../shared/product-info/product-info.component';
import { MatIcon } from '@angular/material/icon';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FeatureLockDirective } from '../../directives/feature-lock.directive';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatFormField, MatLabel, MatSuffix, MatHint, MatError } from '@angular/material/form-field';

@Component({
    selector: 'app-username',
    templateUrl: './username.component.html',
    styleUrls: ['./username.component.scss'],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    MatSelectTrigger,
    MatOption,
    MatInput,
    FeatureLockDirective,
    FaIconComponent,
    MatSuffix,
    MatHint,
    MatError,
    CdkCopyToClipboard,
    NgClass,
    MatIcon,
    ProductInfoComponent,
    SlickCarouselModule,
    MatButton
],
})
export class UsernameComponent implements OnInit {
  @ViewChild('usernameInput', { static: true })
  input: ElementRef;

  @ViewChild('usernameInput') inputModel: NgModel;

  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Output() change = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';
  @Input() showNavigate = true;
  @Input() isOnboard = true;
  @Input() includePromo = false;

  @ViewChild('slickModal') slickModal;

  showSlide = true; //false;
  isLastSlide = false;
  isSaving = false;
  isCreator = false;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: false,
  };

  domains: any[] = [];
  fullLinkStatus = 'available';
  fullLinkMessage = 'Enter a Username';
  fullLinkClass = 'loading'; //'text-light bg-[#4ecbc4]'; //'text-light bg-success';
  isPaidUser = false;
  hasPremiumDomains = false;

  usernameSubscriber;

  form: FormGroup;
  linkPreview = 'socialvalid.com/';
  alternate_free_usernames = [];
  alternate_paid_usernames = [];
  remainingUsernameChanges = -1;
  previousUsernameCount = 0;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private utilities: UtilitiesService,
    private profiles: ProfilesService,
    private domainService: DomainService,
    private products: ProductsService,
    private stripe: StripeService,
    private page: PageService,
    private fb: UntypedFormBuilder,
    private profileService: ProfilesService
  ) {}

  async ngOnInit() {
    const features = this.utilities.featureList;
    const username = this.utilities.profileDetails.username;

    this.isCreator =
      this.profileService.isFeatureEnabledForProfile('creator-portfolio');

    this.makeForm();
    this.form.disable();

    /* Get number of username changes remaining */
    if (this.utilities.profileDetails.username_history) {
      this.previousUsernameCount =
        this.utilities.profileDetails.username_history.length;
      this.remainingUsernameChanges =
        this.previousUsernameCount <= 4 ? 4 - this.previousUsernameCount : 0;
    } else {
      this.previousUsernameCount = 0;
      this.remainingUsernameChanges = 4;
    }

    let formData: any = {};

    /* Update link preview when input changes */
    this.form.valueChanges.subscribe(async (data) => {
      const { username, domainName } = this.form.value;
      this.updateLinkPreviewText(domainName, username);
    });

    /* Update link status when username validation finishes */
    this.form.statusChanges.subscribe((data) => {
      if (this.form.get('username').touched) {
        this.updateUsernameStatus(data);
      }
    });

    /* Set username from profile */
    if (username && username !== '') {
      formData.username = username;
    }

    /* Populate username from local storage */
    const usernameFromStorage = localStorage.getItem('username');
    if (usernameFromStorage) {
      formData.username = usernameFromStorage;
    }

    /* Get domain and short */
    const page = await this.profiles.getMainPageForProfile(
      undefined,
      true,
      false,
      'badges,customization_options,referral_banner,brand_deals,social_metrics,reviews,average_review_score,background_image_url,contact_card_image_url,file_download_url,collaborations,spotlight_footer,links,passport_link,about_link,banner_bio_link,banner_about_link,active_theme,features'
    );
    if (page?.preferred_domain) {
      formData.domainId = page.preferred_domain._id;
      formData.domainName = page.preferred_domain.name;
    }

    /* Load Domains */
    const data = await this.domainService.listDomains();
    this.domains = [];
    for (let domain of data) {
      this.domains.push({
        value: domain._id,
        viewValue: domain.name,
        isPremium: domain.premium,
      });
    }

    if (formData.username && formData.username !== '') {
      this.updateUsernameStatus('VALID')
    }

    this.isPaidUser = features['premium-usernames'];
    this.hasPremiumDomains = features['alt-domains'];

    this.form.patchValue(formData);
    this.form.enable();
  }

  makeForm() {
    this.form = this.fb.group(
      {
        username: [
          '',
          [],
          [
            UsernameValidatorDirective.createValidator(
              this.profileService,
              this.snackBar,
              this.utilities.profileId
            ),
          ],
        ],
        domainId: ['', Validators.required],
        domainName: ['', Validators.required],
      },
      {
        updateOn: 'change',
      }
    );
  }

  updateDomain(id) {
    const domain = this.domains.find((domain) => domain.value === id);
    this.form.patchValue({
      domainId: id,
      domainName: domain.viewValue,
    });
  }

  updateLinkPreviewText(domainName, username) {
    const preview = document.getElementById('linkPreview');
    const maxChars = preview ? preview.offsetWidth / 10 : 22;

    const result = `${domainName}/${username}`;
    this.linkPreview =
      result.length <= maxChars ? result : `...${result.slice(-maxChars)}`;
  }

  updateUsernameStatus(data) {
    console.log(data)

    if (data === 'VALID') {
      this.fullLinkStatus = 'available';
      this.fullLinkMessage = 'Available';
      this.fullLinkClass = 'available';
    } else if (data === 'PENDING') {
      this.fullLinkStatus = 'loading';
      this.fullLinkMessage = 'Checking...';
      this.fullLinkClass = 'loading';
    } else if (
      data === 'INVALID' &&
      this.form.get('username')?.hasError('noPremiumAccess')
    ) {
      this.fullLinkStatus = 'premium';
      this.fullLinkMessage = 'Available with PLUS';
      this.fullLinkClass = 'plus';
    } else if (
      data === 'INVALID' &&
      this.form.get('username')?.hasError('usernameTaken')
    ) {
      this.fullLinkStatus = 'error';
      this.fullLinkMessage = 'Taken';
      this.fullLinkClass = 'error';
    } else if (
      data === 'INVALID' && (
        !this.form.get('username')?.value ||
        this.form.get('username')?.value === ''
      )
    ) {
      this.fullLinkStatus = 'available';
      this.fullLinkMessage = 'Enter a Username';
      this.fullLinkClass = 'loading';
    } else if (data === 'INVALID') {
      this.fullLinkStatus = 'error';
      this.fullLinkMessage = 'Invalid Username';
      this.fullLinkClass = 'error';
    }

    this.alternate_free_usernames =
      this.form.get('username')?.getError('alternativeFree') || [];
    this.alternate_paid_usernames =
      this.form.get('username')?.getError('alternativePaid') || [];
  }

  copy() {
    return this.snackBar.open('Link copied to clipboard', 'OK', {
      duration: 2000,
    });
  }

  afterChange(e) {
    this.isLastSlide = e.last;
    if (this.isLastSlide) {
      // this.slideConfig.autoplay = false;
      this.slickModal.slickPause();
    }
  }

  activatePlan(priceId, productId, mode, planName, planCost) {
    const body = {
      items: [{ price: priceId, quantity: 1 }],
      mode,
      product_id: productId,
      success_url: `${api.stripeCallback}/payment/confirmation?status=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${api.stripeCallback}/payment/confirmation?status=failed`,
    };

    this.stripe.purchase(body).subscribe((data) => {
      console.log(data);
      window.open(data.redirect_url, '_self');
    });

    console.log(body);
  }

  upgrade() {
    this.products.listProducts().subscribe((data) => {
      console.log(data);
      const plus = data.results.filter((item) => item.identifier === 'plus')[0];
      const price = plus.prices.find(
        (item) => item.lookup_key === 'plus_semi_annual_guest'
      );
      console.log(price);
      this.activatePlan(
        price.id,
        plus._id,
        price.metadata.checkout_mode,
        plus.title,
        price.unit_amount
      );
    });
  }

  async nextPage() {
    this.isSaving = true;

    const { username, domainId } = this.form.value;

    if (username !== '') {
      const profileId = this.utilities.profileId;
      console.log(this.utilities.profile);

      // Update profile with new username
      const profileResponse: any = await firstValueFrom(
        this.profiles.updateProfile(
          profileId,
          {
            username: username,
            // preferred_page: this.boardMode === 'creator' ? 'portfolio' : 'bio',  // Now set during sign up
          },
          undefined,
          { minimized_fields: 'all' }
        )
      );

      this.utilities.profile.username = username;
      this.profileService.updateUsername(profileId, username);

      // Update all main pages with preferred domain (ASYNC)
      for (let pageType of ['bio', 'about', 'passport', 'portfolio']) {
        this.page
          .retrieveMainPage(pageType, false, 'all', false)
          .then((page) => {
            if (page) {
              if (page.is_main) {
                const pageTypeTitleCase =
                  pageType.charAt(0).toUpperCase() + pageType.slice(1);
                this.utilities[
                  `main${pageTypeTitleCase}Details`
                ].preferred_domain = {
                  _id: domainId,
                  name: this.domains.find((d) => d.value === domainId)
                    ?.viewValue,
                };
              }

              this.page.updatePage(
                page._id,
                pageType,
                {
                  preferred_domain: domainId,
                },
                undefined,
                undefined,
                { minimized_fields: 'all' },
                false
              );
            }
          });
      }

      // Update remaining username changes
      if (profileResponse && profileResponse.username_history) {
        this.previousUsernameCount = profileResponse.username_history.length;
        this.remainingUsernameChanges =
          this.previousUsernameCount <= 4 ? 4 - this.previousUsernameCount : 0;
      }

      this.isSaving = false;
      this.form.markAsPristine();
      this.form.markAsUntouched();

      this.snackBar.open('Username and domain updated.', 'OK', {
        duration: 3000,
      });
    }

    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  navigate(route) {
    switch (route) {
      case 'change-plan': {
        this.router.navigateByUrl('/account/change-plan');
        this.closeDialog();
        break;
      }
      default: {
        this.router.navigateByUrl('/');
      }
    }
  }

  handleInput(event) {
    this.form.patchValue({
      username: event.target.value.replace(/[^a-zA-Z0-9-_]/g, ''),
    });

    // Don't wait until blur to mark as touched.
    this.form.get('username').markAsTouched();
  }

  setUsername(name) {
    this.form.patchValue({ username: name });
  }
}
