import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';

import { GuestPricingDialogComponent } from '../guest-pricing-dialog/guest-pricing-dialog.component';
import { Router } from '@angular/router';
import { GuestOfferComponent } from '../guest-offer/guest-offer.component';
import { ProductsService } from 'src/app/services/products.service';
import { ExternalService } from 'src/app/services/external.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';


@Component({
    selector: 'app-guest-pricing',
    templateUrl: './guest-pricing.component.html',
    styleUrls: ['./guest-pricing.component.scss'],
    standalone: true,
    imports: [
    FaIconComponent,
    MatSlideToggle
],
})
export class GuestPricingComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() later = new EventEmitter();

  isMonthly = false;

  products;
  product;
  annualPlan;
  monthlyPlan;
  laterStatus = false;

  transparentBackground = false;

  isLoading = true;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private productsService: ProductsService,
    public dialogRef: MatDialogRef<GuestPricingComponent>,
    private external: ExternalService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.laterStatus = Boolean(localStorage.getItem('maybeLater'));
    console.log(this.laterStatus);
    // this.products = this.data.products;
    this.loadPrices();
  }

  loadPrices() {
    this.productsService.listProducts().subscribe(({ results: products }) => {
      console.log(products);
      this.products = products;
      this.product = this.products.find(
        (product) => product.identifier === 'blue_ribbon'
      );
      console.log(this.product);
      this.annualPlan = this.product?.prices.find(
        (price) => price.unit_amount === 3588
      );
      this.monthlyPlan = this.product?.prices.find(
        (price) => price.unit_amount === 499
      );
      this.isLoading = false;
    });
  }

  openDialog(option) {
    let dialogRef;
    if (option === 'monthly') {
      this.transparentBackground = true;
      dialogRef = this.dialog.open(GuestPricingDialogComponent, {
        panelClass: 'onboard-sub-dialog-guestFlow',
        data: { product: this.product, annualPlan: this.annualPlan },
        // backdropClass: 'frosted-backdrop',
      });
    } else {
      dialogRef = this.dialog.open(GuestOfferComponent, {
        panelClass: 'onboard-dialog-guestFlow',
        data: this.products,
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.transparentBackground = false;

      if (result.url) {
        this.next.emit({
          external_step: result.url,
          wait_for_update: true,
          // next_step: 'guest-preview'
        });
      }
    });
  }

  laterPage() {
    if (this.laterStatus) {
      localStorage.removeItem('maybeLater');
      this.dialogRef.close();
    } else {
      this.later.emit();
    }
  }

  prevPage() {
    this.prev.emit();
  }

  navigateToRoute() {
    // this.router.navigate(['https://socialvalid.com/home/pricing']);
    const url = 'https://socialvalid.com/home/pricing'; // Replace with the URL you want to open
    const target = '_blank';

    this.external.openNewTab(url, target);
  }
}
