import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: true,
})
export class LoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
