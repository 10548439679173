import { Component, OnInit } from '@angular/core';
import { DomainService } from '../services/domain.service';
import { ProfilesService } from '../services/profiles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PromoService } from '../services/promo.service';
import { MatIcon } from '@angular/material/icon';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { MatIconButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { UpperCasePipe } from '@angular/common';

@Component({
    selector: 'app-creator-invite',
    templateUrl: './creator-invite.component.html',
    styleUrls: ['./creator-invite.component.scss'],
    standalone: true,
    imports: [
    FormsModule,
    MatIconButton,
    CdkOverlayOrigin,
    MatIcon,
    CdkConnectedOverlay,
    UpperCasePipe
],
})
export class CreatorInviteComponent implements OnInit {
  isLoading = false;
  isSearchLoading = false;
  isCompleted = false;

  inviteText = 'invite only';

  showIdentity = false;

  promoCode;
  codeValid;
  codeError;
  domain;
  domainName;
  domains = [];
  username = '';
  usernameValid;
  usernameAvailable;

  showCode = true;
  showUsername = false;

  alternate_free_usernames;
  alternate_paid_usernames;
  fullLinkStatus;
  fullLinkMessage;
  fullLinkClass;

  showDomains = false;

  socials = ['facebook', 'instagram', 'twitter', 'snapchat', 'tiktok'];

  constructor(
    private domainService: DomainService,
    private profileService: ProfilesService,
    private router: Router,
    private route: ActivatedRoute,
    private promoService: PromoService
  ) {}

  ngOnInit() {
    this.promoCode = this.route.snapshot.queryParamMap.get('code')

    this.domainService.listDomains().then((data) => {
      console.log(data);
      this.domains = [];
      for (let domain of data) {
        this.domains.push({
          value: domain._id,
          viewValue: domain.name,
          isPremium: domain.premium,
        });
      }

      if (this.domains.length > 0) {
        this.domain = this.domains[0].value
        this.domainName = this.domains[0].viewValue
      }

      this.isLoading = false;
    });
  }

  changeDomain(domain) {
    console.log(domain)
    this.domain = domain.value;
    this.domainName = domain.viewValue;
  }

  customizeBio() {
    // Save code, domain, username to local storage
    localStorage.setItem('username', this.username)
    localStorage.setItem('preferredDomain', this.domain)
    localStorage.setItem('promoCode', this.promoCode)
    localStorage.setItem('boardMode', 'vipInvite')

    // Go to sandbox
    this.router.navigateByUrl("sandbox")
  }

  switchToLink() {
    this.showCode = false;
    this.showUsername = true;
  }

  checkCode() {
    this.isLoading = true;

    this.promoService.checkCode(this.promoCode, 'membership', undefined, "VIP").subscribe((data) => {
      console.log({data})
      this.codeValid = data.count === 1;
      this.codeError = data.count === 0
      this.isLoading = false;
    })

    // setTimeout(() => {
    //   this.isLoading = false;
    //   this.codeValid = true;
    // }, 2000)
  }

  checkAvailability() {
    console.log(this.username)
    if (this.username !== '') {
      this.isSearchLoading = true;
      this.profileService
        .checkProfileUsername(this.username)
        .subscribe(
          (data: any) => {
            // Success
            console.log(data);
            this.alternate_free_usernames = data.alternate_free;
            this.alternate_paid_usernames = data.alternate_paid;

            this.usernameValid = true
            this.usernameAvailable = data.availability
            this.isSearchLoading = false
            this.isCompleted = true;
          },
          (data) => {
            // Error
            this.alternate_free_usernames = [];
            this.alternate_paid_usernames = [];

            this.usernameValid = false
            this.usernameAvailable = false
            this.isSearchLoading = false
            this.isCompleted = true;
          }
        );
    }
  }
}
