import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { GuestPricingDialogComponent } from '../guest-pricing-dialog/guest-pricing-dialog.component';
import { GuestPricingComponent } from '../guest-pricing/guest-pricing.component';
import { FreeTrialComponent } from '../free-trial/free-trial.component';
import { ProductsService } from 'src/app/services/products.service';
import { firstValueFrom } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdersService } from 'src/app/services/orders.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatRipple } from '@angular/material/core';


@Component({
    selector: 'app-blue-ribbon',
    templateUrl: './blue-ribbon.component.html',
    styleUrls: ['./blue-ribbon.component.scss'],
    standalone: true,
    imports: [
    MatRipple,
    FaIconComponent
],
})
export class BlueRibbonComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() later = new EventEmitter();

  // isMonthly = false;
  products;
  isFreePlan;

  constructor(
    private dialog: MatDialog,
    private productsService: ProductsService,
    private orders: OrdersService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(this.data);
    //Add logic to go to next page if already subscribed
    this.orders.listOrders({}).subscribe((data) => {
      console.log(data);
      const isCreator = data.results.some(
        (item) => item.product.identifier === 'creator_plus'
      );
      console.log(isCreator);
      if (isCreator) {
        this.nextPage(undefined);
      } else {
        //To load the plans for subscription
        // this.upgrade();
      }
    });
    this.loadProducts();
  }

  ngOnInit() {}

  // nextPage() {
  //   // this.save();
  //   this.next.emit();
  // }

  // prevPage() {
  //   this.prev.emit();
  // }

  async loadProducts() {
    const { results: products } = await firstValueFrom(
      this.productsService.listProducts()
    );
    console.log(products);
    const isFreePlan = products.find(
      (item) => item.identifier === 'standard'
    ).is_active_for_bio;
    console.log(isFreePlan);

    if (isFreePlan) {
      this.isFreePlan = isFreePlan;
    } else {
      // this.next.emit();

      // For testing remove after testing
      this.isFreePlan = true;
    }
    this.products = products;
  }

  openDialog(option) {
    let dialogRef;
    if (option === 'blue') {
      dialogRef = this.dialog.open(GuestPricingComponent, {
        panelClass: 'onboard-dialog-guestFlow',
        data: { products: this.products },
      });
    } else {
      dialogRef = this.dialog.open(FreeTrialComponent, {
        panelClass: 'onboard-dialog-guestFlow',
        data: this.products,
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  nextPage(option) {
    if (option === 'blue') {
      this.next.emit({
        next_step: 'guest-pricing',
      });
    } else if (option === 'plus') {
      this.next.emit({
        next_step: 'free-trial',
      });
    } else {
      this.next.emit();
    }
  }

  laterPage() {
    this.later.emit();
  }
}
