import 'zone.js';
// import './polyfills.ts';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

import { environment } from './environments/environment';

// import { defineCustomElements } from '@ionic/pwa-elements/loader';

import 'hammerjs';
// import '@lottiefiles/lottie-player';
// import 'animate.css';

if (environment.deployed) {
  enableProdMode();
}

if (environment.domain !== 'dev') {
  window.console.log = () => {};
}

// window.scrollTo({
//   top: 100,
//   left: 100,
//   behavior: 'smooth'
// });

document.fonts.ready.then(() => {
  document.body.classList.add('material-icons-loaded');
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.log(err));

// Call the element loader after the platform has been bootstrapped
// defineCustomElements(window);
