import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.scss'],
    standalone: true,
    imports: [MatButton],
})
export class StartComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  constructor() {}

  ngOnInit() {}

  nextPage() {
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }
}
