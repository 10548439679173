import { Component, OnInit } from '@angular/core';
import { PromoService } from '../services/promo.service';
import { catchError, of } from 'rxjs';
import { JWTTokenService } from '../services/jwttoken.service';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatList, MatListItem } from '@angular/material/list';
import { FeaturesTableComponent } from '../account/features-table/features-table.component';
import { SpotlightFooterComponent } from '../pages/bio-information/spotlight-footer/spotlight-footer.component';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatCard } from '@angular/material/card';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-redeem-code',
    templateUrl: './redeem-code.component.html',
    styleUrls: ['./redeem-code.component.scss'],
    standalone: true,
    imports: [
    MatCard,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    MatButton,
    SpotlightFooterComponent,
    FeaturesTableComponent,
    MatList,
    MatListItem,
    FaIconComponent,
    DecimalPipe
],
})
export class RedeemCodeComponent implements OnInit {
  isLoading = false;
  isAuthenticated = false;

  code = new FormControl('', [Validators.required]);

  codeBody;
  redeemBody;

  constructor(
    private promoService: PromoService,
    private jwt: JWTTokenService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private auth: AuthService,
    private storage: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isAuthenticated = this.jwt.isAuthenticatedUser();

    const urlCode = this.route.snapshot.queryParamMap.get('code');
    if (urlCode) {
      this.code.setValue(urlCode);
      this.checkCode();
    }
  }

  processCodeBody(body) {
    console.log(body);
    // Process Product
    if (body.code_type === 'checkout' || body.code_type === 'membership') {
      const product = body.code_data.product;

      // Features
      const allFeatures = [];
      for (const enabledFeature of product.enabled_features) {
        enabledFeature.hasAccess = true;
        allFeatures.push(enabledFeature);
      }
      for (const disabledFeature of product.disabled_features) {
        disabledFeature.hasAccess = false;
        allFeatures.push(disabledFeature);
      }
      product.all_features = allFeatures;

      // Showcase Items
      product.showcase_items = product.showcase_items.map((i) => ({
        ...i,
        hasAccess: true,
        enabled: true,
      }));

      // Default Price
      const defaultPrice = product?.prices?.filter(
        (price) => price.is_default
      )[0];
      if (defaultPrice.type === 'recurring') {
        defaultPrice.recurring_text = `${
          defaultPrice?.recurring.interval_count > 1
            ? defaultPrice?.recurring.interval_count + ' '
            : ''
        }${
          defaultPrice?.recurring.interval_count > 1
            ? defaultPrice?.recurring.interval + 's'
            : defaultPrice?.recurring.interval
        }`;
      }
      body.code_data.product.default_price = defaultPrice;

      body.code_data.product = product;
    }

    // Process Discounts
    if (body.code_type === 'checkout' && body.code_data.price) {
      if (body.code_data.price.type === 'recurring') {
        body.code_data.price.recurring_text = `${
          body.code_data.price?.recurring.interval_count > 1
            ? body.code_data.price?.recurring.interval_count + ' '
            : ''
        }${
          body.code_data.price?.recurring.interval_count > 1
            ? body.code_data.price?.recurring.interval + 's'
            : body.code_data.price?.recurring.interval
        }`;
      }
      if (body.code_data.coupon) {
        const originalPrice = body.code_data.price.unit_amount;
        const amountOff = body.code_data.coupon.amount_off
          ? body.code_data.coupon.amount_off
          : 0;
        const percentOff = body.code_data.coupon.percent_off
          ? body.code_data.coupon.percent_off
          : 0;

        body.code_data.price.new_total =
          originalPrice - amountOff - originalPrice * (percentOff / 100);
      } else {
        body.code_data.price.new_total = body.code_data.price.unit_amount;
      }
    }

    // Discount
    if (body.code_data.coupon) {
      const amountOff = body.code_data.coupon.amount_off
        ? body.code_data.coupon.amount_off
        : 0;
      const percentOff = body.code_data.coupon.percent_off
        ? body.code_data.coupon.percent_off
        : 0;
      const couponDuration = body.code_data.coupon.duration;
      const couponMonthDuration = body.code_data.coupon.duration_in_months;

      // Get months in interval
      let numDiscountedPayments = 0;
      if (body.code_data.price.recurring) {
        const intervalMonths =
          body.code_data.price?.recurring.interval === 'year'
            ? body.code_data.price?.recurring.interval_count * 12
            : body.code_data.price?.recurring.interval_count;
        numDiscountedPayments = Math.ceil(couponMonthDuration / intervalMonths);
      }

      const discountText = `${amountOff ? '$' + amountOff / 100 : ''}${
        percentOff ? percentOff + '%' : ''
      }`;

      const onceText = `${
        body.code_data.price.type === 'recurring'
          ? 'the first ' + body.code_data.price.recurring_text
          : ''
      }`;
      const foreverText = `${
        body.code_data.price.type === 'recurring'
          ? 'every ' + body.code_data.price.recurring_text
          : ''
      }`;
      const repeatingText = `${
        body.code_data.price.type === 'recurring'
          ? 'for the first ' + numDiscountedPayments + ' payments'
          : ''
      }`;

      const durationText =
        couponDuration === 'once'
          ? onceText
          : couponDuration === 'forever'
          ? foreverText
          : couponDuration === 'repeating'
          ? repeatingText
          : '';

      body.code_data.coupon.text = `${discountText} off ${durationText}`;
    }

    return body;
  }

  checkCode() {
    this.isLoading = true;

    this.promoService.checkCode(this.code.value).subscribe((data) => {
      console.log(data);
      if (data.count === 0) {
        this.code.setErrors({
          invalid: true,
        });
        this.code.markAsTouched();
      } else {
        const codeBody = this.processCodeBody(data.results[0]);

        // Adding logic to have product field on parent level to work with old logic
        this.codeBody = {
          ...codeBody,
          product: codeBody?.code_data?.product,
          username: codeBody?.code_data?.username,
          is_wildcard: codeBody?.code_data?.is_wildcard,
          stripe_price_id: codeBody?.code_data?.stripe_price_id,
          title: codeBody?.code_data?.title,
          subtitle: codeBody?.code_data?.subtitle,
          theme: codeBody?.code_data?.theme,
          grant_ambassador_status: codeBody?.code_data?.grant_ambassador_status,
          agency_id: codeBody?.code_data?.agency_id,
          agency_name: codeBody?.code_data?.agency_name,
          price: codeBody?.code_data?.price,
          coupon: codeBody?.code_data?.coupon,
          image_url: codeBody?.code_data?.image_url,
        };
        console.log(this.codeBody);
        this.snackbar.open('Special Offer Code Accepted!', 'OK', {
          duration: 2000,
        });
      }
      this.isLoading = false;
    });
  }

  redeemCode() {
    this.isLoading = true;

    let body = {};
    if (this.codeBody.code_type === 'membership') {
      body = {
        code: this.code.value,
        membership: this.codeBody.code_data.product.identifier,
      };
    } else {
      body = { code: this.code.value };
    }

    if (this.isAuthenticated) {
      this.promoService
        .redeemCode(body)
        .pipe(catchError((err, caught) => of({ error: true })))
        .subscribe((data) => {
          this.isLoading = false;
          if (data && !data.error) {
            this.redeemBody = data;

            if (data.redirect_url) {
              window.open(data.redirect_url, '_self');
            } else if (
              data.message &&
              data.message === 'Membership code successfully redeemed.'
            ) {
              this.snackbar.open('Membership code redeemed!', 'OK', {
                duration: 2000,
              });
              this.router.navigateByUrl('/account/membership');
            } else if (
              data.message &&
              data.message === 'Spotlight footer successfully added.'
            ) {
              this.snackbar.open('Spotlight code redeemed!', 'OK', {
                duration: 2000,
              });
              this.router.navigateByUrl('/profiles');
            } else if (
              data.message &&
              data.message === 'Agency code successfully redeemed.'
            ) {
              this.snackbar.open(
                `Partnered with ${this.codeBody.code_data.agency_name}!`,
                'OK',
                {
                  duration: 2000,
                }
              );
              this.router.navigateByUrl('/profiles');
            }

            console.log(this.redeemBody);
          } else {
            this.code.setErrors({
              invalid: true,
            });
          }
        });
    } else {
      //Add logic to start creator onboard if they redeem creator code
      if (this.codeBody?.product?.type === 'creator') {
        localStorage.setItem('boardMode', 'creator');
      }
      this.storage.set(
        'signinRedirect',
        `/redeem/special-offer?code=${this.code.value}`
      );
      this.auth.navigateToAuthPage();
    }
  }

  goBack() {
    this.codeBody = null;
    this.redeemBody = null;
    this.code.patchValue('');
  }
}
