import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { api } from '../../config/apiUrls';
import { UtilitiesService } from './utilities.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  constructor(
    private http: HttpClient,
    private utilities: UtilitiesService
  ) {}

  async listDomains( options? ) {
    // Load profile if needed
    if (this.utilities.svDomains.length === 0) {
      const domainResponse = await firstValueFrom(
        this.http.get<any>(`${api.domains}?enabled=true`)
      );

      this.utilities.svDomains = domainResponse.results;
    }

    return this.utilities.svDomains;
  }

  retrieveDomain(id) {
    return this.http.get<any>(`${api.domains}/${id}`)
  }

  retrieveDefaultDomain() {
    return this.http.get<any>(`${api.domains}?default=true`)
  }
}
