import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as confetti from 'canvas-confetti';

@Component({
    selector: 'app-guest-created',
    templateUrl: './guest-created.component.html',
    styleUrls: ['./guest-created.component.scss'],
    standalone: true,
})
export class GuestCreatedComponent implements OnInit, AfterViewInit {
  @Output() next = new EventEmitter();
  @Output() later = new EventEmitter();

  @ViewChild('conf', { static: true }) conf: ElementRef;

  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {
    // const myCanvas = document.createElement('canvas');
    // this.myDiv.nativeElement.appendChild(myCanvas);

    const myConfetti = confetti.create(this.conf.nativeElement, {
      resize: true,
      useWorker: true,
    });
    myConfetti({
      particleCount: 300,
      spread: 160,
      // any other options from the global
      // confetti function
    });
  }

  nextPage() {
    this.next.emit();
  }
  laterPage() {
    // saving may be later status to local storage in order to show it during the username page
    localStorage.setItem('maybeLater', 'true');

    this.later.emit();
  }
}
