/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';

import { api } from '../../../config/apiUrls';
import { StripeService } from 'src/app/services/stripe.service';
import { firstValueFrom } from 'rxjs';
import { OnboardPage } from '../onboard.page';
import { MatDialog } from '@angular/material/dialog';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { UsernameComponent } from '../username/username.component';

@Component({
    selector: 'app-guest-username',
    templateUrl: './guest-username.component.html',
    styleUrls: ['./guest-username.component.scss'],
    standalone: true,
    imports: [
    UsernameComponent,
    SlickCarouselModule
],
})
export class GuestUsernameComponent implements OnInit {
  @Output() next = new EventEmitter();

  @ViewChild('slickModal') slickModal;

  showSlide = true; //false;
  isLastSlide = false;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: false,
  };

  constructor(
    private products: ProductsService,
    private stripe: StripeService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  public onboardPrompt(step: string, mode: string): Promise<boolean> {
    return firstValueFrom(
      this.dialog
        .open(OnboardPage, {
          autoFocus: step === 'text-verification' ? false : true,
          disableClose: true,
          panelClass: `onboard-${mode ? 'dialog-' + mode : 'dialog'}`,
          data: {
            nextStep: step,
            mode,
          },
        })
        .afterClosed()
    );
  }

  nextPage() {
    const later = Boolean(localStorage.getItem('maybeLater'));
    console.log(later);
    if (later) {
      this.onboardPrompt('guest-more', 'guestFlow');
      this.next.emit();
    } else {
      this.next.emit();
    }
  }

  afterChange(e) {
    console.log('afterChange');
    // console.log(e);

    this.isLastSlide = e.last;
    if (this.isLastSlide) {
      // this.slideConfig.autoplay = false;
      this.slickModal.slickPause();
    }
  }

  activatePlan(priceId, productId, mode, planName, planCost) {
    const body = {
      items: [{ price: priceId, quantity: 1 }],
      mode,
      product_id: productId,
      success_url: `${api.stripeCallback}/payment/confirmation?status=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${api.stripeCallback}/payment/confirmation?status=failed`,
    };

    this.stripe.purchase(body).subscribe((data) => {
      console.log(data);
      window.open(data.redirect_url, '_self');
    });

    console.log(body);
  }

  upgrade() {
    this.products.listProducts().subscribe((data) => {
      console.log(data);
      const plus = data.results.filter((item) => item.identifier === 'plus')[0];
      const price = plus.prices.find(
        (item) => item.lookup_key === 'plus_semi_annual_guest'
      );
      console.log(price);
      this.activatePlan(
        price.id,
        plus._id,
        price.metadata.checkout_mode,
        plus.title,
        price.unit_amount
      );
    });
  }
}
