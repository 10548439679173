// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-preview {
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  white-space: normal;
  border-radius: 50px;
  width: 100%;
  font-size: 16px;
}
.link-preview.loading {
  background-color: var(--mat-theme-inactive);
  color: black;
}
.link-preview.available {
  background-color: var(--mat-theme-success);
  color: white;
}
.link-preview.plus {
  background-color: var(--accent);
  color: white;
}
.link-preview.error {
  background-color: var(--mat-theme-danger);
  color: white;
}

.full-link-message {
  margin-left: 25px;
  margin-top: -5px;
}

.alt-name {
  cursor: pointer;
  background-color: var(--mat-theme-app-bar);
  padding: 5px;
  border-radius: 5px;
}

.domain {
  white-space: nowrap;
  overflow: hidden;
  /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
  direction: rtl;
  text-align: left;
}

.info-container {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
