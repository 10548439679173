import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlternativeVerificationService } from 'src/app/services/alternative-verification.service';
import { ExternalService } from 'src/app/services/external.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { MatButton } from '@angular/material/button';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatRipple } from '@angular/material/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { JwplayerVideoComponent } from '../jwplayer-video/jwplayer-video.component';

const SOCIAL_DM_LINKS = {
  instagram: (code) => 'https://ig.me/m/socialvalid',
  facebook: (code) => 'https://m.me/61551761733946',  // NOTE: need a username instead of ID
  twitter: (code) => `https://twitter.com/messages/compose?recipient_id=1402725786969276422&text=${code}`,
  linkedin: (code) => 'https://www.linkedin.com/company/social-valid/',
  tiktok: (code) => 'https://www.tiktok.com/messages?u=7218408373547877422'
};

const SOCIAL_DESCRIPTIONS = {
  instagram: `
    Sign in to your Instagram account and message us the
    code below to get a verified Instagram link added to
    your profile.
  `,
  facebook: `
    Sign in to your Facebook account and message us the
    code below to get a verified Facebook link added to
    your profile.
  `,
  twitter: `
    Sign in to your Twitter account and message us the
    code below to get a verified Twitter link added to
    your profile.
  `,
  linkedin: `
    Sign in to your LinkedIn account and message us the
    code below to get a verified LinkedIn link added to
    your profile.
  `,
  tiktok: `
    Sign in to your TikTok account and message us the
    code below to get a verified TikTok link added to
    your profile.
  `
};

const SOCIAL_VIDEO_LINKS = {
  instagram: null,
  facebook: null,
  twitter: null,
  linkedin: `${environment.host}/static/videos/demos/linkedin_dm_verification.mov`,
  tiktok: null
};

@Component({
    selector: 'app-dm-verification',
    templateUrl: './dm-verification.component.html',
    styleUrls: ['./dm-verification.component.scss'],
    standalone: true,
    imports: [
    JwplayerVideoComponent,
    MatProgressSpinner,
    MatRipple,
    FaIconComponent,
    MatButton
],
})
export class DmVerificationComponent implements OnInit {
  code;
  socialType;
  loading = true;
  copied = false;
  video;

  socialTitles = {
    instagram: 'Instagram',
    facebook: 'Facebook',
    twitter: 'Twitter',
    linkedin: 'LinkedIn',
    tiktok: 'TikTok'
  }

  constructor(
    public dialogRef: MatDialogRef<DmVerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private verificationService: AlternativeVerificationService,
    private external: ExternalService,
    private utilities: UtilitiesService
  ) { }

  get description() {
    return SOCIAL_DESCRIPTIONS[this.socialType]
  }

  ngOnInit() {
    this.socialType = this.data.socialType;

    if (SOCIAL_VIDEO_LINKS[this.socialType]) {
      this.video = {
        _id: this.utilities.getUniqueId(),
        url: SOCIAL_VIDEO_LINKS[this.socialType],
        options: {
          aspect_ratio: '16:9'
        }
      }
    }

    this.verificationService.getSocialVerificationCode(
      this.data.pageId,
      this.utilities.profileId
    ).subscribe((result) => {
      this.code = result.code;
      this.loading = false;
    });
  }

  navigate() {
    this.external.openNewTab(SOCIAL_DM_LINKS[this.socialType](this.code), '_blank');
    this.dialogRef.close();
  }

  back() {
    this.dialogRef.close();
  }

  copy() {
    navigator.clipboard.writeText(`social-valid-${this.socialType}-${this.code}`);
    this.copied = true;
  }
}
