import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileUpdateService {
  private profilePictureSource = new BehaviorSubject<string>(null);
  profilePicture$ = this.profilePictureSource.asObservable();

  updateProfilePicture(newPictureUrl: string) {
    this.profilePictureSource.next(newPictureUrl);
  }
}
