// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

tr.detail-row {
  height: 0;
}

tr.element-row:not(.expanded-row):hover {
  background: var(--mat-theme-background);
}

tr.element-row:not(.expanded-row):active {
  background: var(--mat-theme-app-bar);
}

.element-row td {
  border-bottom-width: 0;
}

.element-detail {
  overflow: hidden;
  display: flex;
}

.element-description {
  padding: 16px;
}

.element-description-attribution {
  opacity: 0.5;
}

.mat-row .mat-cell {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  cursor: pointer;
}

.feature-table {
  margin-bottom: 10px;
  transition: max-height 1s ease-in;
}

.limited-height {
  max-height: 500px;
  overflow-y: hidden;
  transition: max-height 1s ease-in;
}

.expanded-height {
  max-height: 999px;
  overflow-y: hidden;
  transition: max-height 1s ease-in;
}

button {
  border-radius: 0px !important;
}

.coming-soon {
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
