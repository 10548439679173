import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { AccountService } from 'src/app/services/account.service';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { NgClass } from '@angular/common';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
    selector: 'app-guest-dob',
    templateUrl: './guest-dob.component.html',
    styleUrls: ['./guest-dob.component.scss'],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MatCalendar,
    NgClass
],
})
export class GuestDobComponent implements OnInit {
  @Output() next = new EventEmitter();
  selected: Date | null;
  isBelow13 = false;

  dateFormControl: FormControl;

  constructor(
    private jwt: JWTTokenService,
    private account: AccountService,
    private profiles: ProfilesService,
    private utilities: UtilitiesService
  ) {
    this.dateFormControl = new FormControl('', Validators.required);
  }

  ngOnInit() {
    // console.log(data);
    // if (data) {
    //   const age = moment().diff(data, 'years');
    //   console.log(age);
    //   if (age < 13) {
    //     const control = new FormControl(false, Validators.requiredTrue);
    //     this.accountInfoForm.addControl('childCheck', control);
    //     this.is18Above = false;
    //     this.is13Below = true;
    //   } else if (age < 18) {
    //     const control = new FormControl(false, Validators.requiredTrue);
    //     this.accountInfoForm.addControl('childCheck', control);
    //     this.is18Above = false;
    //   } else {
    //     this.accountInfoForm.removeControl('childCheck');
    //     this.is18Above = true;
    //     this.is13Below = false;
    //   }
    //   console.log(this.is18Above);
    //   console.log(this.is13Below);
    // }

    this.dateFormControl.valueChanges.subscribe((data) => {
      console.log(data);
      this.isBelow13 = moment().diff(data, 'years') < 13;
      console.log(this.isBelow13);
      this.selected = moment(data, 'YYYY-MM-DD').toDate();
      console.log(this.selected);
    });
  }

  dateChange(event) {
    console.log(event);

    this.dateFormControl.setValue(moment(this.selected).format('YYYY-MM-DD'));

    // this.isBelow13 = moment().diff(this.selected, 'years') < 13;
    // console.log(this.isBelow13);

    // if (event.timeStamp) {
    //   this.selected = new Date(event.timeStamp);
    // }
    // console.log(this.selected);
  }

  saveChanges() {
    console.log(this.selected);
    const dateOfBirth = this.selected;

    // const {
    //   firstName,
    //   middleName,
    //   lastName,
    //   email,
    //   zipCode,
    //   timezone,
    //   preferredPronouns,
    //   dateOfBirth,
    // } = this.accountInfoForm.value;

    const userId = this.jwt.getSub();

    const bioProfileId = this.utilities.profileId;

    // console.log(userId, bioProfileId);

    // const accountBody = {
    //   first_name: firstName,
    //   middle_name: middleName,
    //   last_name: lastName,
    //   email,
    //   zip_code: zipCode,
    //   settings: { tz: timezone },
    // };

    const profileBody = {
      // first_name: firstName,
      // middle_name: middleName,
      // last_name: lastName,
      // email,
      // zip_code: zipCode,
      // settings: { tz: timezone },
      // pronouns: preferredPronouns,
      dob: new Date(dateOfBirth).toISOString().slice(0, 10),
    };

    console.log(userId, { profileBody });

    // //update account
    // this.account.updateAccount(userId, accountBody).subscribe((data: any) => {
    //   console.log(data);
    //   this.utilities.accountDetails = data;
    // });

    //update profile
    this.profiles
      .updateProfile(bioProfileId, profileBody)
      .subscribe((data: any) => {
        console.log(data);
        this.utilities.profileDetails = data;
      });

    // this.snackBar.open('Updated account information', 'OK', {
    //   duration: 5000,
    // });

    // this.router.navigateByUrl('/profiles');
    this.nextPage();
  }

  nextPage() {
    this.next.emit();
  }
}
