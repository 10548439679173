import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { JWTTokenService } from '../services/jwttoken.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { DomPortalOutlet } from '@angular/cdk/portal';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfluencerApplicationComponent } from './influencer/influencer-application/influencer-application.component';
import { InfluencerRequirementsComponent } from './influencer/influencer-requirements/influencer-requirements.component';
import { IdentityComponent } from './identity/identity.component';
import { VerifySocialMediaComponent } from './social-media/verify-social-media/verify-social-media.component';
import { ChooseSocialMediaComponent } from './social-media/choose-social-media/choose-social-media.component';


const badgeModes = {
  standard: {
    color: 'primary',
  },
  passport: {
    color: 'passport',
  },
};

@Component({
    selector: 'app-badges',
    templateUrl: './badges.component.html',
    styleUrls: ['./badges.component.scss'],
    standalone: true,
    imports: [
    ChooseSocialMediaComponent,
    VerifySocialMediaComponent,
    IdentityComponent,
    InfluencerRequirementsComponent,
    InfluencerApplicationComponent
],
})
export class BadgesComponent implements OnInit {
  badgeMode = 'normal';
  color = 'primary';
  currentPage = 'choose-social-media';
  oauthData;
  creatorData;

  constructor(
    public dialogRef: MatDialogRef<BadgesComponent>,
    // private account: AccountService,
    // private jwt: JWTTokenService,
    // private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.badgeMode = this.data.mode;
    this.currentPage = this.data.page;
    this.oauthData = this.data.oauth;
    this.color = badgeModes[this.badgeMode]['color'];
  }

  closeDialog() {
    this.dialogRef.close();
  }

  creatorSubmit(page, data) {
    this.creatorData = data;
    this.changePage(page);
  }

  changePage(page) {
    this.currentPage = page;
  }
}
