import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { api } from '../../config/apiUrls';

import { environment } from '../../environments/environment';
import { JWTTokenService } from './jwttoken.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private jwt: JWTTokenService
  ) {}
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): boolean | Promise<boolean> {
  //   // var isAuthenticated = this.authService.getAuthStatus();
  //   // if (!isAuthenticated) {
  //   //     this.router.navigate(['/login']);
  //   // }
  //   // return isAuthenticated;
  // }

  signIn(id = null) {
    return this.http.get<any>(api.signIn);
  }

  signInProvider(provider = 'cognito') {
    const headers = environment.deployed
      ? { 'X-SIGNIN-MODE': 'prod' }
      : { 'X-SIGNIN-MODE': 'local' };

    let httpParams = new HttpParams().set('cognito_mode', 'signin');

    // Instead of having local storage variable persist in local storage until after sign in,
    // Now the data is passed into the sign in route directly and gets handled there in the
    // cognito callback automatically.
    const boardMode = localStorage.getItem('boardMode');
    if (boardMode) {
      httpParams = httpParams.set('onboarding_mode', boardMode);
      localStorage.removeItem('boardMode');
    }

    const referralId = localStorage.getItem('referralId');
    if (referralId) {
      httpParams = httpParams.set('referral_id', referralId);
      localStorage.removeItem('referralId');
    }

    const referralPageId = localStorage.getItem('referralPageId');
    if (referralPageId) {
      httpParams = httpParams.set('referral_page_id', referralPageId);
      localStorage.removeItem('referralPageId');
    }

    const preferredPage = localStorage.getItem('preferredPage');
    if (preferredPage) {
      httpParams = httpParams.set('preferred_page', preferredPage);
      localStorage.removeItem('preferredPage');
    }

    const promoCode = localStorage.getItem('promoCode');
    if (promoCode) {
      httpParams = httpParams.set('promo_code', promoCode);
      localStorage.removeItem('promoCode');
    }

    console.log(httpParams);
    return this.http.get<any>(`${api.signIn}/${provider}`, {
      headers,
      params: httpParams,
    });
  }

  signUp(provider = 'cognito') {
    const headers = environment.deployed
      ? { 'X-SIGNIN-MODE': 'prod' }
      : { 'X-SIGNIN-MODE': 'local' };

    let httpParams = new HttpParams().set('cognito_mode', 'signup');

    // Instead of having local storage variable persist in local storage until after sign in,
    // Now the data is passed into the sign in route directly and gets handled there in the
    // cognito callback automatically.
    const boardMode = localStorage.getItem('boardMode');
    if (boardMode) {
      httpParams = httpParams.set('onboarding_mode', boardMode);
      localStorage.removeItem('boardMode');
    }

    const referralId = localStorage.getItem('referralId');
    if (referralId) {
      httpParams = httpParams.set('referral_id', referralId);
      localStorage.removeItem('referralId');
    }

    const referralPageId = localStorage.getItem('referralPageId');
    if (referralPageId) {
      httpParams = httpParams.set('referral_page_id', referralPageId);
      localStorage.removeItem('referralPageId');
    }

    const preferredPage = localStorage.getItem('preferredPage');
    if (preferredPage) {
      httpParams = httpParams.set('preferred_page', preferredPage);
      localStorage.removeItem('preferredPage');
    }

    const promoCode = localStorage.getItem('promoCode');
    if (promoCode) {
      httpParams = httpParams.set('promo_code', promoCode);
      localStorage.removeItem('promoCode');
    }

    console.log(httpParams);
    return this.http.get<any>(`${api.signIn}/${provider}`, {
      headers,
      params: httpParams,
    });
  }

  initiateJwt(token = null) {
    token = token || localStorage.getItem('token');
    this.jwt.setToken(token);
    this.jwt.decodeToken();
    // console.log(this.jwt.isTokenExpired());
  }

  signOut() {
    //call signout and remove token from local storage
    this.http.get<any>(api.signOut).subscribe((data) => {
      console.log(data);
      localStorage.removeItem('token');
      localStorage.removeItem('limitToSelectedProfile');
      localStorage.removeItem('selectedProfileId');
      localStorage.removeItem('personaLockoutTimer');
      // this.router.navigate(['/']);
      this.router.navigate(['/sign-in']);
    });
  }

  signInWithProvider() {
    const providerMode = localStorage.getItem('providerMode') || 'signin';
    localStorage.removeItem('providerMode');

    // Remove any remnants of
    localStorage.removeItem('selectedProfileId');
    localStorage.removeItem('limitToSelectedProfile');

    let params = new HttpParams();
    params = params.append('cognito_mode', providerMode);

    const headers = environment.deployed
      ? { 'X-SIGNIN-MODE': 'prod' }
      : { 'X-SIGNIN-MODE': 'local' };

    console.log(headers);
    return this.http.get<any>(api.signInCognito, {
      headers,
      params,
    });
  }

  oAuthCallback() {
    return this.http.get<any>(api.oAuthCallback);
  }

  navigateToAuthPage(signup = true) {
    this.router.navigateByUrl(signup ? '/sign-up' : '/sign-in');
  }

  signInWithEmail(email: string, provider: string = 'email') {
    const token = localStorage.getItem('token');
    const authHeader = token ? `Bearer ${token}` : null;

    const headers = new HttpHeaders({
      'X-SIGNIN-MODE': environment.deployed ? 'prod' : 'local',
      ...(authHeader ? { Authorization: authHeader } : {}),
    });

    let httpParams = new HttpParams().set('email', email);

    return this.http.get<any>(`${api.signIn}/${provider}`, {
      headers,
      params: httpParams,
    });
  }
}
