import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { ReferralService } from 'src/app/services/referral.service';
import * as confetti from 'canvas-confetti';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-guest-complete',
    templateUrl: './guest-complete.component.html',
    styleUrls: ['./guest-complete.component.scss'],
    standalone: true,
    imports: [MatButton],
})
export class GuestCompleteComponent implements OnInit, AfterViewInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;

  constructor(
    private account: AccountService,
    private referrals: ReferralService,
    private jwt: JWTTokenService,
    private utilities: UtilitiesService,
  ) {}

  ngOnInit() {
    // this.surprise();
  }

  ngAfterViewInit() {
    const confettiCanvas = document.createElement('canvas');
    confettiCanvas.id = 'confetti-canvas';
    confettiCanvas.width = window.innerWidth;
    confettiCanvas.height = window.innerHeight;
    confettiCanvas.style.position = 'absolute';
    confettiCanvas.style.top = '0';
    confettiCanvas.style.left = '0';
    this.myDiv.nativeElement.style.backgroundImage = `url(${confettiCanvas.toDataURL()})`;
    this.myDiv.nativeElement.style.backgroundSize = 'cover';
    document.body.appendChild(confettiCanvas);
    this.myDiv.nativeElement.style.backgroundColor = 'red';

    confetti({
      particleCount: 100,
      spread: 200,
      origin: { x: 0, y: 0 },
    });
  }
  // public surprise(): void {
  //   console.log('Confetti!');
  //   const canvas = this.renderer2.createElement('canvas');

  //   canvas.className = 'confetti';

  //   this.renderer2.appendChild(this.elementRef.nativeElement, canvas);

  //   const myConfetti = confetti.create(canvas, {
  //     resize: true, // will fit all screen sizes
  //     origin: {
  //       y: 1,
  //       x: 0.5,
  //     },
  //   });

  //   myConfetti({
  //     particleCount: 100,
  //   });

  //   // this.clicked = true;
  // }

  nextPage() {
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  finishOnboarding() {
    const userId = this.jwt.getSub();

    // Add log for referral if it exists in account
    // const referral_id = this.utilities.accountDetails?.referred_by_data?.referral_id
    // if (referral_id) {
    //   this.referrals
    //     .createReferralLog(referral_id, {
    //       object_type: 'referral',
    //       event: 'complete',
    //       action: 'analytics',
    //     });
    // }

    // Mark onboarding as completed
    const accountBody = {
      onboarding_completed: true,
      onboarding_next_step: '',
    };

    this.account.updateAccount(userId, accountBody, {
      minimized_fields: 'all',
    });

    this.closeDialog();
  }
}
