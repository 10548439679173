// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing {
  color: rgb(69, 141, 236);
}

.price-btn {
  cursor: pointer;
  min-width: 80px;
  border-radius: 10px;
  background-color: var(--primary-light);
}

.price-btn:hover {
  filter: brightness(95%);
}

.info-link-text {
  font-weight: 700;
  text-decoration: underline;
}

.product-price {
  color: var(--mat-theme-text);
  font-size: 24pt;
  line-height: 28pt;
  font-weight: 300;
}

.currency-sign {
  color: var(--mat-theme-text);
  font-size: 9pt;
}

.recurring-text {
  color: var(--mat-theme-text);
  font-size: 8pt;
}

.trial-price-text {
  color: var(--mat-theme-text);
  font-size: 8pt;
}

.trial-text {
  color: var(--mat-theme-text);
  font-size: 7pt;
}

.default-price-text {
  color: var(--mat-theme-text);
  font-size: 7pt;
}

.product-title {
  color: var(--mat-theme-text);
  font-size: 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
