// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-button-checked {
  background-color: #ff4081;
  color: white;
  font-weight: 500;
  border-color: #3f51b5 !important;
}

.toggle-button {
  border-radius: 20px;
  border-color: #3f51b5;
  border-width: 2px;
}

.checkbox-container {
  border-radius: 40px;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
  text-align: left;
}

::ng-deep .mat-checkbox-layout {
  white-space: normal !important;
}

::ng-deep .mat-checkbox-inner-container {
  margin-top: 3px !important;
}

.checked {
  background-color: var(--primary);
  color: var(--mat-theme-app-bar);
}

.unchecked {
  background-color: var(--mat-theme-app-bar);
  color: var(--primary);
}

.toggle-box {
  padding: 20px;
  border-radius: 50px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
