/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Component, Inject, OnInit } from '@angular/core';

import { api } from '../../../config/apiUrls';
import { StripeService } from 'src/app/services/stripe.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-guest-pricing-dialog',
    templateUrl: './guest-pricing-dialog.component.html',
    styleUrls: ['./guest-pricing-dialog.component.scss'],
    standalone: true,
})
export class GuestPricingDialogComponent implements OnInit {
  product;
  constructor(
    public dialogRef: MatDialogRef<GuestPricingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stripe: StripeService,
    private router: Router
  ) {}

  ngOnInit() {
    console.log('DATA');
    console.log(this.data);
    this.product = this.data.product;
  }

  activatePlan() {
    console.log('activatePlan');
    // const currentPlan = plan === 'monthly' ? this.monthlyPlan : this.annualPlan;
    // if (plan === 'monthly') {
    //   console.log(this.monthlyPlan);
    // } else {
    //   console.log(this.annualPlan);
    // }
    const currentPlan = this.data.annualPlan;
    const { id: priceId, metadata, unit_amount: planCost } = currentPlan;
    console.log(currentPlan);
    const planName = this.product.title;
    const body = {
      items: [{ price: priceId, quantity: 1 }],
      mode: metadata.checkout_mode,
      product_id: this.product._id,
      success_url: `${api.stripeCallback}/payment/confirmation?status=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${api.stripeCallback}/payment/confirmation?status=failed`,
    };
    console.log(body);

    this.stripe.purchase(body).subscribe((data) => {
      console.log(data);
      // localStorage.setItem('signinRedirect', this.router.url);

      this.dialogRef.close({ url: data.redirect_url });

      // this.next.emit({
      //   external_step: data.redirect_url,
      //   wait_for_update: true,
      // });
    });
  }
}
