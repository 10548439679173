// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-lock-clicker {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  cursor: pointer;
  z-index: 9999;
  top: 0;
  right: 0;
  pointer-events: all !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
