import {
  Input,
  OnInit,
  HostListener,
  EventEmitter,
  Output,
  Component,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { timer } from 'rxjs';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage, ImageCropperModule } from 'ngx-image-cropper';
import { ConnectionPositionPair, GlobalPositionStrategy, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Photo, PhotoService } from 'src/app/services/photo.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-image-upload-wrapper',
    templateUrl: './image-upload-wrapper.component.html',
    styleUrls: ['./image-upload-wrapper.component.scss'],
    standalone: true,
    imports: [
        CdkConnectedOverlay,
        ImageCropperModule,
        MatButton,
        FaIconComponent,
    ],
})
export class ImageUploadWrapperComponent implements OnInit {
  @Input() startingImageUrl: string;
  @Input() allowPhoto: boolean = true;
  @Input() allowGallery: boolean = true;
  @Input() crop: boolean = true;
  @Input() lockCropAspectRatio: boolean = true;
  @Input() cropAspectRatio: number = 1;
  @Input() compressImage: boolean = true;

  @Output() imageUploaded: EventEmitter<any> = new EventEmitter();
  // @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 20,
      originX: 'center',
      originY: 'center',
      overlayX: 'center',
      overlayY: 'center',
      panelClass: null,
    },
  ];

  overlayPositions: GlobalPositionStrategy = new GlobalPositionStrategy()
  .centerHorizontally()
  .centerVertically();

  // imageMaxWidth = 1024;
  // imageMaxHeight = 1024;

  uniqueId;
  isOpen = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  file;
  isImageLoading: boolean = false;

  constructor(
    private utilitiesService: UtilitiesService,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    private photoService: PhotoService,
    private sanitizer: DomSanitizer,
    private imageCompress: NgxImageCompressService
  ) {
    this.uniqueId = this.utilitiesService.getUniqueId();

    //subscribe to photos
    this.photoService
      .getPhoto(this.uniqueId) // Only want to handle photo uploads originating from this component.
      .subscribe((data) => {
        const { photo, id } = data;
        if (photo && id === this.uniqueId) {
          this.fileChangeEvent(photo);
        }
      });
  }

  ngOnInit() {}

  async openImageSelectionSheet() {
    if (this.startingImageUrl) {
      // If there's already an image passed in to the wrapper, skip image
      // selection and fetch the specified image instead.

      const blob = await (await fetch(this.startingImageUrl)).blob();
      const fileName = this.startingImageUrl.split('/').pop();
      const ext = fileName.split('.').pop();

      const file = new File([blob], fileName, { type: 'image/' + ext });

      this.fileChangeEvent({ file, webviewPath: '' });
    } else {
      timer(0).subscribe((time) => {
        const bottomSheetRef = this.bottomSheet.open(ProfilePictureComponent, {
          data: {
            uniqueId: this.uniqueId,
            allowPhoto: this.allowPhoto,
            allowGallery: this.allowGallery,
          },
        });

        // // Set a high z-index for the bottom sheet
        // const container =
        //   bottomSheetRef.containerInstance['_elementRef'].nativeElement;
        // container.style.zIndex = '9999 !important'; // Set a value higher than other elements

        bottomSheetRef.afterDismissed().subscribe(() => {
          console.log('Bottom sheet has been dismissed.');
        });
      });
    }
  }

  async getImageBase64(url) {
    const res = await fetch(url);
    const file = await res.blob();

    const reader = new FileReader();
    const base64data = await new Promise((resolve) => {
      reader.onload = (ev) => {
        resolve(ev.target.result);
      };
      reader.readAsDataURL(file);
    });

    return base64data;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  async fileChangeEvent(photoData: Photo) {
    this.isImageLoading = true;
    let { file, webviewPath } = photoData;

    if (!webviewPath && file) {
      webviewPath = await this.fileToDataURL(file);
    }

    if (!file && webviewPath) {
      const blob = await (await fetch(webviewPath)).blob();
      console.log(blob);
      console.log(typeof blob);
      file = new File([blob], 'cameraPhoto', { type: blob.type });
    }

    // const blob = await fetch(photoData).then((r) => r.blob());

    // If not a file object, make it one.

    this.file = file;
    console.log(file);

    if (this.crop) {
      this.isOpen = true;
      this.imageChangedEvent = file;
      this.isImageLoading = true;
    } else {
      this.imageUploaded.emit({
        image: webviewPath,
        sanitizedUrl: this.sanitize(webviewPath),
      });
    }
    // this.isImageLoading = false;
  }

  async imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    if (this.compressImage) {
      this.compressFile(event.base64);
    }
  }

  onImageLoaded(loadedImage: LoadedImage) {
    // if (this.imageCropper) {
    //   const { width, height } = loadedImage.original.size;
    //   this.imageCropper.cropperMinWidth = width;
    //   this.imageCropper.cropperMinHeight = height;
    // }
    this.isImageLoading = false;
  }

  onLoadBegin() {
    // The logic will now be handled in onImageLoaded
  }

  compressFile(base, size = 100) {
    this.imageCompress
      .compressFile(base, -1, size, size, 1024, 1024)
      .then((result: string) => {
        if (result.length > 9000000) {
          this.compressFile(result, 50);
        } else {
          this.croppedImage = result;
        }
      })
      .catch((e) => {
        console.log(e);
        this.snackBar.open(
          'Please select a smaller size image or crop your image smaller.',
          'Ok',
          {
            duration: 5000,
          }
        );
      });
  }

  chooseAnotherImage() {
    this.isOpen = false;
    if (!this.startingImageUrl) {
      this.openImageSelectionSheet();
    }
  }

  finalizeSelection() {
    this.imageUploaded.emit({
      image: this.croppedImage,
      sanitizedUrl: this.sanitize(this.croppedImage),
    });
    this.isOpen = false;
  }

  fileToDataURL(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result as string;
        resolve(dataURL);
      };
      reader.onerror = () => {
        reject(new Error('Error reading file.'));
      };
      reader.readAsDataURL(file);
    });
  }

  loadImageFailed() {
    console.error('Image failed to load');
    // Handle the error, e.g., show a message to the user
  }
}
