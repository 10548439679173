import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductsService } from 'src/app/services/products.service';
import { StripeService } from 'src/app/services/stripe.service';
import { TicketService } from 'src/app/services/ticket.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { api } from 'src/config/apiUrls';
import { MatButton } from '@angular/material/button';
import { FeaturesTableComponent } from '../../account/features-table/features-table.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-creator-pricing',
    templateUrl: './creator-pricing.component.html',
    styleUrls: ['./creator-pricing.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        FeaturesTableComponent,
        MatButton,
    ],
})
export class CreatorPricingComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();
  hasCreatorPlus = false;
  isDeclined = false;
  allFeatures;
  plus;
  isLoading = true;

  // trail;
  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private products: ProductsService,
    private orders: OrdersService,
    private stripe: StripeService, // private tickets: TicketService,
    private utilitiesService: UtilitiesService
  ) {}

  ngOnInit() {
    //Add logic to go to next page if already subscribed
    this.orders.listOrders({}).subscribe((data) => {
      console.log(data);
      const isCreator = data.results.some(
        (item) => item.product.identifier === 'creator_plus'
      );
      console.log(isCreator);
      if (isCreator) {
        this.nextPage();
      } else {
        //To load the plans for subscription
        this.upgrade();
      }
    });
  }

  nextPage() {
    // this.save();
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  activatePlan() {
    const price = this.plus.prices.find((item) =>
      item.lookup_key === (this.isDeclined
        ? 'creator_plus_monthly_trial'
        : 'creator_plus_monthly'
      )
    );
    console.log(price);
    const {
      id: priceId,
      metadata: { checkout_mode: mode },
      unit_amount: planCost,
    } = price;
    const { _id: productId } = this.plus;
    const body = {
      items: [{ price: priceId, quantity: 1 }],
      mode,
      product_id: productId,
      success_url: `${api.stripeCallback}/payment/confirmation?status=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${api.stripeCallback}/payment/confirmation?status=failed`,
    };

    this.stripe.purchase(body).subscribe((data) => {
      console.log(data);
      // localStorage.setItem('boardMode', 'creator');
      window.open(data.redirect_url, '_self');
    });

    console.log(body);
  }

  upgrade() {
    this.products.listProducts({ identifier: 'creator_plus' }).subscribe((data) => {
      console.log(data);
      const plus = data.results[0];
      this.plus = plus;

      this.loadFeatures();

      this.isLoading = false;
    });
  }

  loadFeatures() {
    // const product = this.isDeclined ? this.trail : this.plus;
    // Features
    const allFeatures = [];
    for (const enabledFeature of this.plus.enabled_features) {
      enabledFeature.hasAccess = true;
      allFeatures.push(enabledFeature);
    }
    for (const disabledFeature of this.plus.disabled_features) {
      disabledFeature.hasAccess = false;
      allFeatures.push(disabledFeature);
    }
    this.allFeatures = allFeatures;

    //TODO adding this logic as these are not coming from API, check with team why showcase doesn't have these fields
    this.plus.showcase_items = this.plus.showcase_items.map((item) => ({
      ...item,
      hasAccess: true,
      enabled: true,
    }));

    console.log(this.allFeatures);
  }

  // close() {
  //   // this.dialogRef.close();
  // }
}
