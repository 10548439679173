import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter, firstValueFrom } from 'rxjs';
import { TicketService } from 'src/app/services/ticket.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';

import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
    standalone: true,
    imports: [
    MatIcon,
    MatDivider,
    MatButton,
    MatProgressSpinner
],
})
export class ErrorDialogComponent implements OnInit {
  page = '';
  error: any = {};
  title = '';
  description = '';
  showReport = false;
  showRetry = false;
  env = environment.domain;

  reportSending = false;
  reportSent = false;
  showThankYouMessage = false;
  ticketNumber;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilities: UtilitiesService,
    private ticketService: TicketService
  ) {}

  ngOnInit() {
    console.log({ data: this.data });
    this.error = this.data.error;
    this.title = this.data.title;
    this.description = this.data.description;
    this.showReport = this.data.showReport;
    this.showRetry = this.data.showRetry;

    if (this.env === 'prod') {
      this.submit().subscribe((data) => {
        this.reportSent = true;
        this.ticketNumber = data.ticket_number;
      });
    }
  }

  submit() {
    const consoleDump = [...this.utilities.consoleHistory];
    const env = this.env.toUpperCase();

    const body = {
      email: this.utilities.email,
      details: `${this.error.status} error from ${this.error.url} encountered by ${this.utilities.email} on page ${this.utilities.currentPage}.`,
      metadata: {
        environment: env,
        local_storage: { ...localStorage },
        console_logs: consoleDump,
        account_id: this.utilities.accountId,
        page: this.utilities.currentPage,
        error_code: this.error.status,
        error_route: this.error.url,
        error_message: this.error.error?.message,
        full_error: this.error,
      },
      ticket_type: 'user',
      category: {
        name: `[${env}] Error Report`,
      },
    };

    return this.ticketService.createTicket(body);
  }

  close() {
    this.dialogRef.close();
  }

  submitReport() {
    this.reportSending = true;
    this.showThankYouMessage = true;

    this.submit().subscribe((data) => {
      this.reportSending = false;
      this.reportSent = true;
      this.ticketNumber = data.ticket_number;
    });
  }

  retry() {
    localStorage.removeItem('token');
    location.reload();
  }
}
