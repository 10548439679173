/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LocationService } from 'src/app/helper/location.service';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import { Observable } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as mt from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'app-personal-information',
    templateUrl: './personal-information.component.html',
    styleUrls: ['./personal-information.component.scss'],
    // providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-US' }],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    MatMiniFabButton,
    FaIconComponent
],
})
export class PersonalInformationComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() isOnboard = false;
  @Input() boardMode = 'full';
  @Input() color = 'primary';

  @ViewChild('ref') dob: ElementRef;

  accountInfoForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    // preferredPronouns: [''],
  });

  maxDob: Date;
  minDob: Date;

  is18Above = true;
  is13Below = false;

  tosAgree = false;

  isLoading = true;

  constructor(
    public dialog: MatDialog,
    private locationService: LocationService,
    private fb: UntypedFormBuilder,
    private jwt: JWTTokenService,
    private account: AccountService,
    private profiles: ProfilesService,
    private utilities: UtilitiesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.retrieveInformation();
    this.isLoading = false;
  }

  filteredTimezones: Observable<string[]>;

  guessTimezone() {
    return mt.tz.guess();
  }

  tzValidator(timezones: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validTz = timezones.includes(control.value);
      return validTz ? null : { forbiddenTz: { value: control.value } };
    };
  }

  // updateDate() {
  //   this.accountInfoForm.get('dateOfBirth').valueChanges.subscribe((data) => {
  //     console.log(data);
  //   });
  // }

  initializeDob() {
    const today = new Date();
    const defaultDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    // this.accountInfoForm.patchValue({ dateOfBirth: defaultDate });
    this.maxDob = new Date(
      // today.getFullYear() - 14, changing to show message for all ages
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    this.minDob = new Date(
      today.getFullYear() - 105,
      today.getMonth(),
      today.getDate()
    );
  }

  retrieveInformation() {
    console.log(this.utilities.accountDetails);
    console.log(this.utilities.profileDetails);
    const {
      email,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      zip_code: zipCode,
      settings: { tz: timezone },
    } = this.utilities.accountDetails;

    // const { pronouns: preferredPronouns, dob: dateOfBirth } =
    const { dob: dateOfBirth } =
      this.utilities.profileDetails;

    console.log(dateOfBirth);

    this.accountInfoForm.patchValue({
      firstName,
      lastName,
      // preferredPronouns,
    });
  }

  // dobChanges(value) {
  //   console.log(value);
  //   if (value.length === 2 || value.length === 5) {
  //     // console.log(value);

  //     // this.accountInfoForm.patchValue({dateOfBirth:`${value}/`});
  //     this.dob.nativeElement.value = `${value}/`;
  //   }
  // }

  async getLocation() {
    const { zipCode } = await this.locationService.getLocation();

    this.accountInfoForm.patchValue({ zipCode });
    // this.locationService.getPosition().then((pos) => {
    //   console.log(`Position: ${pos.lng} ${pos.lat}`);
    // });
  }

  nextPage() {
    console.log(this.accountInfoForm.value);
    const {
      firstName,
      // middleName,
      lastName,
      // email,
      // zipCode,
      // timezone,
      // preferredPronouns,
      // dateOfBirth,
    } = this.accountInfoForm.value;

    const userId = this.jwt.getSub();

    const bioProfileId = this.utilities.profileId;

    const accountBody = {
      first_name: firstName,
      // middle_name: middleName,
      last_name: lastName,
      // email,
      // zip_code: zipCode,
      // settings: { tz: timezone },
    };

    const profileBody = {
      first_name: firstName,
      // middle_name: middleName,
      last_name: lastName,
      // email,
      // zip_code: zipCode,
      // settings: { tz: timezone },
      // pronouns: preferredPronouns,
      // dob: new Date(dateOfBirth).toISOString().slice(0, 10),
    };

    console.log(userId, { accountBody }, { profileBody });

    //update account
    this.account
      .updateAccount(userId, accountBody)
      .subscribe((data: any) => {
        console.log(data);
        this.utilities.accountDetails = data;
      });

    //update profile
    this.profiles
      .updateProfile(bioProfileId, profileBody)
      .subscribe((data: any) => {
        console.log(data);
        this.utilities.profileDetails = data;
      });

    this.next.emit()
  }

  prevPage() {
    this.prev.emit()
  }

  closeDialog() {
    this.close.emit()
  }

  saveChanges() {
    console.log(this.accountInfoForm.value);
    const {
      firstName,
      middleName,
      lastName,
      email,
      zipCode,
      timezone,
      // preferredPronouns,
      dateOfBirth,
    } = this.accountInfoForm.value;

    const userId = this.jwt.getSub();

    const bioProfileId = this.utilities.profileId;

    const accountBody = {
      first_name: firstName,
      last_name: lastName,
    };

    const profileBody = {
      first_name: firstName,
      last_name: lastName,
      // pronouns: preferredPronouns,
    };

    console.log(userId, { accountBody }, { profileBody });

    //update account
    this.account
      .updateAccount(userId, accountBody)
      .subscribe((data: any) => {
        console.log(data);
        this.utilities.accountDetails = data;
      });

    //update profile
    this.profiles
      .updateProfile(bioProfileId, profileBody)
      .subscribe((data: any) => {
        console.log(data);
        this.utilities.profileDetails = data;
      });

    this.snackBar.open('Updated account information', 'OK', {
      duration: 5000,
    });

  }

  private _filterTimezones(value: string): string[] {
    const filterValue = value.toLowerCase();
    return mt.tz.names().filter((tz) => tz.toLowerCase().includes(filterValue));
  }
}
