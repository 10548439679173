/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { BadgesComponent } from 'src/app/badges/badges.component';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-badge-info',
    templateUrl: './badge-info.component.html',
    styleUrls: ['./badge-info.component.scss'],
    standalone: true,
    imports: [
    FaIconComponent,
    MatButton
],
})
export class BadgeInfoComponent implements OnInit {
  @Output() newPageOpened = new EventEmitter();
  @Output() badgeProcessFinished = new EventEmitter();

  @Input() badge!: any;
  badgeImage: any = '';
  showMore = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private utilities: UtilitiesService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    console.log(this.badge);
    this.badgeImage = this.sanitizer.bypassSecurityTrustUrl(
      // `${environment.host}/static/images/${this.badge.image}`
      `/badges/${this.badge.image}`
    ); //environment.host + '/static/images/' + this.badge.image;
    // console.log(environment.host);
    // console.log(this.badgeImage);
  }

  navigate(route) {
    this.utilities.returnUrl = this.router.url;

    if (!this.badge['available_for_profile']) {
      route = 'change-plan';
    } else if (route === 'identity' && !this.badge['prereqs_satisfied']) {
      route = 'social-media';
    }

    switch (route) {
      case 'social-media':
      case 'identity':
      case 'influencer': {
        const page =
          route === 'social-media'
            ? 'choose-social-media'
            : route === 'identity'
            ? 'identity'
            : route === 'influencer'
            ? 'influencer-requirements'
            : '';

        const dialogRef = this.dialog.open(BadgesComponent, {
          data: { mode: 'standard', page },
          panelClass: page === 'identity' ? 'custom-identity-dialog' : 'custom-badge-dialog',
        });

        dialogRef.afterClosed().subscribe(() => {
          this.badgeProcessFinished.emit();
        });
        break;
      }
      case 'change-plan': {
        this.newPageOpened.emit();
        this.router.navigateByUrl(`/account/${route}`);
        break;
      }
    }
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }

  goBack() {
    this.location.back();
  }
}
