import { Injectable } from '@angular/core';
import { JWTTokenService } from './jwttoken.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  router: any;
  constructor(private jwt: JWTTokenService) {
    this.setToken();
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  checkId() {
    const status =
      localStorage.getItem('anonymous_id') ||
      localStorage.getItem('account_id');
    if (status) {
      //check token status with API
    } else if (
      localStorage.getItem('anonymous_id') &&
      localStorage.getItem('account_id') === null
    ) {
      //call to  /api/v1/signin?anonymous_id=stored_id
    } else if (
      localStorage.getItem('anonymous_id') === null &&
      localStorage.getItem('account_id')
    ) {
      //call to  /api/v1/signin?account_id=stored_id
    } else {
      // redirect to "Get Started" page
      // api call to /signin
      this.router.navigateByURL('/get-started');
    }
    return;
  }

  setToken() {
    this.jwt.setToken(localStorage.getItem('token'));
  }
}
