import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class JWTTokenService {
  jwtToken: string;
  decodedToken: { [key: string]: string };

  constructor() {}

  setToken(token: string) {
    if (token) {
      localStorage.setItem('token', token);
      this.jwtToken = token;
    }
  }

  removeToken() {
    localStorage.removeItem('token');
    this.jwtToken = null;
  }

  decodeToken() {
    if (this.jwtToken) {
      const decodedToken = jwtDecode(this.jwtToken);
      // console.log(decodedToken);
      this.decodedToken = decodedToken as any;
    }
  }

  verifyToken(token) {
    try {
      const decodedHeader = jwtDecode(token);
      console.log({ decodedHeader });
      if (
        this.isTokenExpired(decodedHeader['exp']) ||
        !this.isTokenAuthenticated(decodedHeader['type'])
      ) {
        return false;
      }
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  getDecodeToken() {
    // const decodedToken = jwtDecode(this.jwtToken);
    // console.log(decodedToken);
    return this.decodedToken;
  }

  getAuthorizationHeader() {
    return this.jwtToken ? `Bearer ${this.jwtToken}` : null;
  }

  getSub() {
    // this.decodeToken();
    return this.decodedToken ? this.decodedToken.sub : null;
  }

  getUser() {
    // this.decodeToken();
    return this.decodedToken ? this.decodedToken.name : null;
  }

  // getEmailId() {
  //   this.decodeToken();
  //   return this.decodedToken ? this.decodedToken.email : null;
  // }

  getExpiryTime() {
    // this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  getType() {
    // this.decodeToken();
    return this.decodedToken ? this.decodedToken.type : null;
  }

  isTokenExpired(exp = null) {
    const expiryTime = exp || Number(this.getExpiryTime());
    // console.log(Date.now(), expiryTime * 1000);
    if (expiryTime) {
      // check if current date is less than th e token expiry time
      return Date.now() >= expiryTime * 1000;

      // return Date.now() < expiryTime * 1000;
    } else {
      return true;
    }
  }

  isTokenAuthenticated(type = null) {
    const userType = type || this.getType();
    return userType === 'authenticated';
  }

  isAuthenticatedUser() {
    return !this.isTokenExpired() && this.isTokenAuthenticated();
  }
}
