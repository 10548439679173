// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-div {
  background-color: black; /* Add this line for the black background */
  background-image: url('auth-bg.8bbb329e7ebba26a.jpg');
  background-size: cover; /* Make the background cover the entire div */
  background-position: center; /* Center the background image */
  width: 100vw;
  min-height: 100vh; /* Full viewport height */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  text-align: center;
  color: white;
}

.auth-font {
  font-family: "Tex", sans-serif !important;
}

.hover-white:hover .icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(180deg);
}

.hover-white svg {
  transition: fill 0.3s ease;
}

.hover-white:hover svg path {
  fill: white;
}

.email-button {
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}
.email-button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
