import { Directive } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, debounceTime, delay, map, switchMap } from 'rxjs/operators';
import { ProfilesService } from '../services/profiles.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
    selector: '[appUsernameValidator]',
    standalone: true,
})
export class UsernameValidatorDirective {
  static createValidator(
    profilesService: ProfilesService,
    snackBar: MatSnackBar,
    profileShort
  ): AsyncValidatorFn {
    return (control: UntypedFormControl): Observable<ValidationErrors> => {
      return of(control.value).pipe(
        delay(500),
        switchMap(username => {
          return profilesService.checkProfileUsernameAndPermissions(username, profileShort).then(
            (result: any) => {
              console.log(result);
              if (result) {
                const { usernameStatus: { availability, type, alternate_free, alternate_paid }, premiumAccess, isCurrent } = result;
                if (isCurrent) {
                  return null
                } else if (availability) {
                  if (type === 'free') {
                    // Free and available
                    return null;
                  } else {
                    // PLUS and available
                    return premiumAccess ? null : { noPremiumAccess: true, alternativeFree: alternate_free, alternativePaid: alternate_paid };
                  }
                } else {
                  return { usernameTaken: true, alternativeFree: alternate_free, alternativePaid: alternate_paid };
                }
              } else {
                return { alternativeFree: [], alternativePaid: [] }
              }
            }
          )
        })
      )
    }
  }
}
