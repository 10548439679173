import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ProductsService } from 'src/app/services/products.service';
import { StripeService } from 'src/app/services/stripe.service';
import { api } from 'src/config/apiUrls';
import { MatButton } from '@angular/material/button';
import { FeaturesTableComponent } from '../../account/features-table/features-table.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatRipple } from '@angular/material/core';
import { DecimalPipe } from '@angular/common';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-product-info',
    templateUrl: './product-info.component.html',
    styleUrls: ['./product-info.component.scss'],
    standalone: true,
    imports: [
    NgbPopover,
    MatRipple,
    FaIconComponent,
    FeaturesTableComponent,
    MatButton,
    DecimalPipe
],
})
export class ProductInfoComponent implements OnInit {
  @Output('viewMoreClicked') viewMoreClicked = new EventEmitter();
  @Input('productType') productType = '';     // "Standard", "Plus Trial", "Plus"
  @Input('productData') productData = null;   // Full product data (if available)
  @Input('placement') placement = 'auto';   // Full product data (if available)
  allFeatures = [];
  showcaseItems = [];

  constructor(
    private products: ProductsService,
    private stripe: StripeService,
    private router: Router
  ) { }

  async ngOnInit() {
    if (this.productType && !this.productData) {
      const data = await firstValueFrom(this.products.listProducts(
        {
          identifier: this.productType
        }
      ));
      if (data.results && data.results.length > 0) {
        this.productData = data.results[0];

        this.allFeatures = [];
        for (const enabledFeature of this.productData.enabled_features) {
          enabledFeature.hasAccess = true;
          this.allFeatures.push(enabledFeature);
        }
        for (const disabledFeature of this.productData.disabled_features) {
          disabledFeature.hasAccess = false;
          this.allFeatures.push(disabledFeature);
        }

        this.showcaseItems = this.productData.showcase_items.map((item) => ({
          ...item,
          enabled: true,
        }));

        // Price Details
        for (const price of this.productData.prices) {
          if (price.type === 'recurring') {
            price.recurring_text = `${
              price.recurring.interval_count > 1 ? price.recurring.interval_count + ' ' : ''
            }${
              price.recurring.interval_count > 1 ? price.recurring.interval + 's' : price.recurring.interval
            }`;
          }
        }
        const defaultPrice = this.productData.prices.filter((productPrice) => productPrice.is_default)[0];
        if (defaultPrice.type === 'recurring') {
          defaultPrice.recurring_text = `${
            defaultPrice.recurring.interval_count > 1 ? defaultPrice.recurring.interval_count + ' ' : ''
          }${
            defaultPrice.recurring.interval_count > 1 ? defaultPrice.recurring.interval + 's' : defaultPrice.recurring.interval
          }`;
        }
        this.productData.default_price = defaultPrice;

        console.log("ALL", this.allFeatures);
      }
    }

    console.log(this.productData.prices)

    this.productData.prices = this.productData.prices.filter((price) => price.metadata.special_offer !== 'true');
  }

  activatePlan(priceId, productId, mode, planName, planCost) {
    const body = {
      items: [{ price: priceId, quantity: 1 }],
      mode,
      product_id: productId,
      success_url: `${api.stripeCallback}/payment/confirmation?status=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${api.stripeCallback}/payment/confirmation?status=failed`,
    };

    this.stripe.purchase(body).subscribe((data) => {
      console.log(data);
      window.open(data.redirect_url, '_self');
    });

    console.log(body);
  }

  navigate(route) {
    this.viewMoreClicked.emit()
    this.router.navigateByUrl(route);
  }
}
