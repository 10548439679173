// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.requirement-list-item {
  margin-bottom: 10px;
  margin-left: 16px;
}

.btn-text {
  line-height: 30px;
}

.badge-icon {
  height: 85px;
  width: 85px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
