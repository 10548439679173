import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrdersService } from 'src/app/services/orders.service';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-confirm-cancel-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: true,
    imports: [MatButton],
})
export class ConfirmCancelDialogComponent implements OnInit {
  loading = false;

  constructor(
    private orders: OrdersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ConfirmCancelDialogComponent>,
  ) {}

  ngOnInit() {}

  cancelMembership() {
    this.loading = true
    this.orders.cancelOrder(this.data.orderId, true).subscribe(data => {
      if (data.success) {
        location.reload()
      }
    })
  }

  close() {
    this.dialogRef.close()
  }

}
