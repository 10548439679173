/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { api } from '../../config/apiUrls';
import { UtilitiesService } from './utilities.service';
import { FeatureLockModalComponent } from '../helper/feature-lock-modal/feature-lock-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

const READ_ONLY_FIELDS_BASE: string[] = [
  'analytics',
  'badges',
  'created_at',
  'features',
  // 'is_main',
  'short_link',
  'updated_at',
  'owned',
  'profile',
  'image_index',
  'image_ext',
  'expanded_fields',
  'image_url',
  'spotlight_footer',
]

const READ_ONLY_FIELDS: any = {
  bio: READ_ONLY_FIELDS_BASE,
  passport: READ_ONLY_FIELDS_BASE,
  about: READ_ONLY_FIELDS_BASE,
  portfolio: READ_ONLY_FIELDS_BASE,
  agency: [ ...READ_ONLY_FIELDS_BASE, 'status' ],
}

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(
    private http: HttpClient,
    public utilities: UtilitiesService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {
    // api[pageType] = this.pageType === "bio" ? api.bio :
    //   this.pageType === "passport" ? api.passport :
    //   this.pageType === "about" ? api.about :
    //   undefined
    // this.imageApiBasePath = this.pageType === "bio" ? this.bioImage :
    //   this.pageType === "passport" ? api.passportImage :
    //   this.pageType === "about" ? api.aboutImage :
    //   undefined
  }

  listPages(options, pageType, bypassLimit = false) {
    const { filters, ...rest } = options;
    const params = {
      ...rest,
      ...(filters &&
        filters.reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item.split('=')[0]]: item.split('=')[1],
            }),
          {}
        )),
    };
    console.log({ params });
    console.log(pageType)

    // default minimized fields
    if (!params['expanded_fields'] && params['minimized_fields'] !== 'all') {
      const currentMinimizedFields = params['minimized_fields']
        ? params['minimized_fields'].split(',')
        : [];
      params['minimized_fields'] = [
        ...currentMinimizedFields,
        'products_offered_in',
        'customization_options',
        'videos', //adding videos here as mentioned by Joel
      ].join(',');
    }

    // Filter to selected profileId
    if (
      localStorage.getItem('limitToSelectedProfile') === 'true' &&
      !bypassLimit
    ) {
      // undefined counts as true in this case
      params['profile'] = this.utilities.profileId;
    }

    return this.http.get<any>(api[pageType], {
      params,
    });
  }

  reportPage(id, pageType, body) {
    return this.http.post<any>(`${api[pageType]}/${id}/report`, body);
  }

  async createPage(body, pageType, imageUrl = null, params = {}) {
    // default minimized fields
    if (!params['expanded_fields'] && params['minimized_fields'] !== 'all') {
      const currentMinimizedFields = params['minimized_fields']
        ? params['minimized_fields'].split(',')
        : [];
      params['minimized_fields'] = [
        ...currentMinimizedFields,
        'products_offered_in',
        'customization_options',
        'videos', //adding videos here as mentioned by Joel
      ].join(',');
    }

    const res = await firstValueFrom(
      this.http.post<any>(api[pageType], body, { params }).pipe(
        catchError((err) => {
          const featureName =
            err.error.message ===
            'You have reached the max number of pages allowed by your plan.'
              ? 'Unlimited Profiles'
              : err.error.message === 'Your plan does not allow for multiple pages.'
              ? 'Multiple Profiles'
              : null;

          this.dialog.open(FeatureLockModalComponent, {
            panelClass: 'feature-lock-dialog',
            data: {
              featureName,
            },
          });
          throw new Error(err.error.message);
        })
      )
    );

    // Update page image
    if (res && imageUrl) {
      const upload_response = this.uploadPageImage(
        res._id,
        pageType,
        imageUrl,
        'avatar'
      );
    }

    //Adding return statement to the function which caused issue during the Profile card addition NT-L3-F-8205
    return res;
  }

  retrievePage(lookupId, pageType, params = {}) {
    // default minimized fields
    if (!params['expanded_fields'] && !params['expanded_fields'] && params['minimized_fields'] !== 'all') {
      const currentMinimizedFields = params['minimized_fields']
        ? params['minimized_fields'].split(',')
        : [];
      params['minimized_fields'] = [
        ...currentMinimizedFields,
        'products_offered_in',
        'customization_options',
      ].join(',');
    }
    return this.http.get<any>(`${api[pageType]}/${lookupId}`, { params });
  }

  retrievePageQrCode(lookupId, params, pageType) {
    params['host'] = api.shortUrl;
    params['color'] = params['color'].replace('#', '');

    return this.http.get(`${api[pageType]}/${lookupId}/qr`, {
      params,
      responseType: 'blob',
    });
  }

  async updatePage(
    lookupId,
    pageType,
    body = null,
    code = null,
    imageUrl = null,
    params = {},
    updateCache = true
  ) {
    // Update page image
    if (imageUrl) {
      const upload_response = this.uploadPageImage(
        lookupId,
        pageType,
        imageUrl,
        'avatar'
      );
    }

    console.log(body);

    // delete body['image_url']; // added logic as its giving API error if image_url is present

    // Remove Read-only fields
    (READ_ONLY_FIELDS[pageType] || []).forEach((field) => delete body[field]);

    // default minimized fields
    if (!params['expanded_fields'] && params['minimized_fields'] !== 'all') {
      const currentMinimizedFields = params['minimized_fields']
        ? params['minimized_fields'].split(',')
        : [];
      params['minimized_fields'] = [
        ...currentMinimizedFields,
        'products_offered_in',
        'customization_options',
        'videos', //adding videos here as mentioned by Joel
      ].join(',');
    }

    console.log(params);

    const page: any = await firstValueFrom(
      this.http.patch(`${api[pageType]}/${lookupId}`, body, { params })
    );

    if (page.is_main && updateCache) {
      const pageTypeTitleCase =
        pageType.charAt(0).toUpperCase() + pageType.slice(1);
      this.utilities[`main${pageTypeTitleCase}Details`] = page;
    }

    return page;
  }

  /**
   * Accepts a page object in the same format as it is returned from the API,
   * and updates the page using the API.
   * @param pageData The full page including any changes
   * @param image An optional image file for updating the page image
   * @returns the API response from update page
   */
  async updateFullPage(pageData, pageType, images = {}, params = {}) {
    console.log(pageData, pageType, images);
    const page = { ...pageData };

    // Extract page id
    const pageId = page._id;
    delete page['_id'];
    if (!pageId) {
      return null;
    }

    // Update page image
    for (const [element, image] of Object.entries(images)) {
      if (image) {
        const upload_response = await this.uploadPageImage(
          pageId,
          pageType,
          image,
          element
        );
        console.log(upload_response);
      }
    }

    // Remove Read-only fields
    (READ_ONLY_FIELDS[pageType] || []).forEach((field) => delete page[field]);

    // Map sub-objects to just IDs
    if (page.active_theme && page.active_theme._id) {
      page.active_theme = page.active_theme._id;
    }
    if (page.referral_banner && page.referral_banner._id) {
      page.referral_banner = page.referral_banner._id;
    }
    if (page.links) {
      let linkArray = [];
      for (const link of page.links) {
        if ('verified' in link) {
          delete link['verified'];
        }
        if (link._id) {
          linkArray.push(link._id);
        } else if (link) {
          linkArray.push(link);
        }
      }
    }

    // Update page using body
    const response = await this.updatePage(
      pageId,
      pageType,
      page,
      undefined,
      undefined,
      params
    );

    return response;
  }

  /**
   * Accepts a page object in the same format as it is returned from the API,
   * and updates the page using the API.
   * @param pageData The full page including any changes
   * @param image An optional image file for updating the page image
   * @returns the API response from update page
   */
  async createFullPage(pageData, pageType, images) {
    const page = { ...pageData };

    // Extract page Id
    if (page._id) {
      delete page['_id'];
    }

    // Map sub-objects to just IDs
    if (page.active_theme && page.active_theme._id) {
      page.active_theme = page.active_theme._id;
    }
    if (page.referral_banner && page.referral_banner._id) {
      page.referral_banner = page.referral_banner._id;
    }
    if (page.links) {
      let linkArray = [];
      for (const link of page.links) {
        if ('verified' in link) {
          delete link['verified'];
        }
        if (link._id) {
          linkArray.push(link._id);
        } else if (link) {
          linkArray.push(link);
        }
      }
    }

    // Remove Read-only fields
    (READ_ONLY_FIELDS[pageType] || []).forEach((field) => delete page[field]);

    // Create page using body
    const response: any = await this.createPage(page, pageType);

    if (!response.error) {
      // Update page image
      return this.updateFullPage(response, pageType, images);
    }

    return response;
  }

  getPresignedPostData(lookupId, pageType, body) {
    return this.http.post(`${api[pageType]}/${lookupId}/image`, body);
  }

  imageUpload(url, formData) {
    return this.http.post(url, formData);
  }

  async uploadPageImage(
    lookupId,
    pageType,
    newImage,
    element,
    replace = true,
    options = { generate_thumbnail: true }  // Generate thumbnails by default for all images
  ) {
    const res = await fetch(newImage);
    const file = await res.blob();
    const ext = await res.headers.get('Content-Type');

    console.log(ext);
    console.log(typeof ext);

    let extension = ext.split('/').pop(); //for saving just the extension

    console.log(extension);

    // Get presigned image upload URL and data
    let presignedPostData: any;

    presignedPostData = await firstValueFrom(
      this.getPresignedPostData(lookupId, pageType, {
        extension: extension,
        element: element,
        should_replace: replace,
        options: options,
      }).pipe(catchError((err, caught) => of({ error: true })))
    );

    console.log(presignedPostData);

    if (presignedPostData.error) {
      throw Error('Generate Presigned Post Failed');
    }

    const {
      presigned_url_data: {
        url,
        fields: {
          AWSAccessKeyId,
          key,
          policy,
          signature,
          'x-amz-security-token': token,
        },
      },
      image_doc,
    } = presignedPostData;

    // Format form data
    const formData: FormData = new FormData();
    formData.append('AWSAccessKeyId', AWSAccessKeyId);
    formData.append('key', key);
    formData.append('policy', policy);
    formData.append('signature', signature);
    formData.append('x-amz-security-token', token); // why this is commented previously
    formData.append('file', file);

    // Upload form data to presigned URL
    const upload_response: any = await firstValueFrom(
      this.imageUpload(url, formData)
    );

    console.log(upload_response);

    // Return file index and extension
    return image_doc;
  }

  downloadPageImage(lookupId, pageType, index, extension) {
    return this.http.get(
      api[`${pageType}Image`] + `/${lookupId}/${index}.${extension}`,
      {
        responseType: 'blob',
      }
    );
  }

  duplicatePage(lookupId, pageType, body = {}, params = {}) {
    return this.http
      .post(`${api[pageType]}/${lookupId}/copy`, body, { params })
      .pipe(
        catchError((err) => {
          this.snackbar.open(err.error.message, 'OK', { duration: 5000 });
          throw new Error(err.error.message);
        })
      );
  }

  async isFeatureEnabledForPageByLookupId(
    lookupId,
    pageType,
    featureIdentifier
  ) {
    const res = await firstValueFrom(
      this.retrievePage(lookupId, pageType).pipe(
        catchError((err, caught) => {
          return of({ error: true });
        })
      )
    );

    if (!res || res.error) {
      return false;
    }

    return this.isFeatureEnabledForPage(res, pageType, featureIdentifier);
  }

  isFeatureEnabledForPage(pageData, pageType, featureIdentifier) {
    if (pageData) {
      return (
        pageData.features.enabled_features.filter((feature) => {
          return feature.identifier === featureIdentifier;
        }).length > 0
      );
    } else {
      return false;
    }
  }

  deletePage(lookupId, pageType) {
    return this.http.delete(`${api[pageType]}/${lookupId}`);
  }

  listPageLogs(lookupId, pageType, options = undefined) {
    const params = {
      ...(options?.sort && { order_by: options.sort }),
      ...(options?.filters &&
        options.filters.reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item.split('=')[0]]: item.split('=')[1],
            }),
          {}
        )),
    };

    return this.http.get<any>(`${api[pageType]}/${lookupId}/log`, { params });
  }

  createPageLog(lookupId, pageType, body) {
    console.log('creating page log');
    console.log(`route: ${api[pageType]}/${lookupId}/log`);
    return this.http.post<any>(`${api[pageType]}/${lookupId}/log`, body);
  }

  getImageUrl(page, pageType) {
    return (
      api[`${pageType}Image`] +
      `/${page._id}/${page.image_index}.${page.image_ext}`
    );
  }

  getVerifiedSocialBadgesForProfile(lookupId, pageType) {
    // default minimized fields
    // TODO: analyze what is needed on a case-by-case basis and pass in with options object instead.
    const params = { minimized_fields: 'products_offered_in' };

    return this.http.get<any>(
      `${api[pageType]}/${lookupId}/badges?type__in=linkedin,twitter,facebook,instagram,tiktok`,
      { params }
    );
  }

  async retrieveMainPage(
    pageType,
    useCache = true,
    minimized_fields = '',
    updateCache = true
  ) {
    const pageTypeTitleCase =
      pageType.charAt(0).toUpperCase() + pageType.slice(1);

    let main = null;

    if (this.utilities[`main${pageTypeTitleCase}Details`]?._id && useCache) {
      // Use cached page from utilities
      main = this.utilities[`main${pageTypeTitleCase}Details`];
    } else {
      // Fetch page ffrom API
      const res = await firstValueFrom(
        this.listPages({ is_main: true, minimized_fields }, pageType)
      );
      if (res) {
        main = res?.results.at(0);
        if (main && updateCache) {
          // Update cache in utilities
          this.utilities[`main${pageTypeTitleCase}Details`] = main;
        }
      }
    }

    return main;
  }

  assignUsername(page, pageType) {
    const promises = [];

    // Update page to be main
    if (!page.is_main) {
      promises.push(
        firstValueFrom(
          this.http.patch(
            `${api[pageType]}/${page._id}`,
            { is_main: true },
            { params: { minimized_fields: 'all' } }
          )
        )
      );
    }

    // Mark pageType as preferred
    if (pageType) {
      promises.push(
        firstValueFrom(
          this.http
            .patch(
              `${api.profiles}/${page.profile}`,
              { preferred_page: pageType },
              { params: { minimized_fields: 'all' } }
            )
            .pipe(
              map((result: any) => {
                // Update utilities
                if (result._id === this.utilities.profileId) {
                  this.utilities.profileDetails.preferred_page =
                    result.preferred_page;
                }
                return result;
              })
            )
        )
      );
    }

    return Promise.all(promises);
  }

  async refetchPageProperty(pageId, pageType, options={}, attempts=5, getPropertyFn=(page) => {}) {
    while (attempts > 0) {
      await this.utilities.sleep(1000);

      const pageResponse: any = await firstValueFrom(this.retrievePage(pageId, pageType, options))

      const property: any = getPropertyFn(pageResponse)
      console.log(property)
      if (property) {
        return property
      }

      attempts -= 1;
    }

    return null
  }

  /* Tracking the total number of pages a user has */

  // private totalPagesSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  // public totalPages: Observable<number> = this.totalPagesSubject.asObservable();

  // private pageLimitSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  // public pageLimit: Observable<string> = this.pageLimitSubject.asObservable();

  // async getTotalPages(isCreator=false) {
  //   let totalPages = 0;

  //   const options = {
  //     status__is_disabled: false,
  //     status__is_archived: false,
  //     is_main: false,
  //     limit: 0,
  //     minimized_fields: 'all',
  //   };

  //   //TODO need to have a separate API to calculate total pages(for Free user) to make dashboard faster #NT-L1-F-9309
  //   // Prepare all the promises
  //   const promises = [
  //     this.listPages(options, 'bio'),
  //     this.listPages(options, 'passport'),
  //     this.listPages(options, 'about'),
  //     ...(isCreator
  //       ? [this.listPages(options, 'creator')]
  //       : []),
  //   ];

  //   // Use Promise.all to wait for all promises to resolve
  //   const responses = await Promise.all(promises.map((p) => firstValueFrom(p)));

  //   // Process all responses together
  //   responses.forEach((response) => {
  //     totalPages += response?.count || 0;
  //   });

  //   console.log({TOAL_PAGES: totalPages})

  //   this.totalPagesSubject.next(totalPages);
  // }
}
