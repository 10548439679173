import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
// import { createAvatar } from '@dicebear/avatars';
// import * as style from '@dicebear/avatars-avataaars-sprites';
import { PhotoService } from 'src/app/services/photo.service';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.scss'],
    standalone: true,
    imports: [
    MatButton,
    FaIconComponent,
    MatFormField,
    MatInput,
    FormsModule,
    ReactiveFormsModule
],
})
export class ProfilePictureComponent implements OnInit {
  svgs: Array<SafeHtml>;
  showAvatar = false;
  seedControl = new UntypedFormControl('');
  constructor(
    private sanitizer: DomSanitizer,
    private bottomSheetRef: MatBottomSheetRef<ProfilePictureComponent>,
    public photoService: PhotoService,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      uniqueId: any;
      allowPhoto: boolean;
      allowGallery: boolean;
    }
  ) {}

  ngOnInit() {
    this.changeCustomSeed('custom-seed');
    this.seedControl.valueChanges.subscribe((value) => {
      this.changeCustomSeed(value);
    });
    this.photoService.addNewToGallery(undefined);
  }

  closeSheet(): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  toggleAvatar() {
    this.showAvatar = !this.showAvatar;
  }

  changeCustomSeed(term) {
    // const createSvg = (id) =>
    //   createAvatar(style, {
    //     seed: `${term.split(' ').join('-')}-${id}`,
    //     // ... and other options
    //   });
    // this.svgs = [...Array(8).keys()].map((item) =>
    //   this.sanitizer.bypassSecurityTrustHtml(createSvg(item))
    // );
  }

  takePhoto() {
    this.photoService.addNewToGallery(true, this.data?.uniqueId);
    this.closeSheet();
  }

  selectGallery() {
    // console.log('test');
    this.photoService.addNewToGallery(false, this.data?.uniqueId);
    this.closeSheet();
  }

  get showPhoto() {
    return this.data?.allowPhoto;
  }

  get showGallery() {
    return this.data?.allowGallery;
  }
}
