/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../../config/apiUrls';
import { decamelizeKeys } from 'humps';

@Injectable({
  providedIn: 'root',
})
export class PledgeService {
  constructor(private http: HttpClient, private router: Router) {}

  verifyPurchase(sessionId) {
    return this.http.post<any>(api.stripePurchase + `?verify=true`, {
      session_id: sessionId,
    });
  }

  createPledge(body) {
    return this.http.post<any>(api.pledges, decamelizeKeys(body));
  }

  setPledgeValue(key: any, value: any) {
    let pledge = localStorage.getItem('pledge');
    if (pledge) {
      const parsePledge = JSON.parse(pledge) as any;
      localStorage.setItem(
        'pledge',
        JSON.stringify({ ...parsePledge, [key]: value })
      );
    } else {
      localStorage.setItem('pledge', JSON.stringify({}));
    }
  }

  getPledge() {
    let pledge = localStorage.getItem('pledge');
    if (pledge) {
      return JSON.parse(pledge);
    } else {
      this.router.navigateByUrl('/pledge-promo');
    }
  }
}
