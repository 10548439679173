/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JWTTokenService } from '../services/jwttoken.service';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from '../shared/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class UniversalAppInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  // Error dialog flag to prevent opening twice
  errorDialogOpen = false;

  constructor(
    // private injector: Injector,
    private router: Router,
    private jwt: JWTTokenService,
    private dialog: MatDialog,
  ) {}

  addAuthHeader(request) {
    // Ensure the jwt token is up to date
    this.jwt.jwtToken = localStorage.getItem('token');
    this.jwt.decodeToken();

    // Don't add auth header to certain requests
    if (
      request.url.includes('amazonaws') || 
      request.url.includes('images') ||
      request.url.includes('https://www.youtube.com/oembed') ||
      request.url.includes('https://vimeo.com/api/oembed.json') ||
      request.url.includes('https://www.tiktok.com/oembed')
    ) {
      return request;
    }

    const authHeader = this.jwt.getAuthorizationHeader();
    // console.log(authHeader);
    if (authHeader) {
      return request.clone({
        setHeaders: {
          Authorization: authHeader,
        },
      });
    }
    return request;
  }

  removeToken() {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  openErrorDialog(error, title, description, showReport, showRetry) {
    if (!this.errorDialogOpen) {
      this.errorDialogOpen = true;

      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        panelClass: 'error-dialog',
        data: {
          error,
          title,
          description,
          showReport,
          showRetry,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.errorDialogOpen = false;
      });
    }
  }

  handleResponseError(error, request?, next?) {
    console.log(error);

    if (error.status === 400) {
      // Show message
    }

    // Invalid token error
    else if (error.status === 401) {
      this.openErrorDialog(
        error,
        'Session expired',
        'Your sign in session has expired. Please sign in again.',
        false,
        true
      );
    }

    // Access denied error
    else if (error.status === 403) {
      // this.openErrorDialog(true, error)
      // this.logout();
      // this.authService.signOut();
      // this.removeToken();
    }

    // Account Disabled Error
    else if (error.status === 409) {
      this.openErrorDialog(
        error,
        'Account disabled',
        'Your account has been disabled. Contact support for more information or to submit an appeal.',
        false,
        false
      );
    }

    // Server error
    else if (error.status === 500) {
      this.openErrorDialog(
        error,
        'Something went wrong',
        'An error occurred within the app. Please try again later or contact support for assistance.',
        true,
        true
      );
    }

    // Maintenance error
    else if (error.status === 503) {
      this.openErrorDialog(
        error,
        'Website under maintenance',
        'socialvalid.com is currently undergoing maintenance. Please try again later.',
        false,
        true
      );
    }

    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    // Add authorization token to request
    request = this.addAuthHeader(request);

    // Handle response
    return next
      .handle(request)
      .pipe(
        catchError((error) => this.handleResponseError(error, request, next))
      );
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: UniversalAppInterceptor,
  multi: true,
};
