// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redeem-background {
  background-image: url('iStock-1437614729-[Converted].872d7822cc38083b.png');
  background-size: cover;
  background-position: top center;
  background-color: #e8f6fc;
  position: relative;
}

.redeem-card {
  border-radius: 10px;
  max-width: 500px;
  border: 3px solid var(--primary-light);
}

.product-title {
  color: var(--primary);
  font-size: 20pt;
}

.product-price {
  color: var(--mat-theme-text);
  font-size: 40pt;
  line-height: 34pt;
  font-weight: 300;
}

.currency-sign {
  color: var(--mat-theme-text);
  font-size: 12pt;
}

.recurring-text {
  color: var(--mat-theme-disabled);
  font-size: 11pt;
}

.trial-price-text {
  color: var(--mat-theme-text);
  font-size: 11pt;
}

.trial-text {
  color: var(--mat-theme-text);
  font-size: 9pt;
}

.default-price-text {
  color: var(--mat-theme-disabled);
  font-size: 9pt;
}

.discount-text {
  color: var(--mat-theme-disabled);
  font-size: 11pt;
}
.discount-text.striked {
  text-decoration: line-through;
}

.feature-table {
  display: block;
  max-height: 400px;
  overflow-y: scroll;
}
@media (max-height: 800px) {
  .feature-table {
    max-height: 200px;
  }
}
@media (max-height: 600px) {
  .feature-table {
    max-height: 150px;
  }
}

hr {
  border-color: rgba(0, 0, 0, 0.48) !important;
}

.bottom-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: var(--mat-theme-card);
}
@media (max-height: 800px) {
  .bottom-banner {
    height: 50px;
  }
}
@media (max-height: 600px) {
  .bottom-banner {
    height: 40px;
  }
}

.banner-image {
  height: 40px;
}
@media (max-height: 800px) {
  .banner-image {
    height: 30px;
  }
}
@media (max-height: 600px) {
  .banner-image {
    height: 25px;
  }
}

.logo-image {
  filter: brightness(0) saturate(100%) invert(18%) sepia(97%) saturate(3188%) hue-rotate(272deg) brightness(78%) contrast(134%);
  height: 80px;
}

.redeem-title {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--primary);
  text-align: center;
}

.agency-list-item {
  width: 100%;
  white-space: break-spaces;
  font-size: 14px;
  margin-bottom: 0;
  margin: 10px 0px 10px 0px;
}

.agency-description {
  font-size: 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
