import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicAuthComponent } from '../helper/basic-auth/basic-auth.component';
import { MatDialog } from '@angular/material/dialog';

//Guard for temporary code
@Injectable({
  providedIn: 'root',
})
export class BasicAuthGuard  {
  constructor(private dialog: MatDialog) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.domain === 'prod') {
      return true;
    }

    const basicAuthToken = localStorage.getItem('auth');
    if (!basicAuthToken) {
      // trigger auth prompt
      await firstValueFrom(this.basicAuthPrompt().afterClosed());
      return this.canActivate(route, state);
    }

    if (basicAuthToken !== 'sv2022!') {
      return false;
    }

    return true;
  }

  basicAuthPrompt() {
    return this.dialog.open(BasicAuthComponent, {
      disableClose: true,
      panelClass: 'basic-auth-dialog',
    });
  }
}
