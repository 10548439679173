import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FeatureLockModalComponent } from '../feature-lock-modal/feature-lock-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-feature-lock-clicker',
  templateUrl: './feature-lock-clicker.component.html',
  styleUrls: ['./feature-lock-clicker.component.scss'],
})
export class FeatureLockClickerComponent implements OnInit {
  featureName;
  featureMessage;
  openFeatureLockDialog;

  @Output() dialogClosed = new EventEmitter();
  @Output() clicked = new EventEmitter();

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {}

  onClick(event: Event) {
    this.clicked.emit();

    event.stopPropagation();
    event.preventDefault();

    if (this.openFeatureLockDialog) {
      const lockDialog = this.dialog.open(FeatureLockModalComponent, {
        panelClass: 'feature-lock-dialog',
        data: {
          featureName: this.featureName,
          message: this.featureMessage
        }
      });

      lockDialog.afterClosed().subscribe((result) => {
        this.dialogClosed.emit(result);
      });
    }
  }
}
