/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MoreInfoComponent } from 'src/app/helper/more-info/more-info.component';
import { ProfilePictureComponent } from 'src/app/shared/image-upload-wrapper/profile-picture/profile-picture.component';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PhotoService } from 'src/app/services/photo.service';
import { firstValueFrom, Subscription, timer } from 'rxjs';
// import { CroppedEvent } from 'ngx-photo-editor';
import { LocationService } from 'src/app/helper/location.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnectionPositionPair, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { ProfileUpdateService } from '../../services/profile-update.service';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ImageUploadWrapperComponent } from '../../shared/image-upload-wrapper/image-upload-wrapper.component';

@Component({
    selector: 'app-choose-profile-picture',
    templateUrl: './choose-profile-picture.component.html',
    styleUrls: ['./choose-profile-picture.component.scss'],
    standalone: true,
    imports: [
    CdkOverlayOrigin,
    ImageUploadWrapperComponent,
    FaIconComponent,
    MatButton,
    MatMiniFabButton
],
})
export class ChooseProfilePictureComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() isOnboard = true;
  @Input() boardMode = 'full';
  @Input() color = 'primary';

  newPhoto;
  base64: any;
  subscription: Subscription;
  imageUrl: any;
  profileId;

  isOpen = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  imgResult = '';

  file;
  uniqueId;

  // bio = new FormControl('');

  // publicInfoForm = this.fb.group({
  //   photo: [''],
  //   // fullName: [this.utilities.accountFullName, Validators.required],
  //   // location: [this.utilities.location, Validators.required],
  //   // bio: [this.utilities.bio, Validators.required],
  // });

  // photo: any;
  constructor(
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private fb: UntypedFormBuilder,
    private jwt: JWTTokenService,
    private account: AccountService,
    private profiles: ProfilesService,
    private utilities: UtilitiesService,
    private locationService: LocationService,
    private photoService: PhotoService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private imageCompress: NgxImageCompressService, // private service: NgxPhotoEditorService
    private profileUpdateService: ProfileUpdateService
  ) {}

  ngOnInit() {
    // if (!this.utilities.location) {
    //   this.getLocation();
    // }

    // Get existing profile Information
    // this.profiles
    //   .retrieveProfile(this.utilities.profileId)
    //   .then(async (data) => {
    //     console.log(data);

    //     const { _id: profile_id, image_url } = data;

    //     this.profileId = profile_id;
    //     this.imageUrl = image_url;
    //   });

    this.profileId = this.utilities.profileId;
    this.imageUrl = this.utilities.profileDetails.image_url
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  nextPage() {
    this.updateProfile();
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  openMoreInfo() {
    const dialogRef = this.dialog.open(MoreInfoComponent, {
      panelClass: 'custom-dialog',
      width: '300px',
      height: '270px',
      data: {
        icon: 'phone',
        message: `Provide your public information for your bio profile.`,
        secondaryMessage: `This will also become the default information for new profiles.`,
        submitButton: 'OK',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  goBack() {
    this.location.back();
  }

  async uploadProfilePic(profile_id = null) {
    //new code to fix image upload in chrome
    const res = await fetch(this.newPhoto.image);
    const ext = res.headers.get('Content-Type');
    const file = await res.blob();
    // you have raw data and content-type

    console.log(ext, file);

    // Get presigned image upload URL
    const presigned_response: any = await firstValueFrom(
      this.profiles.imageUploadUrl(this.profileId, {
        extension: ext,
        element: 'avatar',
        should_replace: true
      })
    );
    console.log(presigned_response);

    const {
      presigned_url_data: {
        url,
        fields: {
          AWSAccessKeyId,
          key,
          policy,
          signature,
          'x-amz-security-token': token,
        },
      },
      s3_location,
    } = presigned_response;

    // Format formdata
    const formData: FormData = new FormData();
    formData.append('AWSAccessKeyId', AWSAccessKeyId);
    formData.append('key', key);
    formData.append('policy', policy);
    formData.append('signature', signature);
    formData.append('x-amz-security-token', token);
    formData.append('file', file);

    // Upload formdata to presigned URL
    const upload_response: any = await firstValueFrom(
      this.profiles.imageUpload(url, formData)
    );

    // Return file index and extension
    return {
      index: s3_location,
      ext,
    };
  }

  async updateProfile() {
    // Basic Info
    // const { fullName, bio, location, links } = this.publicInfoForm.value;

    // let body = { location, full_name: fullName, description: bio };

    // Update Profile Pic
    if (this.newPhoto) {
      let upload_response = await this.uploadProfilePic();
      this.utilities.profile.image_url = this.newPhoto.sanitizedUrl;
      this.snackBar.open("Profile Picture Updated", "OK", {duration: 2000})

      // Notify about the profile picture update
      this.profileUpdateService.updateProfilePicture(this.newPhoto.sanitizedUrl);
    }


  }

  imageChanged(event) {
    if (event.image) {
      this.newPhoto = event;
    }
  }
}
