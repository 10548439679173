import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { api } from '../../config/apiUrls';

interface GetOnboardLinkResponse {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(private http: HttpClient) {}

  purchase(body) {
    return this.http.post<any>(api.stripePurchase, body);
  }

  createStripeAccount() {
    return this.http.post<any>(api.stripe, {}, { observe: 'response' });
  }

  getOnboardLink() {
    return this.http.get<GetOnboardLinkResponse>(api.stripeOnboardLink, { observe: 'response' });
  }
}
