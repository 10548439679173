import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'app-expand-reach',
    templateUrl: './expand-reach.component.html',
    styleUrls: ['./expand-reach.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatCheckbox,
        MatButton,
    ],
})
export class ExpandReachComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  expandInfoForm = this.fb.group({
    agencies: [false],
    brands: [false],
  });
  showMessage = false;
  constructor(
    private fb: UntypedFormBuilder,
    private utilities: UtilitiesService,
    private jwt: JWTTokenService,
    private account: AccountService
  ) {}

  ngOnInit() {
    this.retrieveInformation();

    this.expandInfoForm.valueChanges.subscribe((data) => {
      this.showMessage = true;
    });
  }

  nextPage() {
    this.save();
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  retrieveInformation() {
    console.log(this.utilities.accountDetails);
    const agencies =
      this.utilities.accountDetails.settings.expand_your_reach_agencies;
    const brands =
      this.utilities.accountDetails.settings.expand_your_reach_brands;

    this.expandInfoForm.patchValue(
      {
        agencies,
        brands,
      },
      { emitEvent: false }
    );
  }

  save() {
    const { agencies, brands } = this.expandInfoForm.value;

    const userId = this.jwt.getSub();

    console.log(agencies, brands);

    // const profileId = this.utilities.profileId;

    //update account

    this.account
      .updateAccount(userId, {
        settings: {
          expand_your_reach_agencies: agencies,
          expand_your_reach_brands: brands,
        },
      })
      .subscribe((data: any) => {
        console.log(data);
        this.utilities.accountDetails = data;
      });
  }
}
