/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../../config/apiUrls';
import { decamelizeKeys } from 'humps';

@Injectable({
  providedIn: 'root',
})
export class KickStartService {
  steps = ['', 'invite-code', 'select-organization', 'payment-method'];
  stepsRouting = {
    'invite-code': 'inviteCode',
    'select-organization': 'organization',
    'payment-method': 'paymentMethod',
  };
  constructor(private http: HttpClient, private router: Router) {}

  createKickStart(body) {
    return this.http.post<any>(api.kickStartPromos, decamelizeKeys(body));
  }

  verifyPurchase(sessionId) {
    return this.http.post<any>(api.stripePurchase + `?verify=true`, {
      session_id: sessionId,
    });
  }

  setKickStartValue(key: any, value: any) {
    let kickStart = localStorage.getItem('kickStart');
    if (kickStart) {
      const parsedKickStart = JSON.parse(kickStart) as any;
      localStorage.setItem(
        'kickStart',
        JSON.stringify({ ...parsedKickStart, [key]: value })
      );
    } else {
      localStorage.setItem('kickStart', JSON.stringify({}));
    }
  }

  getKickStart() {
    let kickStart = localStorage.getItem('kickStart');
    if (kickStart) {
      return JSON.parse(kickStart);
    } else {
      this.router.navigateByUrl('/kickstart-promo');
    }
  }

  goToLastStep(currentPath) {
    const kickStart = this.getKickStart();
    const currentStep = this.stepsRouting[currentPath];
    const previousStep = this.steps[this.steps.indexOf(currentPath) - 1];
    if (kickStart && typeof kickStart[currentStep] === 'undefined') {
      this.router.navigateByUrl(`/kickstart-promo/${previousStep}`);
    }
  }
}
