import { Injectable } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private geocoder: MapGeocoder) {}

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        },
        {
          enableHighAccuracy: false,
        }
      );
    });
  }

  async getLocation() {
    const pos = await this.getPosition();

    console.log(pos);

    console.log(`Position: ${pos.lng} ${pos.lat}`);

    const data = await this.geocoder
      .geocode({ location: { lat: pos.lat, lng: pos.lng } })
      .toPromise();

    console.log(data);

    const city = data.results
      .flatMap((cg) => cg.address_components)
      .find((c) => c.types.includes('locality')).short_name; //data.results.find(result=>result.address_components[1]);

    const zipCode = data.results
      .flatMap((cg) => cg.address_components)
      .find((c) => c.types.includes('postal_code')).short_name;

    const state = data.results
      .flatMap((cg) => cg.address_components)
      .find((c) => c.types.includes('administrative_area_level_1')).short_name;

    console.log(city, state, zipCode);
    return { city, state, zipCode };
  }

  getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  async checkLocationPermission(): Promise<boolean> {
    if ('geolocation' in navigator) {
      try {
        // Geolocation API is supported by the browser
        const position = await this.getCurrentPosition();
        console.log('Location permission granted!');
        console.log('Latitude:', position.coords.latitude);
        console.log('Longitude:', position.coords.longitude);
        return true;
      } catch (error) {
        // Geolocation is available, but the user has denied permission or it's unavailable
        console.error('Location permission denied or unavailable:', error);
        return false;
      }
    } else {
      // Geolocation API is not supported by the browser
      console.error('Geolocation is not supported by this browser.');
      return false;
    }
  }
}
