import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { PhoneCodeValidatorDirective } from '../../directives/phone-code-validator.directive';
import { AccountService } from '../../services/account.service';
import { AuthorizationService } from '../../services/authorization.service';
import { JWTTokenService } from '../../services/jwttoken.service';
import { ProfilesService } from '../../services/profiles.service';
import { UtilitiesService } from '../../services/utilities.service';
import { interval, takeUntil, timer, map, filter } from 'rxjs';
import { MoreInfoComponent } from 'src/app/helper/more-info/more-info.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageService } from 'src/app/services/page.service';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MatFormField, MatHint, MatError, MatLabel, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'app-guest-sms',
    templateUrl: './guest-sms.component.html',
    styleUrls: ['./guest-sms.component.scss'],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    NgxMatIntlTelInputComponent,
    MatHint,
    MatError,
    MatButton,
    MatLabel,
    MatInput,
    MatIcon,
    MatSuffix,
    NgClass
],
})
export class GuestSmsComponent implements OnInit {
  @Output() next = new EventEmitter();

  isMessageSent: boolean;
  isMessageClicked = false;
  isMessageVerified: boolean;
  isResendActive = false;
  resendTime;
  isResendSent = 0;

  isPrepopulated = false;

  // textVerificationForm: FormGroup = new FormGroup({
  //   phone: new FormControl(new MyTel('', '', '')),
  //   code: new FormControl(),
  // });

  textVerificationForm = this.fb.group(
    {
      // phone: [new MyTel('', '', '')],
      phone: [null],
      code: ['', Validators.required],
    },
    {
      asyncValidator: PhoneCodeValidatorDirective.createValidator(
        this.authorize,
        this.snackBar
      ),
    }
  );

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private jwt: JWTTokenService,
    private utilities: UtilitiesService,
    private account: AccountService,
    private authorize: AuthorizationService,
    private snackBar: MatSnackBar,
    private pageService: PageService,
  ) {}
  ngOnInit() {
    this.account.retrieveAccount(this.jwt.getSub()).subscribe((data) => {
      if (data.phone) {
        this.textVerificationForm.patchValue({ phone: data.phone });
        this.isPrepopulated = true;
      }
    });

    this.textVerificationForm.statusChanges.subscribe((data) => {
      console.log(data);
    });
  }

  updatePhone(phone) {
    const addDashes = (input) => {
      // const input = event.target as HTMLInputElement;

      let trimmed = input.replace(/\s+/g, '');

      if (trimmed.length > 12) {
        trimmed = trimmed.substr(0, 12);
      }

      trimmed = trimmed.replace(/-/g, '');

      let numbers = [];

      numbers.push(trimmed.substr(0, 3));
      if (trimmed.substr(3, 2) !== '') {
        numbers.push(trimmed.substr(3, 3));
      }
      if (trimmed.substr(6, 3) !== '') {
        numbers.push(trimmed.substr(6, 4));
      }

      return numbers.join('-');
    };

    console.log(phone);

    if (phone) {
      console.log(addDashes(phone));
      this.textVerificationForm.patchValue({
        phone: addDashes(phone),
      });
    }
  }

  openMoreInfo() {
    const dialogRef = this.dialog.open(MoreInfoComponent, {
      panelClass: 'custom-dialog',
      width: '300px',
      height: '300px',
      data: {
        icon: 'phone',
        message: `Confirm your phone number using verification code.`,
        secondaryMessage: `This number can be used to recover your account.`,
        submitButton: 'OK',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  async sendMessage() {
    this.isResendActive = false;

    //to reset existing verification code
    this.textVerificationForm.get('code').reset();
    if (this.isResendActive && this.isResendSent === 3) {
      this.isMessageClicked = true;
    } else {
      this.isMessageClicked = true;

      // const {
      //   phone: { area, exchange, subscriber },
      // } = this.textVerificationForm.value;
      // const phone = [area, exchange, subscriber].join('');
      const phone = this.textVerificationForm.value.phone.split('-').join('');
      this.authorize
        .sendPhoneNumberCode(phone)
        .subscribe(
          (data) => {
            console.log(data);
            this.snackBar.open(
              `SMS Verification code ${this.isResendActive ? 're' : ''}sent!`,
              'OK',
              {
                duration: 3000,
              }
            );

            this.isMessageSent = true;
            // if (this.isResendActive) {
            //   this.isResendSent = this.isResendSent + 1;
            // }
            this.isResendSent = this.isResendSent + 1;
            console.log(this.isResendSent);
            interval(1000)
              .pipe(
                takeUntil(timer(62000)),
                map((x) => {
                  // console.log(x);
                  this.resendTime = 60 - x;
                  return x;
                }),
                filter((x) => x === 60),
                map((x) => {
                  this.resendTime = undefined;
                  this.isMessageClicked = false;
                  this.isResendActive = true;
                })
              )
              .subscribe();
          },
          (error) => {
            console.log(error);
            this.snackBar.open(
              'Please check the number that you entered!',
              'OK',
              { duration: 5000 }
            );
          }
        );
    }
  }

  sendVerification() {
    this.isMessageVerified = true;
  }

  nextPage() {
    this.next.emit();
  }
}
