import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-thanks',
    templateUrl: './thanks.component.html',
    styleUrls: ['./thanks.component.scss'],
    standalone: true,
})
export class ThanksComponent implements OnInit {
  @Output() next = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  nextPage() {
    this.next.emit({
      skip_account_update: true
    });
  }

  // prevPage() {
  //   this.prev.emit();
  // }

  // closeDialog() {
  //   this.close.emit();
  // }
}
