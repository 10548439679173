import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { api } from '../../config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public notifications = new BehaviorSubject<any>([]);

  constructor(private http: HttpClient) {}

  getNotificationChanges(): Observable<any> {
    return this.notifications.asObservable();
  }

  refreshNotifications() {
    this.listNotifications().subscribe((data) => {
      this.notifications.next(data);
    });
  }

  clearAllNotifications() {
    this.deleteAllNotifications().subscribe((data) => {
      this.refreshNotifications();
    });
  }

  clearNotification(notificationId) {
    this.deleteNotification(notificationId).subscribe((data) => {
      this.refreshNotifications();
    });
  }

  listNotifications() {
    return this.http.get<any>(`${api.accountNotification}`);
  }

  deleteAllNotifications() {
    return this.http.delete(`${api.accountNotification}`);
  }

  deleteNotification(notificationId) {
    return this.http.delete(`${api.accountNotification}/${notificationId}`);
  }

  updateNotification(notificationId, body) {
    return this.http.patch(
      `${api.accountNotification}/${notificationId}`,
      body
    );
  }

  getNotificationConfigs() {
    return this.http.get<any>(`${api.notificationConfigs}`)
  }
}
