import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'app-basic-auth',
    templateUrl: './basic-auth.component.html',
    styleUrls: ['./basic-auth.component.scss'],
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        MatInput,
        FormsModule,
        MatButton,
    ],
})
export class BasicAuthComponent implements OnInit {
  password = '';

  constructor(
    public dialogRef: MatDialogRef<BasicAuthComponent>,
  ) { }

  ngOnInit() {}

  onSubmit() {
    localStorage.setItem("auth", this.password)
    this.dialogRef.close()
  }

}
