import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ScriptLoaderService } from 'src/app/services/script-loader.service';
import { MatButton } from '@angular/material/button';

declare var jwplayer: any;

@Component({
    selector: 'app-jwplayer-video',
    templateUrl: './jwplayer-video.component.html',
    styleUrls: ['./jwplayer-video.component.scss'],
    standalone: true,
    imports: [MatButton],
})
export class JwplayerVideoComponent  implements OnInit {
  @ViewChild('videoContainer') videoContainer: ElementRef;

  @Input() video: any;
  @Input() canSkip = false;
  @Input() repeat = true;
  @Input() mute = true;
  @Input() controls = false;
  @Input() autoStart = true;

  @Output() videoFinished = new EventEmitter()

  loadingExternalScript = false;
  player: any;

  timeToSkip = 7.0;
  elapsedTime = 0;
  videoEnded = false;
  canContinue = false;

  constructor(
    private scriptLoader: ScriptLoaderService,
    private renderer: Renderer2,  
  ) { }

  ngOnInit() {
    this.loadExternalScript();
  }

  loadExternalScript() {
    const scriptUrl = 'https://cdn.jwplayer.com/libraries/ZqbwLAnR.js';
    this.scriptLoader
      .loadScript(scriptUrl)
      .then(() => {
        // Script is loaded, you can use its functionality now
        this.loadingExternalScript = false

        if (this.video.url) {
          setTimeout(() => {
            this.playVideo(this.video.url);
          }, 100);
        }
      })
      .catch((error) => {
        console.error('Error loading script:', error);
      }).finally(() => {
        this.loadingExternalScript = false
      })
  }

  playVideo(url) {
    if (!this.loadingExternalScript) {
      const aspectRatio = (
        this.video.options.aspect_ratio?.split(':') || ['1', '1']
      ).reverse().reduce((accumulator, currentValue) => {
          return currentValue / accumulator
        },
        1
      )

      console.log(aspectRatio)
      
      this.renderer.setStyle(this.videoContainer.nativeElement, 'aspect-ratio', aspectRatio)
      this.renderer.addClass(this.videoContainer.nativeElement, (
        this.video.options.aspect_ratio === "16:9" ? 'landscape' : 
        this.video.options.aspect_ratio === "9:16" ? 'portrait' : 
        'square'
      ))

      this.player = jwplayer(`jw-player-${this.video._id}`).setup({
        file: url,
        width: '100%',
        aspectratio: this.video.options.aspect_ratio || '16:9',
        autostart: this.autoStart,
        controls: this.controls,
        repeat: this.repeat,
        mute: this.mute,
      });

      this.player.on('time', (event) => {
        this.elapsedTime = event.position;
        this.timeToSkip = Math.ceil(7.0 - this.elapsedTime);

        if (event.position >= event.duration) {
          this.videoEnded = true;
          this.canContinue = true;
        }
      });
    }
  }

  skipVideo(viewTime = null) {
    this.videoFinished.emit(viewTime)
  }
}
