import { Directive } from '@angular/core';
import {
  AsyncValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization.service';
import { UtilitiesService } from '../services/utilities.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
    selector: '[appPhoneCodeValidator]',
    standalone: true,
})
export class PhoneCodeValidatorDirective {
  static createValidator(
    authorize: AuthorizationService,
    snackBar: MatSnackBar
  ): AsyncValidatorFn {
    let retry = 0;
    return (control: UntypedFormControl): Observable<ValidationErrors> =>
      authorize
        .verifyPhoneNumberCode(
          control.get('phone').value,
          control.get('code').value,
        )
        .pipe(
          map((result: any) => {
            console.log(result);
            const snackBarRef = snackBar.open(result.message, 'OK', {
              duration: 3000,
            });
            return result ? null : { smsError: true, retry };
          }),
          catchError((err, caught) => {
            console.log({ retry });
            if (retry >= 3) {
              const snackBarRef = snackBar.open(
                'Code Verification failed 3 times. Please contact support!',
                'OK'
              );
            } else {
              const snackBarRef = snackBar.open(
                'Code Verification failed',
                'OK',
                {
                  duration: 5000,
                }
              );
            }
            retry = retry + 1;
            return of({ smsError: true, retry });
          })
        );
  }
}
