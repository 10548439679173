/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, of } from 'rxjs';
import { JWTTokenService } from './jwttoken.service';

import { api } from '../../config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient, private jwt: JWTTokenService) {}

  listAccounts(params = {}) {
    return this.http.get<any>(api.accounts, {params});
  }

  retrieveAccount(userId, params = {}) {
    const currentMinimizedFields = params['minimized_fields'] ? params['minimized_fields'].split(",") : []
    params['minimized_fields'] = [
      ...currentMinimizedFields,
      'total_amount_collected',
    ].join(",");

    return this.http.get<any>(`${api.accounts}/${userId}`, {params});
  }

  updateAccount(userId, body, params = {}) {
    const currentMinimizedFields = params['minimized_fields'] ? params['minimized_fields'].split(",") : []
    params['minimized_fields'] = [
      ...currentMinimizedFields,
      'total_amount_collected',
    ].join(",");

    return this.http.patch(`${api.accounts}/${userId}`, body, {params});
  }

  async checkEmail(email) {
    const body = { email };
    // Email uniqueness is no longer checked. Now uses the hunter.io email validator route.
    const res = await firstValueFrom(
      this.http.post(api.emailValidate, body).pipe(catchError((err, caught) => of({ error: true })))
    );
    return res
  }

  getResetUrl(userId) {
    return this.http.get<any>(`${api.accounts}/${userId}/password/reset`);
  }

  listAccountLogs(filters = {}) {
    // Initialize Params Object
    let params = new HttpParams();

    // Assign parameters
    for (const key in filters) {
      if (key) {
        params = params.append(key, filters[key]);
      }
    }

    return this.http.get<any>(`${api.accounts}/log`, { params });
  }

  listAccountLogById(id?: string, filters = {}) {
    // Use the provided id or get it from the JWT token
    const userId = id || this.jwt.getSub();

    // Initialize Params Object
    let params = new HttpParams();

    // Assign parameters
    for (const key in filters) {
      if (key) {
        params = params.append(key, filters[key]);
      }
    }

    return this.http.get<any>(`${api.accounts}/${userId}/log`, { params });
  }

  createAccountSubscription(profileId, body) {
    return this.http.post(`${api.profiles}/${profileId}/subscriptions`, body);
  }

  listAccountSubscriptions(profileId=null, options={}) {
    // Initialize Params Object
    // let params = new HttpParams();

    // // Assign parameters
    // for (const key in filters) {
    //   if (key) {
    //     params = params.append(key, filters[key]);
    //   }
    // }

    if (profileId) {
      return this.http.get(`${api.profiles}/${profileId}/subscriptions`, options);
    } else {
      return this.http.get(api.subscriptions, options);
    }
  }

  createAccountInquiry(profileId, body) {
    return this.http.post(`${api.profiles}/${profileId}/inquiries`, body);
  }

  listAccountInquiries(profileId=null, options={}) {
    // // Initialize Params Object
    // let params = new HttpParams();

    // // Assign parameters
    // for (const key in filters) {
    //   if (key) {
    //     params = params.append(key, filters[key]);
    //   }
    // }

    if (profileId) {
      return this.http.get(`${api.profiles}/${profileId}/inquiries`, options);
    } else {
      return this.http.get(api.inquiries, options);
    }
  }

  async downloadBusinessInquiriesToCSV(pageId: string) {
    const options: Object = {
      params: { origin_page_id: pageId, exclude_id_column: true },
      headers: {
        'x-response-format': 'csv',
      },
      responseType: 'text',
    };

    const response = await firstValueFrom(
      this.http.get(api.inquiries, options)
    );

    if (response && typeof response === 'string') {
      const formatHeader = response.slice(0, response.indexOf(',') + 1);
      const data = response.slice(response.indexOf(',') + 1);
      const uri = formatHeader + encodeURIComponent(data);

      var link = document.createElement('a');

      link.href = uri;
      link.download = 'SV Business Inquiries';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  listAccountVisitorReports(profileId=null, options={}) {
    // // Initialize Params Object
    // let params = new HttpParams();

    // // Assign parameters
    // for (const key in filters) {
    //   if (key) {
    //     params = params.append(key, filters[key]);
    //   }
    // }

    if (profileId) {
      return this.http.get(`${api.profiles}/${profileId}/visitor-reports`, options);
    } else {
      return this.http.get(api.visitorReports, options);
    }
  }
}
