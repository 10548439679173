import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ProductsService } from 'src/app/services/products.service';
import { StripeService } from 'src/app/services/stripe.service';
import { TicketService } from 'src/app/services/ticket.service';
import { api } from 'src/config/apiUrls';
import { MatButton } from '@angular/material/button';
import { FeaturesTableComponent } from '../../../account/features-table/features-table.component';
import { NgClass, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-upgrade-prompt',
    templateUrl: './upgrade-prompt.component.html',
    styleUrls: ['./upgrade-prompt.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    FeaturesTableComponent,
    MatButton,
    DecimalPipe
],
})
export class UpgradePromptComponent  implements OnInit {
  hasCreatorPlus = false;
  isDeclined = false;
  allFeatures;
  product;
  processedProduct;
  isLoading = true;
  
  // trail;
  constructor(
    public dialogRef: MatDialogRef<UpgradePromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private products: ProductsService,
    private stripe: StripeService,
  ) {}

  /**
   * Process a product object from the api to include a full features list and price text information.
   * @param product a single product object as returned by the api
   * @returns a product object as needed by this component
   */
  _mapProduct(product, priceKey) {
    // Feature Details
    const allFeatures = [];
    for (const enabledFeature of product.enabled_features) {
      enabledFeature.hasAccess = true;
      allFeatures.push(enabledFeature);
    }
    for (const disabledFeature of product.disabled_features) {
      disabledFeature.hasAccess = false;
      allFeatures.push(disabledFeature);
    }

    // Price Details
    let prices = (product?.prices || [])
    for (const price of prices) {
      if (price.type === 'recurring') {
        price.recurring_text = `${
          price.recurring.interval_count > 1 ? price.recurring.interval_count + ' ' : ''
        }${
          price.recurring.interval_count > 1 ? price.recurring.interval + 's' : price.recurring.interval
        }`;
      }
      price.metadata.trial_period_days = price.metadata.trial_period_days ? parseInt(price.metadata.trial_period_days, 10) : null;
    }

    const defaultPrices = prices.filter((productPrice) => productPrice.is_default)
    const defaultPrice = defaultPrices?.shift()

    if (defaultPrice?.type === 'recurring') {
      defaultPrice.recurring_text = `${
        defaultPrice.recurring.interval_count > 1 ? defaultPrice.recurring.interval_count + ' ' : ''
      }${
        defaultPrice.recurring.interval_count > 1 ? defaultPrice.recurring.interval + 's' : defaultPrice.recurring.interval
      }`;
    }

    // Filter prices
    const price = prices.find((price) => price.lookup_key === priceKey)

    // Format response object
    return {
      id: product._id,
      name: product.title,
      type: product.type,
      default_price: defaultPrice,
      identifier: product.identifier,
      showMore: false,
      isActive: product.id,
      info: product.description,
      features: allFeatures,
      showcaseItems: product.showcase_items.map((i) => ({
        ...i,
        enabled: true,
        hasAccess: true,
      })),
      price: price,
    };
  }

  ngOnInit() {
    this.loadPlan()
  }

  activatePlan() {
    const price = this.product.prices.find((item) =>
      item.lookup_key === (
        this.isDeclined
        ? this.data.secondaryPriceId
        : this.data.priceId
      )
    );
    const {
      id: priceId,
      metadata: { checkout_mode: mode },
      unit_amount: planCost,
    } = price;
    const { _id: productId } = this.product;
    const body = {
      items: [{ price: priceId, quantity: 1 }],
      mode,
      product_id: productId,
      success_url: `${api.stripeCallback}/payment/confirmation?status=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${api.stripeCallback}/payment/confirmation?status=failed`,
    };

    this.stripe.purchase(body).subscribe((data) => {
      window.open(data.redirect_url, '_self');
    });

    console.log(body);
  }

  async loadPlan() {
    if (!this.product) {
      const data = await firstValueFrom(this.products.listProducts({identifier: this.data.productId}))
      this.product = data.results[0];
    }

    this.processedProduct = this._mapProduct(this.product, this.isDeclined ? this.data.secondaryPriceId : this.data.priceId)
    console.log(this.processedProduct)

    this.isLoading = false;
  }

  decline() {
    if (this.data.secondaryPriceId && !this.isDeclined) {
      this.isLoading = true;
      this.isDeclined = true;
      this.loadPlan()
    } else {
      this.close()
    }
  }

  // loadFeatures() {
  //   // const product = this.isDeclined ? this.trail : this.product;
  //   // Features
  //   const allFeatures = [];
  //   for (const enabledFeature of this.product.enabled_features) {
  //     enabledFeature.hasAccess = true;
  //     allFeatures.push(enabledFeature);
  //   }
  //   for (const disabledFeature of this.product.disabled_features) {
  //     disabledFeature.hasAccess = false;
  //     allFeatures.push(disabledFeature);
  //   }
  //   this.allFeatures = allFeatures;

  //   //TODO adding this logic as these are not coming from API, check with team why showcase doesn't have these fields
  //   this.product.showcase_items = this.product.showcase_items.map((item) => ({
  //     ...item,
  //     hasAccess: true,
  //     enabled: true,
  //   }));
  // }

  close() {
    this.dialogRef.close()
  }
}
