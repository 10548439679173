// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font {
  font-family: "Poppins", sans-serif;
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px); /* Adjust the blur radius as needed */
  background-color: rgba(255, 255, 255, 0.5); /* Adjust the background color and opacity as needed */
  z-index: 10;
}

.min-w-28 {
  min-width: 7rem !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
