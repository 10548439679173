/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { GuestPricingDialogComponent } from '../guest-pricing-dialog/guest-pricing-dialog.component';
import { api } from '../../../config/apiUrls';
import { StripeService } from 'src/app/services/stripe.service';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ProductsService } from 'src/app/services/products.service';
import { ExternalService } from 'src/app/services/external.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-free-trial',
    templateUrl: './free-trial.component.html',
    styleUrls: ['./free-trial.component.scss'],
    standalone: true,
    imports: [FaIconComponent],
})
export class FreeTrialComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() later = new EventEmitter();

  isMonthly = false;
  products;
  product;
  annualPlan;
  monthlyPlan;

  loading = true;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productsService: ProductsService,
    private stripe: StripeService,
    private router: Router,
    private external: ExternalService,
  ) {}

  async ngOnInit() {
    console.log(this.data);

    await this.loadProducts();

    this.product = this.products.find((product) => product.identifier === 'plus');
    console.log(this.product);
    this.annualPlan = this.product?.prices.find(
      (price) => price.lookup_key === 'plus_annual'
    );
    this.monthlyPlan = this.product?.prices.find(
      (price) => price.lookup_key === 'plus_monthly'
    );

    this.loading = false;
  }

  async loadProducts() {
    const { results: products } = await firstValueFrom(this.productsService.listProducts());
    console.log(products);
    this.products = products;
  }

  openDialog() {
    const dialogRef = this.dialog.open(GuestPricingDialogComponent, {
      panelClass: 'onboard-sub-dialog-guestFlow',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.nextPage();
    });
  }

  activatePlan(plan) {
    console.log(plan);
    const currentPlan = plan === 'monthly' ? this.monthlyPlan : this.annualPlan;
    // if (plan === 'monthly') {
    //   console.log(this.monthlyPlan);
    // } else {
    //   console.log(this.annualPlan);
    // }
    const { id: priceId, metadata, unit_amount: planCost } = currentPlan;
    console.log(currentPlan);
    const planName = this.product.title;
    const body = {
      items: [{ price: priceId, quantity: 1 }],
      mode: metadata.checkout_mode,
      product_id: this.product._id,
      success_url: `${api.stripeCallback}/payment/confirmation?status=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${api.stripeCallback}/payment/confirmation?status=failed`,
    };
    console.log(body);

    this.stripe.purchase(body).subscribe((data) => {
      console.log(data);
      // localStorage.setItem('signinRedirect', this.router.url);
      // window.open(data.redirect_url, '_self');
      this.next.emit({
        external_step: data.redirect_url,
        wait_for_update: true,
      });
    });
  }

  nextPage() {
    this.next.emit();
  }

  laterPage() {
    this.later.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  navigateToRoute() {
    // this.router.navigate(['https://socialvalid.com/home/pricing']);
    const url = 'https://socialvalid.com/home/pricing'; // Replace with the URL you want to open
    const target = '_blank';

    this.external.openNewTab(url, target);
  }
}
