/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { api } from '../../config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class ThemesService {
  constructor(private http: HttpClient) {}

  listElements(options) {
    const params = {
      ...(options?.sort && { order_by: options.sort }),
      ...(options?.filters &&
        options.filters.reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item.split('=')[0]]: item.split('=')[1],
            }),
          {}
        )),
    };
    console.log({ params });
    return this.http.get<any>(`${api.elements}`, {
      params,
    });
  }

  // retrieveStyle(body) {
  //   return this.http.get<any>(`${api.profiles}?is_bio=true`);
  // }

  listThemes(params={}) {
    // Default Params
    if (!params["limit"]) {
      params["limit"] = "50"
    }
    if (!params["order_by"]) {
      params["order_by"] = "default"
    }

    console.log({ params });
    return this.http.get<any>(`${api.themes}`, {
      params,
    });
  }

  createTheme(body) {
    return this.http.post<any>(api.themes, body);
  }

  updateTheme(id, body) {
    return this.http.patch(`${api.themes}/${id}`, body);
  }

  previewTheme(body) {
    return this.http.post<any>(api.themePreview, body);
  }

  deleteTheme(id) {
    return this.http.delete<any>(`${api.themes}/${id}`);
  }

  retrieveTheme(id, params={}) {
    return this.http.get<any>(`${api.themes}/${id}`, {params});
  }
}
