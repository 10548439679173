import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
// import { Filesystem, Directory } from '@capacitor/filesystem';
// import { Storage } from '@capacitor/storage';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Observable, Subject, firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  public photos: any = {};

  constructor(
    private imageCompress: NgxImageCompressService,
    private snackBar: MatSnackBar
  ) {}

  public async addNewToGallery(isCamera, uniqueId = false) {
    console.log({ isCamera });
    //to fix bug on mobile safari not opening gallery for first time
    if (isCamera === undefined) {
      try {
        await Camera.requestPermissions();
      } catch (e) {
        console.log(e);
      }
    } else {
      // Take a photo
      let file;
      let webviewPath;
      if (isCamera) {
        const capturedPhoto = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Camera,
          quality: 100,
        });
        webviewPath = capturedPhoto.webPath;
      } else {
        file = await this.selectFile();
      }

      // Update photo
      this.getUniquePhoto(uniqueId).next({
        file,
        webviewPath,
      });
    }
  }

  getUniquePhoto(id): Subject<Photo> {
    if (!this.photos[id]) {
      this.photos[id] = new Subject<Photo>();
    }
    return this.photos[id];
  }

  getPhoto(id = null) {
    // Including the id in the response to resolve
    // issues with multiple image uploads on one page.

    const photo = this.getUniquePhoto(id).asObservable();

    return photo.pipe(
      map((photo: Photo) => {
        return {
          photo,
          id,
        };
      })
    );
  }

  compressFile(base, size = 100) {
    const MAX_MEGABYTE = 9;
    // console.log(size);
    this.imageCompress
      .compressFile(base, -1, size, size, 1024, 1024)
      .then((result: string) => {
        console.log(result);
        console.log(result.length);

        if (result.length > 9000000) {
          this.compressFile(result, 50);
        } else {
          // return result;
          // this.imgResult = result;
          // this.newPhoto = result;
          // this.imageUrl = this.sanitize(this.imgResult);
        }
      })
      .catch((e) => {
        console.log(e);
        this.snackBar.open(
          'Please select a smaller size image or crop your image smaller.',
          'Ok',
          {
            duration: 5000,
          }
        );
        // return undefined;
      });
  }

  selectFile() {
    return new Promise<File | null>((resolve, reject) => {
      const input = document.createElement('input');
      input.type = 'file';
      // input.accept = 'image/png,image/jpg'; // Optionally restrict to image files
      // input.accept = 'image/*'; // Allow all image formats
      input.accept = 'image/png,image/jpeg'; // Restrict to PNG and JPEG
      input.hidden = true;
      input.onchange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        const files = target.files;
        if (!files || files.length === 0) {
          resolve(null); // No file selected
          return;
        }
        const file = files[0];
        resolve(file);
      };
      document.body.appendChild(input);
      input.click();
      // Clean up the input element after selection
      // input.onchange = null;
      // document.body.removeChild(input);
    });
  }
}

export interface Photo {
  file: File;
  webviewPath: string;
}
