import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../../config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(private http: HttpClient) {}

  createTicket(body) {
    return this.http.post<any>(api.tickets, body);
  }
}
