import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-oauth-landing-page',
  templateUrl: './oauth-landing-page.component.html',
  styleUrls: ['./oauth-landing-page.component.scss'],
})
export class OauthLandingPageComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const provider = this.route.snapshot.paramMap.get('provider');
    const success = this.route.snapshot.queryParamMap.get('success');
    const linkAdded = this.route.snapshot.queryParamMap.get('link_added');

    localStorage.setItem('oauthProvider', provider);
    localStorage.setItem('oauthSuccess', success);
    localStorage.setItem('oauthLinkAdded', linkAdded);

    this.router.navigateByUrl('/profiles')
  }
}
