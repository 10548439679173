import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from 'src/config/apiUrls';

declare var flextrack: any;

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private http: HttpClient) {}

  listOrders(filters = {}) {
    // Initialize Params Object
    let params = new HttpParams();
    filters = { ...filters, status: 'paid' }; // Always filter to paid status

    // Assign parameters
    for (let key in filters) {
      params = params.append(key, filters[key]);
    }

    return this.http.get<any>(api.orders, { params });
  }

  createOrder(body) {
    return this.http.post<any>(api.orders, body);
  }

  retrieveOrder(orderId) {
    return this.http.get<any>(`${api.orders}/${orderId}`);
  }

  updateOrder(orderId, body) {
    return this.http.patch(`${api.orders}/${orderId}`, body);
    //  .pipe(catchError(this.handleError('patchHero')));
  }

  deleteOrder(orderId) {
    return this.http.delete(`${api.orders}/${orderId}`);
  }

  cancelOrder(orderId, atPeriodEnd: boolean) {
    return this.http.post<any>(`${api.orders}/${orderId}/cancel-subscription`, {
      at_period_end: atPeriodEnd,
    });
    // return this.http.post<any>(`${api.orders}/${orderId}/cancel-subscription`, { "at_period_end": atPeriodEnd })
  }

  trackOrder(order) {
    console.log(order);
    let discountAmount;
    let discountPercent;
    let couponId;
    if (order.transaction_info?.subscription) {
      discountPercent =
        order.transaction_info?.subscription?.discount?.coupon?.percent_off ||
        0;
      discountAmount =
        order.transaction_info?.subscription?.discount?.coupon?.amount_off || 0;
      couponId = order.transaction_info?.subscription?.discount?.coupon?.id;
    } else if (order.transaction_info?.payment) {
      discountPercent =
        order.transaction_info?.payment?.discount?.coupon?.percent_off || 0;
      discountAmount =
        order.transaction_info?.payment?.discount?.coupon?.amount_off || 0;
      couponId = order.transaction_info?.payment?.discount?.coupon?.id;
    }

    let donationPercent = 0;
    if (localStorage.getItem('kickStart')) {
      donationPercent = 10;
    } else {
      donationPercent = order.transaction_info?.donation?.percent;
    }

    const cost = order.transaction_info?.price?.unit_amount || 0;
    const totalPaid = (cost - discountAmount) * ((100 - discountPercent) / 100);
    const totalPaidAfterDonation = Math.floor(
      totalPaid * ((100 - donationPercent) / 100)
    );

    console.log('TOTAL PAID');
    console.log(totalPaidAfterDonation);

    const res = flextrack.track({
      order_number: order._id,
      order_amount: totalPaidAfterDonation / 100,
      order_coupon: couponId,
      order_currency: order.transaction_info?.price?.currency?.toUpperCase(),
      order_geo: null,
      order_items: [
        {
          sku: order.product?._id,
          amount: totalPaidAfterDonation / 100,
          commissionid: null,
          quantity: 1,
          order_coupon: couponId,
        },
      ],
    });
    console.log(res);
  }
}
