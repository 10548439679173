/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { BadgeService } from 'src/app/services/badge.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { BadgeInfoComponent } from '../../shared/badges/badge-information/badge-info.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-badges-verification',
    templateUrl: './badges-verification.component.html',
    styleUrls: ['./badges-verification.component.scss'],
    standalone: true,
    imports: [
    FaIconComponent,
    BadgeInfoComponent,
    MatButton
],
})
export class BadgesVerificationComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  badges = [];
  selectedBadge: any;
  badgeIndex: number;
  isLoading = true;
  isPaidUser = false;

  @Input() showSkip = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private jwt: JWTTokenService,
    private account: AccountService,
    private badge: BadgeService,
    private utilities: UtilitiesService,

    private sanitizer: DomSanitizer // private route: ActivatedRoute
  ) {}

  ngOnInit() {
    console.log(this.utilities.profile);
    const features = this.utilities.featureList;
    console.log(features);
    this.isPaidUser = features['premium-usernames'];
    this.getBadges();
  }

  // getImageHost() {
  //   return environment.host + '/static/images/'
  // }

  getImageHost(url) {
    // return this.sanitization.bypassSecurityTrustStyle(
    //   `url(${environment.host + '/static/images/' + url})`
    // );
    return this.sanitizer.bypassSecurityTrustUrl(
      `${environment.host}/static/images/${url}`
    ); //environment.host + '/static/images/' + url;
  }

  getBadges() {
    this.isLoading = true;

    this.badge.listBadges().subscribe((data) => {
      console.log(data);
      this.badges = data.results;
      if (this.badges.length > 0) {
        // Set first badge as selected
        this.selectedBadge = this.badges[0]['_id'];
        this.badges = this.badges.map((badge) => ({
          ...badge,
          status: badge['active_for_profile']
            ? 'active'
            : badge['pending_for_profile']
            ? 'pending'
            : badge['prereqs_satisfied']
            ? 'prereq'
            : badge['available_for_profile']
            ? 'available'
            : undefined,
          action: badge['active_for_profile']
            ? 'Activated'
            : badge['pending_for_profile']
            ? 'Pending'
            : badge['available_for_profile'] && badge['prereqs_satisfied']
            ? 'Activate Badge'
            : !badge['prereqs_satisfied']
            ? badge['category'] === 'identity'
              ? 'Requires Social Badge'
              : 'Requires True Identity'
            : 'Available with PLUS',
        }));
        this.badgeIndex = 0;
      }
      console.log(this.badges);
      this.isLoading = false;
    });
  }

  selectIcon(id, index = null) {
    this.selectedBadge = id;
    if (!index) {
      this.badgeIndex = this.badges.findIndex(
        (badge) => badge._id === this.selectedBadge
      );
    } else {
      this.badgeIndex = index;
    }
  }

  nextPage() {
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  navigate(route) {
    switch (route) {
      case 'next': {
        if (this.badgeIndex === this.badges.length - 1) {
          // At the end of the badge list
          this.nextPage();
        } else {
          this.selectIcon(
            this.badges[this.badgeIndex + 1]._id,
            this.badgeIndex + 1
          );
        }
        break;
      }
      case 'back': {
        if (this.badgeIndex === 0) {
          // At the end of the badge list
          this.prevPage();
        } else {
          this.selectIcon(
            this.badges[this.badgeIndex - 1]._id,
            this.badgeIndex - 1
          );
        }
        break;
      }
      default: {
        this.router.navigateByUrl('/');
      }
    }
  }

  goBack() {
    this.location.back();
  }
}
