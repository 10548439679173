import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AccountService } from '../services/account.service';
import { JWTTokenService } from '../services/jwttoken.service';
import { UtilitiesService } from '../services/utilities.service';
import { ReferralService } from '../services/referral.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ProductsService } from '../services/products.service';
import { OrdersService } from '../services/orders.service';
import { ProfilesService } from '../services/profiles.service';
import { FreeTrialComponent } from './free-trial/free-trial.component';
import { VipVerifyComponent } from './vip-verify/vip-verify.component';
import { GuestUsernameComponent } from './guest-username/guest-username.component';
import { GuestPreviewComponent } from './guest-preview/guest-preview.component';
import { GuestOfferComponent } from './guest-offer/guest-offer.component';
import { GuestMoreComponent } from './guest-more/guest-more.component';
import { GuestDobComponent } from './guest-dob/guest-dob.component';
import { GuestCreatedComponent } from './guest-created/guest-created.component';
import { GuestPricingComponent } from './guest-pricing/guest-pricing.component';
import { GuestSmsComponent } from './guest-sms/guest-sms.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ThanksComponent } from './thanks/thanks.component';
import { VerifiedBadgesComponent } from './verified-badges/verified-badges.component';
import { VerifyIdentityComponent } from './verify-identity/verify-identity.component';
import { PricingWarmUpComponent } from './pricing-warm-up/pricing-warm-up.component';
import { OnboardPricingComponent } from './onboard-pricing/onboard-pricing.component';
import { GuestCompleteComponent } from './guest-complete/guest-complete.component';
import { AccountCreatedComponent } from './account-created/account-created.component';
import { BadgesVerificationComponent } from './badges-verification/badges-verification.component';
import { UsernameComponent } from './username/username.component';
import { PublicProfileInformationComponent } from './public-profile-information/public-profile-information.component';
import { ChooseProfilePictureComponent } from './public-picture/choose-profile-picture.component';
import { BlueRibbonComponent } from './blue-ribbon/blue-ribbon.component';
import { TextVerificationComponent } from './text-verification/text-verification.component';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { CreatorPricingComponent } from './creator-pricing/creator-pricing.component';
import { ExpandReachComponent } from './expand-reach/expand-reach.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PrivateAccountInformationComponent } from './private-account-information/private-account-information.component';
import { StartComponent } from './start/start.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgClass } from '@angular/common';

const onboardingFlows = {
  full: {
    color: 'april',
    'start-page': 'personal-information',
    welcome: {
      prev: null,
      next: 'personal-information',
      progress: 0,
    },
    'personal-information': {
      prev: null,
      next: 'private-account-information',
      progress: 10,
    },
    'private-account-information': {
      prev: 'personal-information',
      next: 'account-settings',
      progress: 20,
    },
    'account-settings': {
      prev: 'private-account-information',
      next: 'blue-ribbon', //'account-verification',
      progress: 30,
    },
    'blue-ribbon': {
      prev: 'account-settings',
      next: 'account-verification', // next: 'text-verification',
      progress: 40,
    },
    'free-trial': {
      prev: 'blue-ribbon',
      next: 'account-verification', // next: 'text-verification',
      progress: 40,
    },
    'guest-pricing': {
      prev: 'blue-ribbon',
      next: 'account-verification',
      later: 'account-verification',
      progress: 40,
    },
    'account-verification': {
      prev: 'account-settings',
      next: 'username', // next: 'text-verification',
      progress: 60,
    },
    // 'text-verification': {
    //   prev: 'account-verification',
    //   next: 'choose-profile-picture',
    //   progress: 50,
    // },

    // 'choose-profile-picture': {
    //   prev: 'account-verification', // prev: 'text-verification',
    //   next: 'public-profile-information',
    //   progress: 60,
    // },
    // 'public-profile-information': {
    //   prev: 'choose-profile-picture',
    //   next: 'username',
    //   progress: 70,
    // },
    username: {
      prev: 'account-verification',
      next: 'account-created',
      progress: 80,
    },
    // 'badges-verification': {
    //   prev: 'username',
    //   next: 'account-created',
    //   progress: 90,
    // },
    'account-created': {
      prev: 'username',
      next: null,
      progress: 100,
    },
  },
  creator: {
    color: 'april',
    'start-page': 'personal-information',
    welcome: {
      prev: null,
      next: 'personal-information',
      progress: 0,
    },
    'personal-information': {
      prev: null,
      next: 'private-account-information',
      progress: 10,
    },
    'private-account-information': {
      prev: 'personal-information',
      next: 'account-settings',
      progress: 20,
    },
    'account-settings': {
      prev: 'private-account-information',
      next: 'expand-reach', //'account-verification',
      progress: 30,
    },
    'expand-reach': {
      prev: 'account-settings',
      next: 'creator-pricing', // next: 'text-verification',
      progress: 40,
    },
    'creator-pricing': {
      prev: 'expand-reach',
      next: 'account-verification', // next: 'text-verification',
      progress: 50,
    },
    'account-verification': {
      prev: 'expand-reach',
      next: 'username', // next: 'text-verification',
      progress: 60,
    },
    // 'text-verification': {
    //   prev: 'account-verification',
    //   next: 'choose-profile-picture',
    //   progress: 50,
    // },
    username: {
      prev: 'account-verification',
      next: 'account-created', //'badges-verification',
      progress: 80,
    },
    'account-created': {
      prev: 'account-verification',
      next: null,
      progress: 100,
    },
  },
  // guest: {
  //   color: 'passport',
  //   'start-page': 'private-account-information',
  //   'private-account-information': {
  //     prev: null,
  //     next: 'account-verification',
  //     progress: 0,
  //   },
  //   'account-verification': {
  //     prev: 'private-account-information',
  //     next: 'guest-complete', // next: 'text-verification',
  //     progress: 33,
  //   },
  //   // 'text-verification': {
  //   //   prev: 'account-verification',
  //   //   next: 'guest-complete',
  //   //   progress: 66,
  //   // },
  //   'guest-complete': {
  //     prev: 'account-verification', // prev: 'text-verification',
  //     next: 'pricing-warm-up',
  //     progress: 100,
  //   },
  //   'pricing-warm-up': {
  //     prev: 'guest-complete',
  //     next: 'onboard-pricing',
  //     progress: 100,
  //   },
  //   'onboard-pricing': {
  //     prev: 'pricing-warm-up',
  //     next: 'username',
  //     progress: 100,
  //   },
  //   username: {
  //     prev: 'onboard-pricing',
  //     next: 'verify-identity',
  //     progress: 100,
  //   },
  //   'verify-identity': {
  //     prev: 'username',
  //     next: 'account-created',
  //     progress: 100,
  //   },
  //   'account-created': {
  //     prev: 'verify-identity',
  //     next: null,
  //     progress: 100,
  //   },
  // },
  // aprilLaunch: {
  //   color: 'april',
  //   'start-page': 'personal-information',
  //   'personal-information': {
  //     prev: null,
  //     next: 'account-verification',
  //     progress: 0,
  //   },
  //   'account-verification': {
  //     prev: 'personal-information',
  //     next: 'private-account-information',
  //     progress: 20,
  //   },
  //   'private-account-information': {
  //     prev: 'account-verification',
  //     next: 'account-settings',
  //     progress: 40,
  //   },
  //   'account-settings': {
  //     prev: 'private-account-information',
  //     next: 'username', // next: 'text-verification',
  //     progress: 60,
  //   },
  //   // 'text-verification': {
  //   //   prev: 'account-settings',
  //   //   next: 'username',
  //   //   progress: 80,
  //   // },
  //   username: {
  //     prev: 'account-settings', // prev: 'text-verification',
  //     next: 'april-launch-verify',
  //     progress: 100,
  //   },
  //   'april-launch-verify': {
  //     prev: 'username',
  //     next: null,
  //     progress: 100,
  //   },
  // },
  guestFlow: {
    color: 'april',
    'start-page': 'guest-dob',
    thanks: {
      prev: null,
      next: 'sign-in',
      progress: 0,
    },
    'sign-in': {
      prev: null,
      next: 'guest-dob',
      progress: 8,
    },
    'guest-dob': {
      prev: null,
      next: 'guest-created', // next: 'guest-sms',
      progress: 16,
    },
    // 'guest-sms': {
    //   prev: null,
    //   next: 'guest-created',
    //   progress: 40,
    // },
    'guest-created': {
      prev: null,
      next: 'guest-more',
      later: 'guest-preview',
      progress: 24,
    },
    'guest-more': {
      prev: null,
      next: 'guest-pricing',
      later: 'guest-preview',
      progress: 32,
    },
    'guest-pricing': {
      prev: 'guest-more',
      next: 'guest-preview',
      later: 'guest-preview',
      progress: 40,
    },
    'guest-preview': {
      prev: 'guest-pricing',
      next: 'personal-information',
      progress: 56,
    },
    'personal-information': {
      prev: 'guest-preview',
      next: 'private-account-information',
      progress: 64,
    },
    'private-account-information': {
      prev: 'personal-information',
      next: 'account-verification',
      progress: 72,
    },
    'account-verification': {
      prev: 'private-account-information',
      next: 'account-settings',
      progress: 80,
    },
    'account-settings': {
      prev: 'account-verification',
      next: 'username',
      progress: 88,
    },
    username: {
      prev: 'account-settings',
      next: null,
      progress: 100,
    },
  },
  vipInvite: {
    color: 'april',
    'start-page': 'personal-information',
    'personal-information': {
      prev: null,
      next: 'private-account-information',
      progress: 0,
    },
    'private-account-information': {
      prev: 'personal-information',
      next: 'account-settings',
      progress: 20,
    },
    'account-settings': {
      prev: 'private-account-information',
      next: 'account-verification',
      progress: 40,
    },
    'account-verification': {
      prev: 'account-settings',
      next: 'app-vip-verify', // next: 'text-verification',
      progress: 60,
    },
    // 'text-verification': {
    //   prev: 'account-verification',
    //   next: 'app-vip-verify',
    //   progress: 80,
    // },
    'app-vip-verify': {
      prev: 'account-verification', // prev: 'text-verification',
      next: null,
      progress: 100,
    },
  },
};

@Component({
    selector: 'app-onboard',
    templateUrl: './onboard.page.html',
    styleUrls: ['./onboard.page.scss'],
    standalone: true,
    imports: [
    MatProgressBar,
    NgClass,
    StartComponent,
    PrivateAccountInformationComponent,
    AccountSettingsComponent,
    ExpandReachComponent,
    CreatorPricingComponent,
    PersonalInformationComponent,
    AccountVerificationComponent,
    TextVerificationComponent,
    BlueRibbonComponent,
    ChooseProfilePictureComponent,
    PublicProfileInformationComponent,
    UsernameComponent,
    BadgesVerificationComponent,
    AccountCreatedComponent,
    GuestCompleteComponent,
    OnboardPricingComponent,
    PricingWarmUpComponent,
    VerifyIdentityComponent,
    VerifiedBadgesComponent,
    ThanksComponent,
    SignInComponent,
    GuestSmsComponent,
    GuestPricingComponent,
    GuestCreatedComponent,
    GuestDobComponent,
    GuestMoreComponent,
    GuestOfferComponent,
    GuestPreviewComponent,
    GuestUsernameComponent,
    VipVerifyComponent,
    FreeTrialComponent
],
})
export class OnboardPage implements OnInit {
  boardMode = 'full';
  color = 'primary';
  onboardStep = 'start';
  progress = 0;
  later = false;
  // isCreator = false;

  constructor(
    public dialogRef: MatDialogRef<OnboardPage>,
    private account: AccountService,
    private jwt: JWTTokenService,
    private router: Router,
    private $gaService: GoogleAnalyticsService,
    private utilities: UtilitiesService,
    // private referrals: ReferralService,
    // private orders: OrdersService,
    private productsService: ProductsService,
    private profileService: ProfilesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.orders.listOrders({}).subscribe((data) => {
    //   console.log(data);
    //   const isCreator = data.results.some(
    //     (item) => item.product.identifier === 'creator_plus'
    //   );
    //   if (isCreator) {
    //     this.boardMode = 'creator';
    //   } else {
    //     this.boardMode = this.data.mode;
    //   }
    //   // console.log(isCreator);
    //   // if (isCreator) {
    //   //   this.nextPage();
    //   // } else {
    //   //   //To load the plans for subscription
    //   //   this.upgrade();
    //   // }
    // });
  }

  async ngOnInit() {
    //To check if its free plan in order not to show the blue ribbon page in full onboarding
    // const { results: products } = await firstValueFrom(
    //   this.productsService.listProducts()
    // );
    // console.log(products);
    // const isFreePlan = products.find(
    //   (item) => item.identifier === 'standard'
    // ).is_active_for_bio;
    // console.log(isFreePlan);
    // if (!isFreePlan) {
    //   onboardingFlows.full['account-settings'].next = 'account-verification';
    //   onboardingFlows.full['account-verification'].prev = 'account-settings';
    // }
    //making code not to wait

    // Prioritize the onboarding mode stored on the account.
    this.boardMode = this.utilities.accountDetails.onboarding_mode;

    if (!this.boardMode) {
      // Moving the creator check here.
      // When it's in the constructor the onInit function can run before board mode is set.
      const isCreator =
        this.profileService.isFeatureEnabledForProfile('creator-portfolio');
      this.boardMode = isCreator ? 'creator' : this.data.mode;
    }

    if (!(this.boardMode in onboardingFlows)) {
      // Fallback case for if it's using an outdated onboarding flow.
      this.boardMode = 'full';
    }

    // Use firstValueFrom to get the observable and process asynchronously
    firstValueFrom(this.productsService.listProducts())
      .then(({ results: products }) => {
        console.log(products);

        // Determine if the 'Standard' plan is active for bio
        const isFreePlan = products.find(
          (item) => item.identifier === 'standard'
        )?.is_active_for_bio;
        console.log(isFreePlan);

        // Adjust the onboarding flows based on the plan status
        if (!isFreePlan) {
          onboardingFlows.full['account-settings'].next =
            'account-verification';
          onboardingFlows.full['account-verification'].prev =
            'account-settings';
        }
      })
      .catch((error) => {
        console.error('Error processing products:', error);
      });

    console.log({ boardMode: this.boardMode });
    this.color = onboardingFlows[this.boardMode].color;

    console.log(this.color);

    this.later = Boolean(localStorage.getItem('maybeLater'));
    console.log(this.later);

    if (!(this.data.nextStep in onboardingFlows[this.boardMode])) {
      this.data.nextStep = 'start';
    }

    if (this.data.nextStep === 'start') {
      // Find starting page for the current flow
      this.onboardStep = onboardingFlows[this.boardMode]['start-page'];
    } else {
      this.onboardStep = this.data.nextStep;
      console.log(this.onboardStep);
      // this.$gaService.event('app-onboard', this.onboardStep, this.onboardStep);
      this.progress =
        onboardingFlows[this.boardMode][this.onboardStep].progress;
    }

    this.$gaService.pageView('/onboard', this.onboardStep);
    console.log('Event logged');
  }

  nextStep(options: any = {}) {
    const external_step = options.external_step;
    const router_step = options.router_step;
    const wait_for_update = options.wait_for_update;
    const dialog_step = options.dialog_step;
    const skip_account_update = options.skip_account_update;
    const next_step = options.next_step;

    const next =
      next_step || onboardingFlows[this.boardMode][this.onboardStep].next;
    this.$gaService.pageView('/onboard', next);
    console.log('Event logged', next);
    const userId = this.jwt.getSub();
    const body = { onboarding_next_step: next };

    if (next === null) {
      this.closeDialog();

      if (!skip_account_update) {
        // Add log for referral if it exists in account
        // const referralId =
        //   this.utilities.accountDetails.referred_by_data?.referral_id;
        // if (referralId) {
        //   this.referrals
        //     .createReferralLog(referralId, {
        //       object_type: 'referral',
        //       event: 'complete',
        //       action: 'analytics',
        //     })
        //     .subscribe((data) => {
        //       console.log('REFERRAL LOG CREATE: ', data);
        //     });
        // }

        // const bioProfileId = this.utilities.profileId;
        const accountBody = {
          onboarding_completed: true,
          onboarding_next_step: '',
        };

        console.log(userId, { accountBody });
        this.account
          .updateAccount(userId, accountBody)
          .subscribe((data: any) => {
            // Make sure account in utilities is updated before navigating
            this.utilities.account = data;
          });
      }

      return;
    }

    if (dialog_step) {
      dialog_step.afterClosed().subscribe((result) => {
        console.log('Dialog step closed');
        this.onboardStep = next;
        this.progress =
          onboardingFlows[this.boardMode][this.onboardStep].progress;
      });
    } else if (wait_for_update) {
      this.account.updateAccount(userId, body).subscribe((data) => {
        console.log(data);

        if (external_step) {
          localStorage.setItem('signinRedirect', this.router.url);
          window.open(external_step, '_self');
          this.closeDialog();
        } else if (router_step) {
          this.router.navigateByUrl(router_step);
        } else {
          this.onboardStep = next;
          this.progress =
            onboardingFlows[this.boardMode][this.onboardStep].progress;
        }
      });
    } else {
      if (!skip_account_update) {
        this.account.updateAccount(userId, body).subscribe((data) => {
          console.log(data);
        });
      }
      if (external_step) {
        localStorage.setItem('signinRedirect', this.router.url);
        window.open(external_step, '_self');
        this.closeDialog();
      } else if (router_step) {
        this.router.navigateByUrl(router_step);
      } else {
        this.onboardStep = next;
        this.progress =
          onboardingFlows[this.boardMode][this.onboardStep].progress;
      }
    }
  }

  prevStep() {
    // console.log('Event started');

    const prev = onboardingFlows[this.boardMode][this.onboardStep].prev;

    if (prev === null) {
      return;
    }

    this.onboardStep = prev;
    this.progress = onboardingFlows[this.boardMode][this.onboardStep].progress;
    this.$gaService.pageView('/onboard', this.onboardStep);
    console.log('Event logged');
  }

  laterStep() {
    const later = onboardingFlows[this.boardMode][this.onboardStep].later;

    if (later === null) {
      return;
    }

    this.onboardStep = later;
    this.progress = onboardingFlows[this.boardMode][this.onboardStep].progress;
    this.$gaService.pageView('/onboard', this.onboardStep);
    console.log('Event logged');
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
