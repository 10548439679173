// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
  border-radius: 50% !important;
  font-size: 100px;
}

ion-img {
  border-radius: 50% !important;
  overflow: hidden;
}

img {
  object-fit: cover;
}

.bubbles::before,
.bubbles::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background-color: rgba(70, 58, 180, 0.5);
}

.bubbles::before {
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  animation: float-before 2s infinite linear;
}

.bubbles::after {
  width: 60px;
  height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  animation: float-after 3s infinite linear;
}

@keyframes float-before {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes float-after {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
