// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vip-font {
  font-family: "Bebas Neue", cursive;
  letter-spacing: 2px;
}

.title-text {
  font-size: 25px;
  font-weight: bold;
}

.face-scan {
  width: 200px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
