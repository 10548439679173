// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spotlight-preview-container {
  padding: 20px;
  border-radius: 20px;
}

.spotlight-image {
  width: 150px;
  border-radius: 20px;
  margin-bottom: 15px;
}

.spotlight-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin-bottom: 10px;
}

.spotlight-subtitle {
  font-size: 14px;
  font-weight: 300;
  line-height: 16.94px;
  margin-bottom: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
