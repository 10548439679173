/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { api } from '../../config/apiUrls';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private http: HttpClient) {}

  authorizeSocial(provider, bioId) {
    const headers = environment.deployed
      ? { 'X-SIGNIN-MODE': 'prod' }
      : { 'X-SIGNIN-MODE': 'local' };

    console.log(headers);
    return this.http.get<any>(`${api.bio}/${bioId}/authorize/${provider}`, {
      headers,
    });
  }

  sendPhoneNumberCode(phone) {
    return this.http.get<any>(`${api.authorizeSocial}/sms?pn=${encodeURIComponent(phone)}`);
  }

  verifyPhoneNumberCode(phone, code) {
    console.log(phone, code);
    // const { area, exchange, subscriber } = phone;
    // phone = [area, exchange, subscriber].join('');
    const phoneNumber = phone.split('-').join('');
    return this.http.get<any>(
      `${api.authorizeSocial}/sms?pn=${encodeURIComponent(phoneNumber)}&code=${code}`
    );
  }

  getPassbaseUrl(profileId) {
    return this.http.get<any>(`${api.passbase}/${profileId}`);
  }

  getPersonaDetails(profileId) {
    return this.http.get<any>(`${api.persona}/${profileId}`);
  }
}
