/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomainService } from 'src/app/services/domain.service';
import { api } from '../../../config/apiUrls';
import { StripeService } from 'src/app/services/stripe.service';
import { ExternalService } from 'src/app/services/external.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { MatList, MatListItem } from '@angular/material/list';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-guest-offer',
    templateUrl: './guest-offer.component.html',
    styleUrls: ['./guest-offer.component.scss'],
    standalone: true,
    imports: [
    FaIconComponent,
    MatDialogClose,
    NgClass,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    MatList,
    MatListItem
],
})
export class GuestOfferComponent implements OnInit {
  @Output() next = new EventEmitter();

  // domain = '';
  domainName = 'socialvalid.com';
  domains: any[] = [];
  isOpen = false;

  products;
  product;
  plusProduct;
  annualPlan;
  monthlyPlan;

  isFlashing = true; // Initial state is flashing

  constructor(
    private domainService: DomainService,
    private router: Router,
    private stripe: StripeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GuestOfferComponent>,
    private external: ExternalService,
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.products = this.data;

    this.product = this.products.find(
      (product) => product.identifier === 'blue_ribbon'
    );
    console.log(this.product);
    this.plusProduct = this.products.find(
      (product) => product.identifier === 'plus'
    );
    this.annualPlan = this.plusProduct?.prices.find(
      (price) => price.lookup_key === 'plus_semi_annual_guest'
    );
    this.monthlyPlan = this.product?.prices.find(
      (price) => price.lookup_key === 'monthly'
    );

    setInterval(() => {
      this.isFlashing = !this.isFlashing;
    }, 1000); // Toggle every 1 second
    this.domainService.listDomains().then((data) => {
      console.log(data);
      this.domains = [];
      for (const domain of data) {
        this.domains.push({
          value: domain._id,
          viewValue: domain.name,
          isPremium: domain.premium,
        });

        // if (this.domain === domain._id) {
        //   this.domainName = domain.name;
        // }
      }
      console.log(this.domains);

      // this.isLoading = false;
    });
  }

  navigateToRoute() {
    // this.router.navigate(['https://socialvalid.com/home/pricing']);
    const url = 'https://socialvalid.com/home/pricing'; // Replace with the URL you want to open
    const target = '_blank';

    this.external.openNewTab(url, target);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  activatePlan(plan) {
    // console.log(plan);
    const currentPlan = plan === 'monthly' ? this.monthlyPlan : this.annualPlan;
    const product = plan === 'monthly' ? this.product : this.plusProduct;

    const { id: priceId, metadata, unit_amount: planCost } = currentPlan;
    const body = {
      items: [{ price: priceId, quantity: 1 }],
      mode: metadata.checkout_mode,
      product_id: product._id,
      success_url: `${api.stripeCallback}/payment/confirmation?status=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${api.stripeCallback}/payment/confirmation?status=failed`,
    };
    console.log(body);

    this.stripe.purchase(body).subscribe((data) => {
      this.dialogRef.close({url: data.redirect_url});
    });
  }

  nextPage() {
    this.next.emit();
  }

  changeDomain(domain) {
    this.domainName = domain.viewValue;
  }
}
