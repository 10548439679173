import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UpgradePromptComponent } from 'src/app/pages/creator-portfolio/upgrade-prompt/upgrade-prompt.component';
import { ProfilesService } from 'src/app/services/profiles.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatIconButton, MatButton } from '@angular/material/button';

@Component({
    selector: 'app-feature-lock-modal',
    templateUrl: './feature-lock-modal.component.html',
    styleUrls: ['./feature-lock-modal.component.scss'],
    standalone: true,
    imports: [
        MatIconButton,
        MatDialogClose,
        FaIconComponent,
        MatButton,
    ],
})
export class FeatureLockModalComponent implements OnInit {
  featureName;
  message;
  productId;
  priceId;
  secondaryPriceId;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FeatureLockModalComponent>,
    private dialog: MatDialog,
    private router: Router,
    private utilities: UtilitiesService,
    private profiles: ProfilesService
  ) {}

  ngOnInit() {
    this.featureName = this.data.featureName;
    this.message = this.data.message
      
    if (this.profiles.isFeatureEnabledForProfile('creator-portfolio')) {
      // Users with creator access get prompted for a creator plus upgrade
      this.productId = "creator_plus"
      this.priceId = "creator_plus_monthly"
      this.secondaryPriceId = "creator_plus_monthly_trial"
    } else {
      // Users without get prompted for a bio plus upgrade
      this.productId = "plus"
      this.priceId = "plus_monthly"
    }
  }

  upgrade() {
    this.dialogRef.close();
    this.dialog.closeAll();
    this.dialog.open(UpgradePromptComponent, {
      data: {
        productId: this.productId,
        priceId: this.priceId,
        secondaryPriceId: this.secondaryPriceId
      }
    });
  }

  route(route, event) {
    this.router.navigateByUrl(route);
    this.dialogRef.close(event);
    this.dialog.closeAll(); //Added logic to close underlying dialogs during the onboarding process
  }
}
