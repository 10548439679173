// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-icon {
  width: 40px;
  transform: scale(1);
}
.badge-icon.xl {
  transform: scale(1.5);
}

.font {
  font-family: "Poppins", sans-serif;
}

.gray {
  filter: brightness(0) saturate(100%) invert(7%) sepia(72%) saturate(7327%) hue-rotate(242deg) brightness(93%) contrast(134%);
}

.badge-container {
  padding: 8px;
  cursor: pointer;
}

.selected-icon {
  border-bottom: 2px solid var(--primary);
}

:host ::ng-deep .mat-tab-label {
  height: auto !important;
  padding-top: 10px;
  padding-bottom: 20px;
}

.status {
  background-color: lightgray;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
