/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';

import * as confetti from 'canvas-confetti';
import { MoreInfoComponent } from 'src/app/helper/more-info/more-info.component';

import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { ReferralService } from 'src/app/services/referral.service';
import { NgbCarouselConfig, NgbCarousel, NgbSlide } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-account-created',
    templateUrl: './account-created.component.html',
    styleUrls: ['./account-created.component.scss'],
    standalone: true,
    imports: [
    NgbCarousel,
    NgbSlide,
    MatButton
],
})
export class AccountCreatedComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  domain = 'socialvalid.com';
  domains: any[] = [
    { value: 'socialvalid.com', viewValue: 'socialvalid.com' },
    { value: 'social.ink', viewValue: 'social.ink' },
  ];

  carouselCompleted = false;

  customLink;
  constructor(
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private location: Location,
    private router: Router,
    private jwt: JWTTokenService,
    private account: AccountService,
    private utilities: UtilitiesService,
    private profiles: ProfilesService,
    private referrals: ReferralService,
    private config: NgbCarouselConfig
  ) {
    config.interval = 10000; // Transition delay
    config.wrap = false;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  shortLink;

  async ngOnInit() {
    this.renderData();
    this.surprise();
  }

  public surprise(): void {
    console.log('Confetti!');
    const canvas = this.renderer2.createElement('canvas');

    canvas.className = 'confetti';

    this.renderer2.appendChild(this.elementRef.nativeElement, canvas);

    const myConfetti = confetti.create(canvas, {
      resize: true, // will fit all screen sizes
      origin: {
        y: 1,
        x: 0.5,
      },
    });

    myConfetti({
      particleCount: 100,
    });

    // this.clicked = true;
  }

  renderData() {
    this.profiles.getUserMainPageLink().then((link) => {
      this.shortLink = link;
    });
  }

  checkAvailability() {
    this.profiles.checkProfileUsername(this.customLink).subscribe(
      (data: any) => {
        console.log(data);
        const { available, type } = data;
        if (type === 'premium') {
          this.openMoreInfo();
        }
      },
      (error) => {
        console.log(error);
        this.snackBar.open(error.error.message, 'OK', {
          duration: 10000,
        });
      }
    );
  }

  copyShortLink() {
    return `${this.domain}/${this.customLink}`;
  }

  showSnackbar() {
    this.snackBar.open('Short link copied to clipboard!', 'OK', {
      duration: 5000,
    });
  }

  openMoreInfo() {
    const dialogRef = this.dialog.open(MoreInfoComponent, {
      panelClass: 'custom-dialog',
      width: '350px',
      height: '320px',
      data: {
        icon: 'workspace_premium',
        header: 'Premium Username',
        message: `This is a premium username. Please upgrade to PRO user and pay in order to use this username.`,
        // secondaryMessage: `You will be notified when the validation is complete and you have received your badge.`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  nextPage() {
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  async navigate(route) {
    const userId = this.jwt.getSub();

    // Add log for referral if it exists in account
    // const referral_id = this.utilities.accountDetails?.referred_by_data?.referral_id
    // if (referral_id) {
    //   this.referrals
    //     .createReferralLog(referral_id, {
    //       object_type: 'referral',
    //       event: 'complete',
    //       action: 'analytics',
    //     });
    // }

    // const bioProfileId = this.utilities.profileId;
    const accountBody = {
      onboarding_completed: true,
      onboarding_next_step: '',
    };

    const data: any = await firstValueFrom(
      this.account.updateAccount(userId, accountBody, {
        minimized_fields: 'all',
      })
    );

    // Even after waiting for the account update, it still sometimes fetches the old account
    // after a page transition. Can't get anything else to work, so as a last resort I'm adding
    // a local storage variable to override the account.
    localStorage.setItem('onboardingCompleted', 'true');

    this.utilities.account.onboarding_completed = true;
    this.utilities.account.onboarding_next_step = '';

    const current_route = this.router.url;
    console.log(current_route);

    switch (route) {
      case 'profile-information/profile': {
        this.closeDialog();
        if (!current_route.includes(`/edit/`)) {
          this.router.navigateByUrl(`/edit/${this.shortLink}`);
        }
        break;
      }
      case 'creator': {
        this.closeDialog();
        // if (!current_route.includes(`/edit/`)) {
        //   this.router.navigateByUrl(`/edit/${this.shortLink}/creator`);
        // }
        this.router.navigateByUrl(`/sandbox/creator`);
        break;
      }
      case 'profiles': {
        this.closeDialog();
        if (!current_route.includes(`/pages/`)) {
          this.router.navigateByUrl(`/pages/bio`);
        }
        break;
      }
      case 'close': {
        this.closeDialog();
        break;
      }
      default: {
        this.closeDialog();
        break;
      }
    }
  }

  goBack() {
    this.location.back();
  }

  slideTransition(data) {
    if (data.current == 'ngb-slide-1') {
      this.carouselCompleted = true;
    }
  }
}
