import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ReferralLandingPage } from './referral/referral.component';
import { HomeComponent } from './home/home.component';
import { BasicAuthGuard } from './guards/basic-auth.guard';
import { CreatorInviteComponent } from './creator-invite/creator-invite.component';
import { OauthLandingPageComponent } from './oauth-landing-page/oauth-landing-page.component';
import { ExternalRedirectComponent } from './shared/external-redirect/external-redirect.component';
import { environment } from 'src/environments/environment';
import { RedeemCodeComponent } from './redeem-code/redeem-code.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { CompleteComponent } from './signup/complete/complete.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { publicGuard } from './guards/public.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/routes').then((mod) => mod.TAB_ROUTES),
    canActivate: [BasicAuthGuard],
  },
  {
    path: 'verify/',
    redirectTo: 'badges/social-media/choose-social',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: ExternalRedirectComponent,
    data: {
      url: `${environment.clientUrl}/home/`,
    },
  },
  {
    path: 'sign-in',
    component: AuthenticationComponent,
    canActivate: [AuthGuard, BasicAuthGuard],
    data: {
      meta: {
        title:
          'Sign In for Social Valid: Enhance Your Brand with Powerful Tools',
        description:
          "Sign in with Google, Apple, Facebook or email to access Social Valid's tools for Portfolio MediaKits and Link-in-Bio pages. Enhance your brand, connect with clients, and explore new opportunities.",
        url: `${environment.clientUrl}/sign-in`,
        imageUrl: 'https://socialvalid.com/assets/logo/sv-black.svg',
      },
    },
  },
  {
    path: 'sign-up',
    component: AuthenticationComponent,
    canActivate: [AuthGuard, BasicAuthGuard],
    data: {
      meta: {
        title:
          'Sign Up for Social Valid: Enhance Your Brand with Powerful Tools',
        description:
          "Sign up with Google, Apple, Facebook or email to access Social Valid's tools for Portfolio MediaKits and Link-in-Bio pages. Enhance your brand, connect with clients, and explore new opportunities.",
        url: `${environment.clientUrl}/sign-up`,
        imageUrl: 'https://socialvalid.com/assets/logo/sv-black.svg',
      },
    },
  },
  {
    path: 'email-in-bio',
    component: ExternalRedirectComponent,
    data: {
      url: `${environment.clientUrl}/email-in-bio/`,
    },
  },
  {
    path: 'creator/invite',
    component: CreatorInviteComponent,
    canActivate: [AuthGuard, BasicAuthGuard],
    data: {
      guardOptions: {
        requireToken: true,
        requireAuthenticatedToken: false,
        resumeOnboarding: false,
        refreshToken: false,
        refreshTokenAsync: false,
        updateAccountInfo: false,
        updateAccountInfoAsync: false,
        updateProfileInfo: false,
        updateProfileInfoAsync: false,
      },
    },
  },
  {
    path: 'badges/social-media/verify/:provider',
    component: OauthLandingPageComponent,
    canActivate: [BasicAuthGuard],
    data: {
      guardOptions: {
        requireToken: true,
        requireAuthenticatedToken: false,
        resumeOnboarding: false,
        refreshToken: false,
        refreshTokenAsync: false,
        updateAccountInfo: false,
        updateAccountInfoAsync: false,
        updateProfileInfo: false,
        updateProfileInfoAsync: false,
        ignoreSigninRedirect: true,
      },
    },
  },
  {
    path: ':id/referral',
    component: ReferralLandingPage,
    canActivate: [AuthGuard, BasicAuthGuard],
  },
  {
    path: 'pledge-promo',
    loadChildren: () =>
      import('./pledge/routes').then((m) => m.routes),
    canActivate: [AuthGuard, BasicAuthGuard],
    data: {
      guardOptions: {
        requireAnonymousToken: true,
      },
    },
  },
  {
    path: 'kickstart-promo',
    loadChildren: () =>
      import('./kick-start/routes').then((m) => m.routes),
    canActivate: [AuthGuard, BasicAuthGuard],
    data: {
      guardOptions: {
        requireAnonymousToken: true,
      },
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./pages/preferred-page/routes').then((m) => m.routes),
    canActivate: [publicGuard, BasicAuthGuard],
    data: {
      viewMode: 'page',
    },
  },
  {
    path: 'redeem/special-offer',
    component: RedeemCodeComponent,
    canActivate: [AuthGuard, BasicAuthGuard],
    data: {
      guardOptions: {
        requireToken: true,
        requireAuthenticatedToken: false,
        updateAccountInfo: true,
        resumeOnboarding: false,
        consumeSigninRedirect: false,
        discardReferralId: true,
      },
    },
  },
  {
    path: 'payment/confirmation',
    component: PaymentConfirmationComponent,
    canActivate: [AuthGuard],
    data: {
      guardOptions: {
        resumeOnboarding: false,
        ignoreSigninRedirect: true,
      },
    },
  },
  {
    path: 'signup/complete',
    component: CompleteComponent,
    canActivate: [AuthGuard],
    data: {
      guardOptions: {
        resumeOnboarding: false,
        ignoreSigninRedirect: true,
      },
    },
  },
];
