/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { api } from '../../config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class PromoService {
  constructor(private http: HttpClient) {}

  checkCode(code, type=null, username=null, productTitle=null) {
    if (type === 'username') {
      return this.http.get<any>(`${api.promo}?code=${code}&code_type=${type}`);
    }
    else if (type === 'username' && username) {
      return this.http.get<any>(`${api.promo}?code=${code}&code_type=${type}&username=${username}`);
    }
    else if (type === 'membership' && productTitle) {
      return this.http.get<any>(`${api.promo}?code=${code}&code_type=${type}&product_title=${productTitle}`);
    }
    else {
      return this.http.get<any>(`${api.promo}?code=${code}`);
    }
  }


  redeemCode(body) {
    return this.http.post<any>(`${api.promo}/redeem`, body);
  }
}
