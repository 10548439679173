import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { StripeService } from 'src/app/services/stripe.service';
import { api } from '../../../config/apiUrls';
import { MatButton } from '@angular/material/button';
import { NgClass, UpperCasePipe } from '@angular/common';

@Component({
    selector: 'app-onboard-pricing',
    templateUrl: './onboard-pricing.component.html',
    styleUrls: ['./onboard-pricing.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    MatButton,
    UpperCasePipe
],
})
export class OnboardPricingComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  id = 1;
  plan: any = {};
  selectedPrice;
  isLoading = true;

  constructor(
    private products: ProductsService,
    private stripe: StripeService
  ) {}

  ngOnInit() {
    this.products.listProducts().subscribe((data) => {
      console.log(data);

      data.results.map((item) => {
        if (item.identifier === 'plus') {
          const allFeatures = [];
          for (const enabledFeature of item.enabled_features) {
            enabledFeature.enabled = true;
            allFeatures.push(enabledFeature);
          }
          for (const disabledFeature of item.disabled_features) {
            disabledFeature.enabled = false;
            allFeatures.push(disabledFeature);
          }

          this.plan = {
            id: item._id,
            name: item.title,
            showMore: false,
            info: item.description,
            features: allFeatures,
            showcaseItems: item.showcase_items.map((i) => ({
              ...i,
              enabled: true,
            })),
            prices: item.prices.filter(
              (price) =>
                !price.metadata.special_offer ||
                price.lookup_key === 'kickstart'
            ),
          };

          this.selectedPrice = this.plan['prices'][0];
        }

        return;
      });

      this.isLoading = false;
    });
  }

  activatePlan(priceId, productId, mode, planName, planCost) {
    const body = {
      items: [{ price: priceId, quantity: 1 }],
      mode,
      product_id: productId,
      success_url: `${api.stripeCallback}/payment/confirmation?status=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${api.stripeCallback}/payment/confirmation?status=failed`,
    };

    this.stripe.purchase(body).subscribe((data) => {
      console.log(data);
      this.next.emit({
        external_step: data.redirect_url,
        wait_for_update: true,
      });
    });

    console.log(body);
  }

  nextPage() {
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }
}
