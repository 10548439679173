// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.country-selector {
  opacity: 1 !important;
  bottom: 8px !important;
}

input:not(.country-search) {
  bottom: 3px;
  left: 10px;
}

.country-list-button {
  font-size: 0.8rem !important;
}

.mat-menu-content:not(:empty) {
  max-height: 250px;
}

.mat-form-field {
  width: 300px;
}

.m-t-10 {
  margin-top: 10px;
}

.error {
  color: red;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
