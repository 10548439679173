/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { api } from '../../config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  constructor(private http: HttpClient) {}

  listBadges(params = {}) {
    // default minimized fields
    const currentMinimizedFields = params['minimized_fields']
      ? params['minimized_fields'].split(',')
      : [];
    params['minimized_fields'] = [
      ...currentMinimizedFields,
      'products_offered_in',
    ].join(',');

    // Frontend only cares about active badges
    return this.http.get<any>(`${api.badges}?is_active=true`, { params });
  }

  createBadge() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token',
      }),
    };
    return this.http.post<any>(api.badges, '', httpOptions);
  }

  retrieveBadge(userId, params = {}) {
    // default minimized fields
    const currentMinimizedFields = params['minimized_fields']
      ? params['minimized_fields'].split(',')
      : [];
    params['minimized_fields'] = [
      ...currentMinimizedFields,
      'products_offered_in',
    ].join(',');

    return this.http.get<any>(`${api.badges}/${userId}`, { params });
  }

  profileBadgePreview(userId, params = {}) {
    // default minimized fields
    const currentMinimizedFields = params['minimized_fields']
      ? params['minimized_fields'].split(',')
      : [];
    params['minimized_fields'] = [
      ...currentMinimizedFields,
      'products_offered_in',
    ].join(',');

    return this.http.get<any>(`${api.profileBadges}/${userId}/preview`, {
      params,
    });
  }

  updateBadge(userId, body) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: 'my-auth-token',
    //   }),
    // };
    return this.http.patch(`${api.badges}/${userId}`, body);
    //  .pipe(catchError(this.handleError('patchHero')));
  }

  deleteBadge() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token',
      }),
    };
    this.http.delete(api.badges, httpOptions);
  }
}
