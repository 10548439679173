// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-box {
  background-color: var(--mat-theme-app-bar);
  padding: 10px;
  border-radius: 10px;
  font-size: 22px;
}

.copy-btn {
  background-color: var(--primary);
  color: white;
  padding: 10px;
  border-radius: 10px;
}
.copy-btn.copied {
  background-color: var(--mat-theme-app-bar);
  transition: background-color 0.3s;
  color: inherit;
}

.icon-wrapper {
  height: 16px;
  width: 16px;
}
.icon-wrapper .icon-transition-from {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s, transform 0.3s;
}
.icon-wrapper .icon-transition-to {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: rotate(-180deg);
  opacity: 0;
}
.icon-wrapper.open .icon-transition-to {
  transform: rotate(0deg);
  opacity: 1;
}
.icon-wrapper.open .icon-transition-from {
  transform: rotate(180deg);
  opacity: 0;
}

.info-text {
  color: gray;
  font-size: 9pt;
  margin-top: -10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
