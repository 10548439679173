/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MoreInfoComponent } from 'src/app/helper/more-info/more-info.component';
import { ProfilePictureComponent } from 'src/app/shared/image-upload-wrapper/profile-picture/profile-picture.component';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PhotoService } from 'src/app/services/photo.service';
import { firstValueFrom, Subscription } from 'rxjs';
// import { CroppedEvent } from 'ngx-photo-editor';
import { LocationService } from 'src/app/helper/location.service';
// import { NgxPhotoEditorService } from 'ngx-photo-editor';
import { MatDialog } from '@angular/material/dialog';
import { PageService } from 'src/app/services/page.service';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix, MatHint } from '@angular/material/form-field';

@Component({
    selector: 'app-public-profile-information',
    templateUrl: './public-profile-information.component.html',
    styleUrls: ['./public-profile-information.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatIcon,
        MatSuffix,
        MatHint,
        MatButton,
    ],
})
export class PublicProfileInformationComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  // oldPhoto;
  // newPhoto;
  // base64: any;
  // subscription: Subscription;
  // imageUrl: any;
  pageId;

  // bio = new FormControl('');

  publicInfoForm = this.fb.group({
    photo: [''],
    fullName: ["", Validators.required],
    location: [""],
    bio: [""],
  });

  // photo: any;
  constructor(
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private fb: UntypedFormBuilder,
    private jwt: JWTTokenService,
    private account: AccountService,
    private profiles: ProfilesService,
    private utilities: UtilitiesService,
    private locationService: LocationService,
    private photoService: PhotoService,
    private sanitizer: DomSanitizer,
    private pages: PageService
    // private service: NgxPhotoEditorService
  ) {}

  ngOnInit() {
    this.publicInfoForm.disable()

    // Get existing profile Information
    this.profiles
      .getMainPageForProfile(undefined, false, false, 'all')
      .then(async (data) => {
        const {
          _id,
          location,
          full_name,
          description,
          // image_ext,
          // image_index,
        } = data;

        if (!location) {
          this.getLocation();
        } else {
          this.publicInfoForm.patchValue({
            location,
          });
        }

        this.pageId = _id;
        this.publicInfoForm.patchValue({
          // location,
          bio: description,
        });
        this.publicInfoForm.patchValue({
          fullName: full_name ? full_name : this.utilities.accountFullName,
          location: location,
          bio: description
        });

        this.publicInfoForm.enable();
      });

    console.log(this.publicInfoForm.value);
  }

  async getLocation() {
    const { city, state } = await this.locationService.getLocation();
    this.publicInfoForm.patchValue({ location: `${city}, ${state}` });
  }

  nextPage() {
    this.updateProfile();
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  openMoreInfo() {
    const dialogRef = this.dialog.open(MoreInfoComponent, {
      panelClass: 'custom-dialog',
      width: '300px',
      height: '270px',
      data: {
        icon: 'phone',
        message: `Provide your public information for your bio profile.`,
        secondaryMessage: `This will also become the default information for new profiles.`,
        submitButton: 'OK',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  goBack() {
    this.location.back();
  }

  // async uploadProfilePic(profile_id = null) {
  //   //new code to fix image upload in chrome
  //   const res = await fetch(this.newPhoto);
  //   const ext = res.headers.get('Content-Type');
  //   const file = await res.blob();
  //   // you have raw data and content-type

  //   console.log(ext, file);

  //   // Get presigned image upload URL
  //   const presigned_response: any = await firstValueFrom(
  //     this.profiles.imageUploadUrl(this.pageId, {
  //       extension: ext,
  //     })
  //   );
  //   console.log(presigned_response);

  //   const {
  //     presigned_url_data: {
  //       url,
  //       fields: {
  //         AWSAccessKeyId,
  //         key,
  //         policy,
  //         signature,
  //         'x-amz-security-token': token,
  //       },
  //     },
  //     s3_location,
  //   } = presigned_response;

  //   // Format formdata
  //   const formData: FormData = new FormData();
  //   formData.append('AWSAccessKeyId', AWSAccessKeyId);
  //   formData.append('key', key);
  //   formData.append('policy', policy);
  //   formData.append('signature', signature);
  //   formData.append('x-amz-security-token', token);
  //   formData.append('file', file);

  //   // Upload formdata to presigned URL
  //   const upload_response: any = await firstValueFrom(
  //     this.profiles.imageUpload(url, formData)
  //   );

  //   // Return file index and extension
  //   return {
  //     index: s3_location,
  //     ext,
  //   };
  // }

  // async uploadProfilePic() {
  //   // Get Image
  //   const file = await (await fetch(this.newPhoto)).blob();
  //   const ext = file['name'].split('.').pop();
  //   console.log(ext);

  //   // Get presigned image upload URL
  //   let presigned_response: any = await firstValueFrom(
  //     this.profiles.imageUploadUrl(this.pageId, {
  //       extension: ext,
  //     })
  //   );
  //   console.log(presigned_response);

  //   const {
  //     presigned_url_data: {
  //       url,
  //       fields: {
  //         AWSAccessKeyId,
  //         key,
  //         policy,
  //         signature,
  //         'x-amz-security-token': token,
  //       },
  //     },
  //     s3_location,
  //   } = presigned_response;

  //   // Format formdata
  //   const formData: FormData = new FormData();
  //   formData.append('AWSAccessKeyId', AWSAccessKeyId);
  //   formData.append('key', key);
  //   formData.append('policy', policy);
  //   formData.append('signature', signature);
  //   formData.append('x-amz-security-token', token);
  //   formData.append('file', file);

  //   // Upload formdata to presigned URL
  //   let upload_response: any = await firstValueFrom(
  //     this.profiles.imageUpload(url, formData)
  //   );

  //   // Return file index and extension
  //   return {
  //     index: s3_location,
  //     ext: ext,
  //   };
  // }

  async updateProfile() {
    // Basic Info
    const { fullName, bio, location, links } = this.publicInfoForm.value;

    let body = { location, full_name: fullName, description: bio };

    // Update Profile Pic
    // if (this.newPhoto) {
    //   let upload_response = await this.uploadProfilePic();
    //   if (upload_response) {
    //     body['image_index'] = upload_response['index'];
    //     body['image_ext'] = upload_response['ext'];
    //   }
    // }

    // Update profile using body
    console.log(this.pageId, { body });
    this.pages
      .updatePage(this.pageId, "bio", body)
      .then(async (data) => {
        console.log(data);
      });
  }
}
