import { Component, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-feature-lock-icon',
    templateUrl: './feature-lock-icon.component.html',
    styleUrls: ['./feature-lock-icon.component.scss'],
    standalone: true,
    imports: [FaIconComponent],
})
export class FeatureLockIconComponent implements OnInit {
  xOffset=-5;
  xOffsetAnchor='right';
  yOffset=-5;
  yOffsetAnchor='bottom';
  iconColor='#40b7e5';
  iconSize=10;

  constructor() { }

  ngOnInit() {}

}
