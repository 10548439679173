
export const environment = {
  production: false,
  deployed: true,
  host: 'https://dev.socialvalid.com',
  imageHost: 'https://dev.socialvalid.com',
  clientUrl:'https://dev.socialvalid.com',
  ga:'G-5KWCJEWSW8',
  domain: 'dev',
  wsUrl: 'wss://9b97djv7dc.execute-api.us-west-2.amazonaws.com/Dev',
  defaultColor: '#8552ff',

};
