// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite-border {
  background: linear-gradient(to right, #7ddcfa 5px, transparent 0px) 0 0, linear-gradient(to right, #7ddcfa 5px, transparent 0px) 0 100%, linear-gradient(to left, #7ddcfa 5px, transparent 0px) 100% 0, linear-gradient(to left, #7ddcfa 5px, transparent 0px) 100% 100%, linear-gradient(to bottom, #7ddcfa 5px, transparent 0px) 0 0, linear-gradient(to bottom, #7ddcfa 5px, transparent 0px) 100% 0, linear-gradient(to top, #7ddcfa 5px, transparent 0px) 0 100%, linear-gradient(to top, #7ddcfa 5px, transparent 0px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 150px 150px;
  border: none;
  font-family: "Bebas Neue", cursive;
  padding: 20px;
  padding-top: 100px;
  height: 100%;
}

.invite {
  color: #1f1347;
  font-family: "League Gothic", sans-serif;
}

.bg-redeem {
  color: #7ddcfa;
}

.invite-text-blue {
  color: #00b7ff;
}

.invite-bg-black {
  background-color: #1f1347;
}

.help-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.code-input {
  width: 100%;
  height: 40px;
  font-family: "League Gothic", sans-serif;
}

.redeem-input {
  height: 40px;
}

.input-container {
  width: 100%;
  max-width: 500px;
}

.loading-button {
  margin-top: -22.5px;
  width: 69px;
  margin-left: auto;
  margin-right: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
