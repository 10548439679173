/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { api } from '../../config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  constructor(private http: HttpClient) {}

  listReferrals(filters = {}) {
    // Initialize Params Object
    let params = new HttpParams();

    // Assign parameters
    for (let key in filters) {
      params = params.append(key, filters[key]);
    }
    
    return this.http.get<any>(api.referrals, { params: params });
  }

  createReferral(body) {
    return this.http.post<any>(api.referrals, body);
  }

  retrieveReferral(id) {
    return this.http.get<any>(`${api.referrals}/${id}`);
  }

  updateReferral(id, body) {
    return this.http.patch(`${api.referrals}/${id}`, body);
  }

  deleteReferral(id) {
    return this.http.delete(`${api.referrals}/${id}`);
  }

  retrieveReferralQrCode(id, params) {
    params['host'] = api.shortUrl;
    params['color'] = params['color'].replace('#', '');

    return this.http.get(`${api.referrals}/${id}/qr`, {
      params,
      responseType: 'blob',
    });
  }

  retrieveReferralLogs(id, filters) {
    // Initialize Params Object
    let params = new HttpParams();

    // Assign parameters
    for (let key in filters) {
      params = params.append(key, filters[key]);
    }

    return this.http.get(`${api.referrals}/${id}/log`, { params: params })
  }

  createReferralLog(id, body) {
    return this.http.post<any>(`${api.referrals}/${id}/log`, body);
  }
}
