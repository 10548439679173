// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-border {
  border-radius: 15px;
  border: 6px solid var(--primary-light);
  padding: 50px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}
@media (max-width: 500px) {
  .card-border {
    padding: 20px;
  }
}

.title-text {
  font-family: "Poppins", sans-serif;
  font-size: 25pt;
  line-height: 32pt;
}

.subtitle-text {
  font-family: "Poppins", sans-serif;
  font-size: 13pt;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
