/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { MoreInfoComponent } from 'src/app/helper/more-info/more-info.component';
import { Location, AsyncPipe } from '@angular/common';
import { LocationService } from 'src/app/helper/location.service';
import { AbstractControl, FormControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { AccountService } from 'src/app/services/account.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption } from '@angular/material/core';

import * as moment from 'moment';
import { Observable, startWith, map } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as mt from 'moment-timezone';
import { EmailValidatorDirective } from 'src/app/directives/email-validator.directive';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.scss'],
    // providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-US' }],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatIcon,
    MatSuffix,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatButton,
    MatMiniFabButton,
    FaIconComponent,
    AsyncPipe
],
})
export class AccountSettingsComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() isOnboard = false;
  @Input() boardMode = 'full';
  @Input() color = 'primary';
  @Input() updateAccount = true;
  @Input() updateProfile = true;

  @ViewChild('ref') dob: ElementRef;

  accountInfoForm = this.fb.group({
    zipCode: ['', [Validators.required]],
    timezone: ['', [Validators.required, this.tzValidator(mt.tz.names())]],
  });

  maxDob: Date;
  minDob: Date;

  timezone = '';

  is18Above = true;
  is13Below = false;

  tosAgree = false;

  constructor(
    public dialog: MatDialog,
    private locationService: LocationService,
    private fb: UntypedFormBuilder,
    private jwt: JWTTokenService,
    private account: AccountService,
    private profiles: ProfilesService,
    private utilities: UtilitiesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.retrieveInformation();

    // this.updateDate();

    this.filteredTimezones = this.accountInfoForm
      .get('timezone')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filterTimezones(value || ''))
      );
  }

  filteredTimezones: Observable<string[]>;

  guessTimezone() {
    return mt.tz.guess();
  }

  tzValidator(timezones: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validTz = timezones.includes(control.value);
      return validTz ? null : { forbiddenTz: { value: control.value } };
    };
  }

  // updateDate() {
  //   this.accountInfoForm.get('dateOfBirth').valueChanges.subscribe((data) => {
  //     console.log(data);
  //   });
  // }

  retrieveInformation() {
    let zipCode;
    let timezone;

    if (!this.updateAccount) {
      zipCode = this.utilities.profileDetails.zip_code
    } else {
      zipCode = this.utilities.accountDetails.zip_code
      timezone = this.utilities.accountDetails.settings.tz
    }

    this.timezone = timezone;
    console.log(this.timezone);

    if (!this.timezone) {
      console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    console.log(this.timezone);

    this.accountInfoForm.patchValue({
      zipCode,
      timezone: this.timezone,
    });
  }

  // dobChanges(value) {
  //   console.log(value);
  //   if (value.length === 2 || value.length === 5) {
  //     // console.log(value);

  //     // this.accountInfoForm.patchValue({dateOfBirth:`${value}/`});
  //     this.dob.nativeElement.value = `${value}/`;
  //   }
  // }

  async getLocation() {
    const { zipCode } = await this.locationService.getLocation();

    this.accountInfoForm.patchValue({ zipCode });
  }

  nextPage() {
    this.save()
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  saveChanges() {
    this.save()
    this.snackBar.open('Updated account information', 'OK', {
      duration: 5000,
    });

  }

  save() {
    const {
      zipCode,
      timezone,
    } = this.accountInfoForm.value;

    const userId = this.jwt.getSub();

    const profileId = this.utilities.profileId;

    //update account
    if (this.updateAccount) {
      this.account
        .updateAccount(userId, {
          zip_code: zipCode,
          settings: { tz: timezone },
        })
        .subscribe((data: any) => {
          console.log(data);
          this.utilities.accountDetails = data;
        });
    }

    //update profile
    if (this.updateProfile) {
      this.profiles
        .updateProfile(profileId, {
          zip_code: zipCode,
        })
        .subscribe((data: any) => {
          console.log(data);
          this.utilities.profileDetails = data;
        });
    }
  }

  private _filterTimezones(value: string): string[] {
    const filterValue = value.toLowerCase();
    return mt.tz.names().filter((tz) => tz.toLowerCase().includes(filterValue));
  }
}
