import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { api } from '../../config/apiUrls';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient) {}

  listProducts(filters = {}) {
    // Initialize Params Object
    let params = new HttpParams();

    // Assign parameters
    for (let key in filters) {
      params = params.append(key, filters[key]);
    }
    
    return this.http.get<any>(api.products, { params: params });
  }

  createProduct(body) {
    return this.http.post<any>(api.products, body);
  }

  retrieveProduct(profileId) {
    return this.http.get<any>(`${api.products}/${profileId}`);
  }

  updateProduct(profileId, body) {
    return this.http.patch(`${api.products}/${profileId}`, body);
    //  .pipe(catchError(this.handleError('patchHero')));
  }

  deleteProduct(profileId) {
    return this.http.delete(`${api.products}/${profileId}`);
  }
}
