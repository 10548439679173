// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  perspective: 1200px;
  transform-style: preserve-3d;
}

.bug-report {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.h-dynamic-screen {
  height: 100dvh;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
