import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { JWTTokenService } from '../services/jwttoken.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicGuardService {
  constructor(
    private auth: AuthService,
    private jwt: JWTTokenService,
    private router: Router
  ) {}

  async canActivate(): Promise<boolean> {
    // Get current token
    const token = localStorage.getItem('token');

    if (token) {
      this.jwt.setToken(token);
      this.jwt.decodeToken();
      const expired = this.jwt.isTokenExpired();

      // If token is expired, get new anonymous token
      if (expired) {
        await this.refreshToken();
      }
    } else {
      // No token present, get anonymous token
      await this.refreshToken();
    }

    return true;
  }

  /**
   * Performs a refresh of the token stored in local storage. If no token is
   * found, a fresh anonymous one will be generated.
   */
  private async refreshToken() {
    const res = await firstValueFrom(this.auth.signIn());
    this.auth.initiateJwt(res.token);
  }
}

export const publicGuard: CanActivateFn = (route, state) => {
  return inject(PublicGuardService).canActivate();
};
