import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BadgesComponent } from 'src/app/badges/badges.component';
import { AccountService } from 'src/app/services/account.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { MatButton } from '@angular/material/button';


@Component({
    selector: 'app-vip-verify',
    templateUrl: './vip-verify.component.html',
    styleUrls: ['./vip-verify.component.scss'],
    standalone: true,
    imports: [MatButton],
})
export class VipVerifyComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  socials = ['facebook', 'instagram', 'twitter', 'snapchat', 'tiktok'];

  constructor(
    private account: AccountService,
    private utilities: UtilitiesService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {}

  openIdentity() {
    const dialogRef = this.dialog.open(BadgesComponent, {
      data: {mode: "standard", page: 'identity'},
      panelClass: 'custom-identity-dialog',
    });

    // Mark onboarding as completed
    const accountBody = {
      onboarding_completed: true,
      onboarding_next_step: '',
    };

    this.account.updateAccount(this.utilities.accountId, accountBody).subscribe((data: any) => {
      console.log(data);
    });

    this.close.emit()
  }

}
