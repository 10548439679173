// const accountApi = {
//   listAccounts:'/api/v1/accounts',
//   createAccount:'',
//   retrieveAccount:'',
//   updateAccount:'',
//   deleteAccount:''
// };
// const profilesApi = {
//   listProfiles:'/api/v1/accounts',
//   createProfile:'',
//   retrieveProfile:'',
//   updateProfile:'',
//   deleteProfile:''
// };
// const authApi = {
//   signIn:'/api/v1/accounts',
//   signOut:'',
//   signInWithProvider:'',
//   oAuthCallback:''
// };
// const utilitiesApi = {
//   status:'/api/v1/status',
// };
// export { accountApi };

import { environment } from '../environments/environment';

const prefix = environment.host + '/api/v1';
const api = {
  status: `${prefix}/status`,
  accounts: `${prefix}/accounts`,
  accountAvailable: `${prefix}/accounts/email/available`,
  profile: `${prefix}/profile`,
  profiles: `${prefix}/profiles`,
  page: `${prefix}/page`,
  bio: `${prefix}/bio`,
  passport: `${prefix}/passport`,
  about: `${prefix}/about`,
  creator: `${prefix}/portfolio`,
  portfolio: `${prefix}/portfolio`,
  agency: `${prefix}/agency-featured`,
  agencyFeatured: `${prefix}/agency-featured`,
  //   tempData: `${prefix}/temp-data`,
  sandboxSession: `${prefix}/sandbox-session`,
  images: `${prefix}/image`,
  imagesRoot: `${environment.imageHost}/images`,
  profileImages: `${environment.imageHost}/images/profile`,
  bioImage: `${environment.imageHost}/images/bio`,
  passportImage: `${environment.imageHost}/images/passport`,
  aboutImage: `${environment.imageHost}/images/about`,
  portfolioImage: `${environment.imageHost}/images/portfolio`,
  framedImage: `${prefix}/profiles/:id/framed-image`,
  watermarkImages: `${environment.imageHost}/images/watermarks`,
  pledges: `${prefix}/pledges`,
  kickStartPromos: `${prefix}/kickstart-promos`,
  links: `${prefix}/links`,
  profilesAvailable: `${prefix}/profiles/available`,
  signIn: `${prefix}/signin`,
  signOut: `${prefix}/signout`,
  signInCognito: `${prefix}/signin/cognito`,
  oAuthCallback: `${prefix}/signin/:provider/callback`,
  products: `${prefix}/products`,
  orders: `${prefix}/orders`,
  stripePurchase: `${prefix}/purchase/stripe`,
  stripeCallback: `${environment.clientUrl}`,
  shortUrl: `${environment.clientUrl}`,
  referrals: `${prefix}/referrals`,
  watermarks: `${prefix}/watermarks`,
  watermarkGroups: `${prefix}/watermark-groups`,
  authorizeSocial: `${prefix}/authorize`,
  validateLink: `${prefix}/links/validate/url`,
  features: `${prefix}/features`,
  passbase: `${prefix}/validation/passbase`,
  accountNotification: `${prefix}/notifications`,
  logs: `${prefix}/logs`,
  badges: `${prefix}/badges`,
  profileBadges: `${prefix}/profile-badges`,

  domains: `${prefix}/domains`,
  themes: `${prefix}/themes`,
  themePreview: `${prefix}/themes/preview`,
  elements: `${prefix}/themes/elements`,
  alternativeVerification: `${prefix}/alternative-verification`,
  stripe: `${prefix}/stripe`,
  stripeOnboardLink: `${prefix}/stripe/onboard`,
  verificationQuestionnaire: `${prefix}/verification-questionnaire`,
  video: `${prefix}/video`,
  videos: `${prefix}/videos`,
  tickets: `${prefix}/tickets`,
  persona: `${prefix}/validation/persona`,
  promo: `${prefix}/promo-code`,
  notificationConfigs: `${prefix}/notification-configs`,
  verificationCode: `${prefix}/verification-codes`,
  review: `${prefix}/portfolio-review`,
  reviews: `${prefix}/reviews`,
  brandDeals: `${prefix}/brand-deal`,
  brandFetch: `${prefix}/brand-fetch`,
  previewUrl: `${prefix}/preview-url`,
  socialMetrics: `${prefix}/social-metrics`,
  validateBusinessEmail: `${prefix}/email-in-bio/business-account/validate`,
  emailValidate: `${prefix}/email/validate`,
  inquiries: `${prefix}/inquiries`,
  subscriptions: `${prefix}/subscriptions`,
  visitorReports: `${prefix}/visitor-reports`,
};

export { api };
