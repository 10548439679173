/* eslint-disable max-len */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    standalone: true,
    imports: [MatButton, FaIconComponent],
})
export class SignInComponent implements OnInit {
  @Output() next = new EventEmitter();

  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  async signIn(provider) {
    const {url} = await firstValueFrom(this.auth.signInProvider(provider));
    console.log(url);

    localStorage.setItem('boardMode', 'guestFlow');
    localStorage.setItem('signinRedirect', this.router.url);
    window.open(url, '_self');
  }

  ngOnInit() {}

  nextPage() {
    this.next.emit();
  }
}
