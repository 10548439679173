import { Component, OnInit, Input } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-features-table',
    templateUrl: './features-table.component.html',
    styleUrls: ['./features-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [
    NgClass,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatButton
],
})
export class FeaturesTableComponent implements OnInit {
  @Input('data') data = [];
  @Input('shortData') shortData = [];
  @Input('showShowcase') showShowcase = false;
  @Input('filterFeatures') filterFeatures = true;
  @Input('featuresExpandable') featuresExpandable = true;
  @Input('mode') mode = 'default';

  columnsToDisplay = ['Feature', 'Enabled'];
  expandedElement: any | null;
  expandedTable;

  constructor() {}

  ngOnInit() {
    this.expandedTable = !this.showShowcase;

    this.data = this.data?.filter((feature) => feature.advertised);

    this.data.sort((e1, e2) =>
      e1.enabled && !e2.enabled ? -1 : e1.enabled && e2.enabled ? 0 : 1
    );

    // if (this.filterFeatures) {
    //   const showFeatures = [
    //     "True Identity",
    //     "Tip & Link Monetization",
    //     "Contact Card Monetization",
    //     "Premium Username (Your name)",
    //     "Alternate Domains",
    //     "Disable SV Score, Banners, Badges",
    //     "Unlimited Bio Profiles"
    //   ]

    //   this.data = this.data.filter((item) => {
    //     return showFeatures.includes(item.title)
    //   })
    // }
  }

  expandFeature(element) {
    if (this.featuresExpandable) {
      this.expandedElement = this.expandedElement === element ? null : element;
    }
  }
}
