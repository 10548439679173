import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BadgesComponent } from 'src/app/badges/badges.component';
import { AccountService } from 'src/app/services/account.service';
import { BadgeService } from 'src/app/services/badge.service';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-verified-badges',
    templateUrl: './verified-badges.component.html',
    styleUrls: ['./verified-badges.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class VerifiedBadgesComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  @Input() showSkip = false;

  badges = [];
  badge: string;
  selectedBadge: string;
  badgeIndex: number;
  isLoading = true;
  isPaidUser = false;

  constructor(
    public dialog: MatDialog,
    private utilities: UtilitiesService,
    private account: AccountService,
    private sanitizer: DomSanitizer // private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const features = this.utilities.featureList;
    console.log(features);
    this.isPaidUser = features['premium-usernames'];
  }

  // getImageHost() {
  //   return environment.host + '/static/images/'
  // }

  getImageHost(url) {
    console.log(url);
    // return this.sanitization.bypassSecurityTrustStyle(
    //   `url(${environment.host + '/static/images/' + url})`
    // );
    return this.sanitizer.bypassSecurityTrustUrl(
      `${environment.host}/static/images/${url}`
    ); //environment.host + '/static/images/' + url;
  }

  nextPage() {
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  openBadge() {
    if (this.badge) {
      const dialogRef = this.dialog.open(BadgesComponent, {
        data: {mode: "standard", page: this.badge},
        panelClass: this.badge === 'identity' ? 'custom-identity-dialog' : 'custom-badge-dialog',
      });

      // Mark onboarding as completed
      const accountBody = {
        onboarding_completed: true,
        onboarding_next_step: '',
      };

      this.account.updateAccount(this.utilities.accountId, accountBody).subscribe((data: any) => {
        console.log(data);
      });

      this.close.emit()
    }
  }
}
