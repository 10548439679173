import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { firstValueFrom } from 'rxjs';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { BadgeService } from 'src/app/services/badge.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';

import * as Persona from 'persona';
import { NotificationsService } from 'src/app/services/notifications.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfilesService } from 'src/app/services/profiles.service';
import { MatButton } from '@angular/material/button';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-identity',
    templateUrl: './identity.component.html',
    styleUrls: ['./identity.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    FaIconComponent,
    MatButton
],
})
export class IdentityComponent implements OnInit {
  @Output() changePage = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input('profileId') profileId = null;

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  loading = false;
  agree = false;
  started = false;
  initializing = true;
  chosen = '';
  isCreator = false;

  identityBadge;
  badgeActive = false;
  available = false;
  // prereqs = false;

  isLastSlide = false;
  isFirstSlide = true;

  currentPage = 1;

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
    // dotsClass: 'slick-dot',
    responsive: [
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  constructor(
    private utilities: UtilitiesService,
    private auth: AuthorizationService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private badges: BadgeService,
    private snackBar: MatSnackBar,
    private notifications: NotificationsService,
    private profiles: ProfilesService
  ) {}

  async ngOnInit() {
    if (!this.profileId) {
      this.profileId = this.utilities.profileId;
    }

    this.isCreator =
      this.profiles.isFeatureEnabledForProfile('creator-portfolio');

    // Check if the badge is available or not
    this.available = this.utilities.isFeatureEnabled("identity-badge")

    for (const profileBadge of this.utilities.profileDetails.badges) {
      if (profileBadge.badge.badge_type === 'identity') {
        this.identityBadge = profileBadge.badge;
        this.badgeActive = profileBadge.is_active;
      }
    }

    if (this.available && (!this.identityBadge || !this.badgeActive)) {
      this.close.emit();
      this.openPersonaFlow();
    } else {
      this.initializing = false;
    }
  }

  openVerification() {
    // Workaround for safari blocking window.open() in an async function -_-
    var windowReference = window.open();
    this.getVerificationUrl().then(function (url) {
      windowReference.location = url;
    });
  }

  async getVerificationUrl() {
    this.chosen = 'passbase';
    this.loading = true;
    // this.auth.getPassbaseUrl(this.profileId).subscribe((data) => {
    //   console.log(data);
    //   this.loading = false;
    //   this.started = true;
    //   window.open(data.url, 'blank');
    // });
    //solution to open in new tab in safari
    const data = await firstValueFrom(this.auth.getPassbaseUrl(this.profileId));
    console.log(data);
    this.loading = false;
    this.started = true;

    return data.url;
  }

  navigate(path) {
    this.router.navigateByUrl(path);
    this.close.emit();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  getImageHost(url) {
    return this.sanitize(`${environment.imageHost}/static/images/${url}`);
  }

  addSlide() {
    // this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }

  removeSlide() {
    // this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
    // console.log(e);

    this.isLastSlide = e.last;
    this.isFirstSlide = e.first;
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  goNext() {
    this.slickModal.slickNext();
  }

  goBack() {
    this.slickModal.slickPrev();
  }

  async refresh() {
    const result: any[] = await firstValueFrom(
      this.profiles.getProfileIdentityBadge(this.utilities.profileId)
    );

    if (result?.length > 0) {
      // For now, refreshing the page as a whole is the easiest way
      // to make sure everything gets updated.
      localStorage.removeItem('personaLockoutTimer');
      window.location.reload();


      // // Refresh Notifications
      // this.notifications.refreshNotifications();

      // // Update utilities profile
      // this.profiles.retrieveProfile(
      //   this.utilities.profileId,
      //   {},
      //   false,
      //   true
      // )
    }
  }

  updateNotifications() {
    // Refresh the notifications every 3 seconds for 30 seconds
    const interval = setInterval(() => this.refresh(), 3000);
    setTimeout(() => {
      clearInterval(interval);
    }, 30000);
  }

  async openPersonaFlow() {
    this.chosen = 'persona';

    const {personaLockoutTimer} = localStorage;
    const curDate = new Date();

    if ((!personaLockoutTimer) || (parseInt(personaLockoutTimer, 10) < curDate.getTime())) {
      this.loading = true;
      const data = await firstValueFrom(
        this.auth.getPersonaDetails(this.profileId)
      );
      console.log(data);

      const config = Object.assign(
        {
          inquiryId: data.inquiryId,
          onComplete: ({ inquiryId, status, fields }) => {
            this.snackBar.open('Identity verification submitted!', null, {
              duration: 2000,
            });
            const lockoutTime = 30; // Minutes
            localStorage.setItem('personaLockoutTimer', (curDate.getTime() + (lockoutTime*60*1000)).toString());
            this.updateNotifications();
          },
        },
        // data.sessionToken ? { sessionToken: data.sessionToken } : null
      );

      // @ts-ignore
      const client = new Persona.Client(config);

      this.loading = false;
      client.open();
      this.close.emit();
    } else {
      this.snackBar.open('Your identity verification has already been submitted.', 'OK', {duration: 5000});
    }
  }
}
