import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { BadgesComponent } from 'src/app/badges/badges.component';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-verify-identity',
    templateUrl: './verify-identity.component.html',
    styleUrls: ['./verify-identity.component.scss'],
    standalone: true,
    imports: [],
})
export class VerifyIdentityComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() close = new EventEmitter();

  @Input() boardMode = 'full';
  @Input() color = 'primary';

  showMessage = false;

  countDown;

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  nextPage() {
    this.next.emit();
  }

  prevPage() {
    this.prev.emit();
  }

  closeDialog() {
    this.close.emit();
  }

  verify() {
    const dialogRef = this.dialog.open(BadgesComponent, {
      data: {mode: "standard", page: "identity"},
      panelClass: 'custom-identity-dialog',
    });

    this.next.emit({dialog_step: dialogRef, wait_for_update: true})
  }

  maybeLater() {
    this.showMessage = true;

    this.countDown = 5;
    const timer = interval(1000);
    const subscription = timer.subscribe(() => {
      this.countDown--;
      if (this.countDown === 0) {
        subscription.unsubscribe();
        this.nextPage();
      }
    });
  }
}
