import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Location, NgClass, TitleCasePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';

import { AuthorizationService } from '../../../services/authorization.service';
// import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatGridList } from '@angular/material/grid-list';
import { AccountService } from 'src/app/services/account.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DmVerificationComponent } from 'src/app/shared/dm-verification/dm-verification.component';
import { MatDialog } from '@angular/material/dialog';
import { PageService } from 'src/app/services/page.service';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-choose-social-media',
    templateUrl: './choose-social-media.component.html',
    styleUrls: ['./choose-social-media.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    FaIconComponent,
    MatButtonToggle,
    MatIcon,
    MatButton,
    TitleCasePipe
],
})
export class ChooseSocialMediaComponent implements OnInit {
  @Output() changePage = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() badgeMode = '';

  socials: IconName[] = [
    'facebook',
    'linkedin',
    'x-twitter',
    'tiktok',
    'instagram',
  ];
  socialVerificationMethods = {
    'facebook': 'oauth',
    'linkedin': 'dm',
    'x-twitter': 'oauth',
    'tiktok': 'oauth',
    'instagram': 'dm',
  };

  selectedSocial = '';
  activatedSocials: { type: IconName; link: string }[] = [];
  fast_access = false;
  badgeActive = false;
  socialBadge = null;
  isLoading = true;
  constructor(
    private location: Location,
    private router: Router,
    private authorize: AuthorizationService,
    // private observableMedia: MediaObserver,
    private route: ActivatedRoute,
    private utilities: UtilitiesService,
    private profiles: ProfilesService,
    private pageService: PageService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    // If profileId not in local storage, get the default bio from account
    let profileId = localStorage.getItem('profileId');
    if (!profileId) {
      profileId = this.utilities.profileId;
    }

    // Check if the profile has access to the simplified social media badge
    this.profiles.retrieveProfile(profileId).then((data) => {
      for (const profileBadge of data.badges) {
        if (profileBadge.badge.badge_type === 'social-media') {
          this.socialBadge = profileBadge.badge;
          this.badgeActive = profileBadge.is_active;
          this.activatedSocials = profileBadge.verified_data?.verified_socials.map(
            (item) => item.type === 'twitter' ? { ...item, type: 'x-twitter' } : item
          );
          this.socials = this.socials.filter((item) => {
            for (let social of this.activatedSocials) {
              if (social.type === item) {
                return false;
              }
            }
            return true;
          });
        }
      }
      this.fast_access = data.features.enabled_features.some(
        (obj) => obj.title === 'Fast Social Badge'
      );
      this.isLoading = false;
    });
  }

  addRemoveSocial(social, event) {
    this.selectedSocial = event.source.checked ? social : [];
  }

  goBack() {
    this.location.back();
  }

  isSocialValidated(social) {
    return this.activatedSocials.filter((e) => e.type === social).length > 0;
  }

  async validateSocial() {
    // Workaround for safari blocking window.open() in an async function -_-
    var windowReference = window.open();
    const social = this.selectedSocial === 'x-twitter' ? 'twitter' : this.selectedSocial;
    const mainBio = await this.pageService.retrieveMainPage("bio")
    console.log(mainBio)
    this.authorize.authorizeSocial(social, mainBio._id).subscribe((data) => {
      console.log(data);
      localStorage.setItem('signinRedirect', this.router.url);
      localStorage.setItem('oauthBadgeMode', this.badgeMode);
      windowReference.location = data.url;
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  getImageHost(url) {
    return this.sanitize(`${environment.imageHost}/static/images/${url}`);
  }

  validateSocialDM(linkType) {
    const dialogRef = this.dialog.open(DmVerificationComponent, {
      panelClass: 'custom-dialog',
      // width: '410px',
      // height: '250px',
      data: {
        socialType: linkType
      }
    });
  }
}
