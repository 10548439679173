// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-banner {
  width: 100%;
  padding: 15px 40px;
  position: relative;
  overflow: hidden;
}
.status-banner.success {
  background-color: var(--mat-theme-success-light);
  color: var(--mat-theme-success);
}
.status-banner.failed {
  background-color: var(--mat-theme-danger-light);
  color: var(--mat-theme-danger);
}
.status-banner.open {
  height: auto;
}
.status-banner.closed {
  height: 0;
  padding: 0;
}
.status-banner h1 {
  font-weight: 500;
  margin-bottom: 5px;
}
.status-banner p {
  margin-bottom: 5px;
}
.status-banner a {
  color: var(--primary);
  font-weight: 500;
  text-decoration: underline;
}

.link-text {
  color: var(--primary);
  font-weight: 500;
  text-decoration: underline;
}

.order-title {
  font-size: 35px;
  line-height: 40px;
}

.card-border {
  border-radius: 10px;
  border: 3px solid var(--primary-light);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
