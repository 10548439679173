import {Injectable} from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'og:title', content: title })
    this.meta.updateTag({ name: 'DC.title', content: title })
    this.meta.updateTag({ name: 'apple-mobile-web-app-title', content: title })
  }

  updateUrl(url: string) {
    this.meta.updateTag({ name: 'url', content: url })
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'og:description', content: desc })
  }

  updateImage(url: string) {
    this.meta.updateTag({ name: 'og:image', content: url })
  }

  updateKeywords(tags: string[]) {
    this.meta.updateTag({ name: 'keywords', content: tags.join(', ') })
  }
}