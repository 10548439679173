// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-spacer {
  flex: 1 1 auto;
}

.navbar-item-width {
  width: auto !important;
}

.example-is-mobile .example-toolbar {
  position: fixed;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.topnav {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.nav-content {
  padding-top: 1.5rem;
}

.content {
  height: 100%;
}

.navbar-spacer {
  flex: 1 1 auto;
}

.nav-logo {
  height: 40px;
  padding: 0;
}

.background {
  padding: 40px;
  min-height: 100%;
}

.content-card {
  padding: 30px;
}

.topnav {
  padding: 10px !important;
  height: auto !important;
}

.progress {
  width: 200px;
}

@media (max-width: 599px) {
  .background {
    padding: 20px;
  }
}
.step-container {
  font-family: "Poppins", sans-serif;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
