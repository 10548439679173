import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../../config/apiUrls';


@Injectable({
  providedIn: 'root',
})
export class SandboxService {
  constructor(
    private http: HttpClient,
  ) {}
  
  createSandboxSession(body) {
    return this.http.post<any>(api.sandboxSession, body);
  }

  updateSandboxSession(id, body) {
    return this.http.patch<any>(
      `${api.sandboxSession}/${id}`, 
      body, 
      { 
        params: { minimized_fields: "page" } 
      }
    );
  }

  retrieveSandboxSession(id) {
    return this.http.get<any>(`${api.sandboxSession}/${id}`);
  }
}
