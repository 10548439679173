import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'app-more-info',
    templateUrl: './more-info.component.html',
    styleUrls: ['./more-info.component.scss'],
    standalone: true,
    imports: [
    MatIcon,
    MatDivider,
    MatButton
],
})
export class MoreInfoComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MoreInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data.source);
  }

  onNoClick(): void {
    this.dialogRef.close({ buttonClicked: 'keep-editing' });
  }

  onSubmit() {
    switch (this.data.source) {
      case 'sandbox-close': {
        this.dialogRef.close({ buttonClicked: 'discard' });
        break;
      }
      case 'sandbox-done': {
        this.dialogRef.close({ buttonClicked: 'login' });
        break;
      }
      case 'sandbox-signin': {
        this.dialogRef.close({ buttonClicked: 'sign-in' });
        break;
      }
      case 'short-done': {
        this.dialogRef.close({ buttonClicked: 'yes' });
        break;
      }
      case 'notification-delete': {
        this.dialogRef.close({ buttonClicked: 'delete' });
        break;
      }
      default: {
        this.dialogRef.close();
      }
    }
  }

  preview() {
    this.dialogRef.close({
      buttonClicked: this.data.previewButton === 'PREVIEW' ? 'preview' : 'edit',
    });
  }
}
